import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import "../assets/style.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import dubaiImage from "../assets/images/dubai-1.jpg";
import dubai2Image from "../assets/images/dubai-2.jpg";
import dubai3Image from "../assets/images/dubai-3.jpg";
import sigaImage from "../assets/images/sigapur-1.jpg";
import siga1Image from "../assets/images/sigapur-2.jpg";
import siga3Image from "../assets/images/sigapur-3.jpg";
import thildImage from "../assets/images/thiland.svg";
import kashmirImage from "../assets/images/kashmir.svg";
import swizweImage from "../assets/images/swizerland.svg";
import butanImage from "../assets/images/butan.svg";
import tripBanner from "../assets/images/singapore-banner.png";
import trip1Banner from "../assets/images/dubai_trip.png";
import activiteImage from "../assets/images/activites.jpg";
import badgeImage from "../assets/images/badge.svg"
import { FaCheck } from "react-icons/fa";
import vactionImage from "../assets/images/at_solo.jpg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faCircleChevronRight } from '@fortawesome/free-solid-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css"; // Core Swiper styles
import "swiper/css/navigation"; // Navigation styles
import "swiper/css/pagination"; // Pagination styles
import saleImage from "../assets/images/sale.svg";
import Swal from "sweetalert2";
import { apiRequest } from '../ApiService/Allapi';
import { NavLink } from 'react-router-dom';
// Import Swiper modules
import { Navigation, Pagination } from "swiper/modules";
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import { useTranslation } from "react-i18next";
import { FaDownload } from "react-icons/fa6";
import Rewes from './Rewes';
const Homepage = () => {

    const [showDropdown, setShowDropdown] = useState(false);
    const [show, setShow] = useState(false);
    const [Loading, setLoading] = useState(true);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [activeTab, setActiveTab] = useState(1);
    const [data, setdata] = useState([])
    const [cities, setcities] = useState([])
    const [active, setactive] = useState("")

    const langs = localStorage.getItem("i18nextLng") ?? "en";
    const [lang, setlang] = useState(langs)

    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
    };


    const handleClose1 = () => setShow1(false);
    const [toursulg, settoursulg] = useState('')
    const handleShow1 = (slug) => {
        settoursulg(slug)
        setShow1(true);
    }


    const fetchData = async () => {
        setLoading(true);

        try {
            const res = await apiRequest(`home?lang=${lang}`, "GET", null);
            if (res.status === true) {
                setdata(res.data);
                setcities(res.location);
            } else {
                Swal.fire({
                    title: res.message || "Failed to fetch data",
                    icon: "error",
                });
            }
        } catch (error) {
            Swal.fire({
                title: "Error",
                text: "Something went wrong while fetching data!",
                icon: "error",

            });

        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [lang]);

    const getTour = async (slug) => {
        setactive(slug)
        // console.log(slug)
        setLoading(true);
        try {
            var url = '';
            if (slug === "activity") {
                url = `activities?lang=${lang}`;
            } else {
                url = 'home/' + slug;
            }


            const res = await apiRequest(url, "GET", null);
            if (res.status === true) {
                if (slug === "activity") {
                    setdata(res.data);
                } else {
                    setdata(res.data);
                    setcities(res.location);
                }

            } else {
                Swal.fire({
                    title: res.message || "Failed to fetch data",
                    icon: "error",
                });
            }
        }
        finally {
            setLoading(false);
        }
    }


    const [withs, setWiths] = useState(0);

    const handleWith = ((para) => {
        setWiths(para)
    })

    const [fixed, setfixed] = useState(0)

    const handlefixd = ((para) => {
        setfixed(para)
    })


    const [trip, settrip] = useState(0)

    const handletrip = (para) => {
        settrip(para)
    }
    const [selectedOption, setSelectedOption] = useState(null);
    const options = [
        { value: '+91', label: '+91' },
        { value: '+92', label: '+92' },
        { value: '+93', label: '+93' },
        { value: '+94', label: '+91' }
    ];
    const username = localStorage.getItem("name")
    const useremail = localStorage.getItem("email")

    const [SinForm, setSiSinForm] = useState({
        name: username,
        email: useremail,
        // Mobail9: "",
        Phone: "",
        Startdate: "",
        person: "",
        texteraa: ""
    });
    const [isLoading, setIsLoading] = useState(false);
    const handleSelectChange = (e) => {
        setSiSinForm({ ...SinForm, [e.target.name]: e.target.value });
    };



    const [show1, setShow1] = useState(false);
    const [Name, setName] = useState('')
    const [formid, setformid] = useState(null)

    const handleSumbit = async (e) => {
        e.preventDefault();

        const body = {
            name: SinForm.name,
            email: SinForm.email,
            phone: SinForm.Phone,
            message: SinForm.texteraa,
            person: SinForm.person,
            date: SinForm.Startdate,
            slug: toursulg
        };

        try {
            const result = await apiRequest(`enquiry?lang=${lang}`, "POST", body);
            if (result.status === true) {


                Swal.fire({
                    title: result.message,
                    icon: "success"
                });
                setName(result.data.name)
                setformid(result.data.id)

                // window.location.reload()s
            } else {
                Swal.fire({
                    title: result.message,
                    icon: "error"
                });
            }
        } finally {
            setIsLoading(false);
        }
    };

    const [tickets, settickets] = useState(0)

    const handletickets = async (para) => {
        settickets(para); // Updates the state asynchronously

        const body = {
            id: formid,
            with: withs,
            fixed: fixed,
            trip: trip,
            ticket: para
        };

        try {
            const result = await apiRequest(`enquiry-addon?lang=${lang}`, "POST", body);
            if (result.status === true) {
                Swal.fire({
                    title: result.message,
                    icon: "success"
                });
            } else {
                Swal.fire({
                    title: result.message,
                    icon: "error"
                });
            }
        } finally {
            setIsLoading(false);
        }
    };



    const { t, i18n } = useTranslation();

    const downloads = async (slug) => {
        setLoading(true); // Start loading before making request

        try {
            const res = await apiRequest(`download-pdf/${slug}`, "GET", null);

            if (res.status === true) {
                Swal.fire({
                    title: "Download started successfully!",
                    icon: "success",
                });

                if (res.file_url) {
                    const link = document.createElement("a");
                    link.href = res.file_url;
                    link.setAttribute("download", "filename.pdf"); // Specify default file name
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            } else {
                Swal.fire({
                    title: res.message || "Failed to fetch data",
                    icon: "error",
                });
            }
        } catch (error) {
            Swal.fire({
                title: "An error occurred!",
                text: error.message || "Something went wrong.",
                icon: "error",
            });
        } finally {
            setLoading(false);
        }
    };



    return (
        <>
            <div className='container '>

                <div className='main-bar'>
                    <div className='row'>

                        <div style={{ marginLeft: "-8px" }} className={active === "" || active === "activity" ? "col-xl-9 col-lg-8 px-0" : 'col-lg-12 ps-0'}>
                            <Swiper

                                slidesPerView={active === "" || active === "activity" ? 5 : 5}
                                spaceBetween={10}
                                pagination={{ clickable: true }}
                                breakpoints={{
                                    360: {
                                        slidesPerView: 4,
                                        spaceBetween: 5
                                    },

                                    // Medium devices (tablet)
                                    640: {
                                        slidesPerView: 5,
                                        spaceBetween: 5,
                                    },
                                    // Large devices (desktop)
                                    1024: {
                                        slidesPerView: 5,
                                        spaceBetween: 10,
                                    },
                                    // Extra large devices
                                    1200: {
                                        slidesPerView: 7,
                                        spaceBetween: 10,
                                    },
                                    // Extra large devices
                                    1440: {
                                        slidesPerView: 7,
                                        spaceBetween: 11,
                                    },
                                }}

                                className="mySwiper-header coustom_tabs"
                            >
                                <ul className="nav nav-tabs " id="myTab" role="tablist">

                                    <SwiperSlide>

                                        <li className="nav-item" role="presentation">
                                            <a
                                                className={`nav-link ${active === '' || active === "activity" ? 'active' : ''}`}
                                                id="home-tab"
                                                data-bs-toggle="tab"

                                                role="tab"
                                                aria-controls="home"
                                                aria-selected={activeTab === '' ? 'true' : 'false'}
                                                onClick={() => getTour("")}
                                            ><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none"><path d="M18.3958 11.6667C18.1562 11.3542 17.8646 11.0833 17.5937 10.8125C16.8958 10.1875 16.1042 9.73958 15.4375 9.08333C13.8854 7.5625 13.5417 5.05208 14.5312 3.125C13.5417 3.36458 12.6771 3.90625 11.9375 4.5C9.23957 6.66667 8.17707 10.4896 9.44791 13.7708C9.48957 13.875 9.53124 13.9792 9.53124 14.1146C9.53124 14.3438 9.37499 14.5521 9.16666 14.6354C8.92707 14.7396 8.67707 14.6771 8.47916 14.5104C8.41968 14.4612 8.37018 14.4011 8.33332 14.3333C7.15624 12.8438 6.96874 10.7083 7.76041 9C6.02082 10.4167 5.07291 12.8125 5.20832 15.0729C5.27082 15.5938 5.33332 16.1146 5.51041 16.6354C5.65624 17.2604 5.93749 17.8854 6.24999 18.4375C7.37499 20.2396 9.32291 21.5313 11.4167 21.7917C13.6458 22.0729 16.0312 21.6667 17.7396 20.125C19.6458 18.3958 20.3125 15.625 19.3333 13.25L19.1979 12.9792C18.9792 12.5 18.3958 11.6667 18.3958 11.6667ZM15.1042 18.2292C14.8125 18.4792 14.3333 18.75 13.9583 18.8542C12.7917 19.2708 11.625 18.6875 10.9375 18C12.1771 17.7083 12.9167 16.7917 13.1354 15.8646C13.3125 15.0312 12.9792 14.3438 12.8437 13.5417C12.7187 12.7708 12.7396 12.1146 13.0208 11.3958C13.2187 11.7917 13.4271 12.1875 13.6771 12.5C14.4792 13.5417 15.7396 14 16.0104 15.4167C16.0521 15.5625 16.0729 15.7083 16.0729 15.8646C16.1042 16.7188 15.7292 17.6563 15.1042 18.2292Z" fill="var(--primary-color, #f37002)"></path></svg>
                                                <span className='city_name' >{t("explore")}</span>
                                            </a>
                                        </li>

                                    </SwiperSlide>
                                    {cities.length === 0 ? (
                                        <p>{t("cities")}</p>
                                    ) : (
                                        cities.map((city) => {
                                            return (
                                                <SwiperSlide key={city.slug}>
                                                    <li className="nav-item" role="presentation">
                                                        <a
                                                            className={`nav-link ${active === city.slug ? 'active' : ''}`}
                                                            id="home-tab"
                                                            data-bs-toggle="tab"
                                                            href="#home"
                                                            role="tab"
                                                            aria-controls="home"
                                                            aria-selected={activeTab === 1 ? 'true' : 'false'}
                                                            onClick={() => getTour(city.slug)}
                                                        >
                                                            {city.is_trending === "1" ? <div className='trade_name'>
                                                                <span>{t("Trending")}<svg xmlns="http://www.w3.org/2000/svg" width="8" height="6" viewBox="0 0 8 6" fill="none"><path d="M4.16352 4.63487C4.16464 4.63618 4.16597 4.63722 4.16744 4.63793C4.16891 4.63864 4.17048 4.639 4.17207 4.639C4.17366 4.639 4.17524 4.63864 4.17671 4.63793C4.17818 4.63722 4.17951 4.63618 4.18063 4.63487L6.11434 2.39102C6.12531 2.3783 6.12538 2.36548 6.11456 2.35259L5.5437 1.68447C5.54064 1.68088 5.53844 1.67642 5.5373 1.67155C5.53617 1.66667 5.53614 1.66153 5.53722 1.65664C5.5383 1.65174 5.54046 1.64726 5.54348 1.64362C5.5465 1.63997 5.55028 1.6373 5.55445 1.63586C6.21701 1.41154 6.8874 1.20055 7.56561 1.00287C7.57292 1.00066 7.5776 0.999729 7.57965 1.00007C7.5912 1.00143 7.59581 1.00796 7.59347 1.01967C7.42863 1.80681 7.25195 2.5904 7.06342 3.37042C7.05625 3.40028 7.04309 3.40444 7.02393 3.38289L6.43333 2.7191C6.42948 2.71473 6.4243 2.71231 6.41892 2.71236C6.41353 2.71241 6.40838 2.71492 6.40459 2.71935C5.75944 3.46798 5.11333 4.21788 4.46628 4.96906C4.36448 5.08715 4.26137 5.27983 4.08695 5.19202C4.06004 5.17844 4.02384 5.14502 3.97835 5.09174C3.63625 4.69214 3.28888 4.28898 2.93625 3.88226C2.9263 3.87089 2.91643 3.87089 2.90663 3.88226C2.41885 4.44712 1.92932 5.01555 1.43803 5.58754C1.369 5.66797 1.31802 5.71777 1.28512 5.73695C1.08459 5.85326 0.899646 5.54351 1.06221 5.35491C1.61303 4.71623 2.16348 4.07713 2.71357 3.43761C2.80461 3.33198 2.87153 3.26428 2.99351 3.31315C3.02481 3.3257 3.05976 3.3548 3.09838 3.40045C3.4513 3.81684 3.80635 4.22832 4.16352 4.63487Z" fill="white" stroke="white" stroke-width="0.183168"></path></svg></span>
                                                            </div> : ""}


                                                            <img src={city.media[0].original_url} onerror="if (this.src != 'https://burst.shopifycdn.com/photos/city-landscape-at-night.jpg?width=1000&format=pjpg&exif=0&iptc=0') this.src = 'https://burst.shopifycdn.com/photos/city-landscape-at-night.jpg?width=1000&format=pjpg&exif=0&iptc=0';" alt={city.media[0].file_name}></img>

                                                            <span className='city_name' >{city?.name}</span>
                                                        </a>
                                                    </li>
                                                </SwiperSlide>
                                            );
                                        })
                                    )}
                                </ul>
                            </Swiper>


                        </div>

                        {active === "" || active === "activity" ?
                            <div className='col-xl-3 col-lg-4 px-0 d-sm-flex d-block align-items-center' >
                                <div class="HomePageBody_verticalSeperator__e_C7b"></div>
                                {/* <div className='activites_tab'>
                                    <ul className="nav nav-tabs" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button
                                                className={`nav-link ${active === '' ? 'active' : ''}`}
                                                onClick={() => setactive("")}
                                            >
                                                {t("Tour")}
                                            </button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button
                                                className={`nav-link ${active === "activity" ? 'active' : ''}`}
                                                onClick={() => getTour("activity")}
                                            >
                                                {t("Activities")}
                                            </button>
                                        </li>
                                    </ul>

                                </div> */}
                            </div>
                            : ''
                        }
                    </div>


                </div>
                <div>



                    <div className="seprator"></div>
                    <div className="tab-content" id="myTabContent">
                        {/* dubai-section-strat */}
                        <div
                            className={`tab-pane fade ${activeTab === 1 ? 'show active' : ''}`}
                            id="home"
                            role="tabpanel"
                            aria-labelledby="home-tab"
                        >
                            {data.length === 0 ? (
                                <div className="no-data-found">
                                    <p>{t("Nodatafound")}</p>
                                </div>
                            ) : (
                                data.map((item) => {
                                    return (
                                        <>
                                            <div className="d-flex align-items-center justify-content-between default_margin">
                                                <h2 className="heading">{item?.name}</h2>
                                                <a href="#" className="viewAll" onClick={(e) => { e.preventDefault(); getTour(item.slug); }}>
                                                    {t("ViewAll")}
                                                    <FontAwesomeIcon className="ms-2 fs-16" icon={faCircleChevronRight} />
                                                </a>
                                            </div>
                                            <div className="duai-content my-3">
                                                <div className='row'>

                                                    {active ? (
                                                        !item?.tours || item?.tours.length === 0 ? (<p>{t("Nodatafound")}</p>) : (
                                                            item?.tours?.map((tour) => {
                                                                return (
                                                                    <>

                                                                        <div className="col-lg-4 my-5">
                                                                            <NavLink to={`/tour/${tour.slug}`}>
                                                                                <div className="media-box">
                                                                                    <div className="col">
                                                                                        <div className="media-box">
                                                                                            <div className="dubai-media">
                                                                                                <Swiper
                                                                                                    modules={[Navigation, Pagination]}
                                                                                                    spaceBetween={20}
                                                                                                    slidesPerView={1}
                                                                                                    navigation
                                                                                                    pagination={{ clickable: true }}
                                                                                                    loop={true}
                                                                                                >
                                                                                                    {tour.media.length !== 0 ? (
                                                                                                        tour.media.map((img, index) => (
                                                                                                            <SwiperSlide key={index}>
                                                                                                                <img src={img.original_url} alt={`Slide ${index}`} style={{ width: "100%" }} />
                                                                                                            </SwiperSlide>
                                                                                                        ))
                                                                                                    ) : (
                                                                                                        <SwiperSlide>
                                                                                                            <img src={dubaiImage} alt="Fallback Example" style={{ width: "100%" }} />
                                                                                                        </SwiperSlide>
                                                                                                    )}
                                                                                                </Swiper>
                                                                                                <div className="sale-tag">
                                                                                                    <img src={saleImage} alt="Sale" style={{ width: "100%" }} />
                                                                                                    <small>{t("save")} {t("inr")} {tour.price - tour.sale_price}</small>

                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="dubai_content">
                                                                                                <div className="d-flex align-items-center justify-content-between mt-2 mb-1">
                                                                                                    <small className="title-xs">{tour.duration} {t("days")}</small>
                                                                                                    <a href="javascript:void(0);" className="text-success">
                                                                                                        <span className="title-xs">
                                                                                                            <FontAwesomeIcon className="text-success" icon={faStar} />
                                                                                                            <b className="text-success mx-1">{tour.review_score}</b>
                                                                                                        </span>
                                                                                                    </a>
                                                                                                </div>
                                                                                                <h6
                                                                                                    style={{
                                                                                                        width: "45vh",
                                                                                                        whiteSpace: "nowrap",
                                                                                                        overflow: "hidden",
                                                                                                        textOverflow: "ellipsis"
                                                                                                    }}
                                                                                                >{tour.title}</h6>
                                                                                                <div className="bottom-content">
                                                                                                    <div className="badge-light">
                                                                                                        <small className="title-small">
                                                                                                            <span><b className="me-1">{tour.duration} {t("days")}</b>{tour.address}</span>
                                                                                                        </small>
                                                                                                    </div>
                                                                                                    <div className="d-flex align-items-center">
                                                                                                        <h2 className="title mb-0">{t("inr")} {(Number(tour?.sale_price) || 0).toFixed(0)}</h2>
                                                                                                        <del>{t("inr")} {(Number(tour?.price) || 0).toFixed(0)}</del>
                                                                                                        <span className="badge badge-success">
                                                                                                            {t("save")} {t("inr")} {parseFloat(tour.price - tour.sale_price).toFixed(0)}
                                                                                                            {/* {parseFloat(admin.totalPtop).toFixed(2)}  */}
                                                                                                        </span>
                                                                                                    </div>

                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>


                                                                                </div>
                                                                            </NavLink>
                                                                            <div className="trip-card-btn">
                                                                                <button className="btn btn-primary" style={{ marginRight: "10px" }} onClick={() => downloads(tour.slug)}>
                                                                                    {t("Download")}   <FaDownload />
                                                                                </button>
                                                                                <button
                                                                                    className="btn btn-primary"
                                                                                    onClick={() => handleShow1(tour.slug)}
                                                                                >
                                                                                    {t("RequestCallback")}
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                );
                                                            })
                                                        )) : (
                                                        <Swiper
                                                            className="main-swiper"
                                                            modules={[Navigation, Pagination]}
                                                            spaceBetween={20}
                                                            slidesPerView={3}
                                                            navigation
                                                            pagination={{ clickable: true }}
                                                            loop={true}
                                                            breakpoints={{
                                                                // Small devices (mobile)
                                                                320: {
                                                                    slidesPerView: 1,
                                                                    spaceBetween: 5,
                                                                },
                                                                // Medium devices (tablet)
                                                                640: {
                                                                    centeredSlides: true,
                                                                    slidesPerView: 2,
                                                                    spaceBetween: 20,
                                                                },
                                                                640: {
                                                                    slidesPerView: 2,
                                                                    spaceBetween: 20,
                                                                },
                                                                // Large devices (desktop)
                                                                1024: {
                                                                    slidesPerView: 2,
                                                                    spaceBetween: 20,
                                                                },
                                                                // Extra large devices
                                                                1200: {
                                                                    slidesPerView: 3,
                                                                    spaceBetween: 20,
                                                                },
                                                                // Extra large devices
                                                                1440: {
                                                                    slidesPerView: 3,
                                                                    spaceBetween: 20,
                                                                },
                                                            }}
                                                        >  {!item?.tours || item?.tours?.length === 0 ? (<p>{t("Nodatafound")}</p>) : (item?.tours?.map((tour) => {
                                                            return (
                                                                <>
                                                                    <SwiperSlide>
                                                                        <NavLink to={`/tour/${tour.slug}`}>
                                                                            <div className="col">
                                                                                <div className="media-box">
                                                                                    <div className="dubai-media">
                                                                                        <Swiper
                                                                                            className='sub-swiper'
                                                                                            modules={[Navigation, Pagination]}
                                                                                            spaceBetween={20}
                                                                                            slidesPerView={1}
                                                                                            navigation
                                                                                            pagination={{ clickable: true }}
                                                                                            loop={true}
                                                                                        >
                                                                                            {tour.media.length !== 0 ? (
                                                                                                tour.media.map((img, index) => (
                                                                                                    <SwiperSlide key={index}>
                                                                                                        <img src={img.original_url} alt={`Slide ${index}`} style={{ width: "100%" }} />
                                                                                                    </SwiperSlide>
                                                                                                ))
                                                                                            ) : (
                                                                                                <SwiperSlide>
                                                                                                    <img src={dubaiImage} alt="Fallback Example" style={{ width: "100%" }} />
                                                                                                </SwiperSlide>
                                                                                            )}
                                                                                        </Swiper>
                                                                                        <div className="sale-tag">
                                                                                            <img src={saleImage} alt="Sale" style={{ width: "100%" }} />
                                                                                            <small>{t("save")} {t("inr")} {tour.price - tour.sale_price}</small>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="dubai_content">
                                                                                        <div className="d-flex align-items-center justify-content-between mt-2 mb-1">
                                                                                            <small className="title-xs">{tour.duration} {t("days")}</small>
                                                                                            <a href="javascript:void(0);" className="text-success">
                                                                                                <span className="title-xs">
                                                                                                    <FontAwesomeIcon className="text-success" icon={faStar} />
                                                                                                    <b className="text-success mx-1">{tour.review_score}</b>
                                                                                                    (1.2k)
                                                                                                </span>
                                                                                            </a>
                                                                                        </div>
                                                                                        <h6
                                                                                            style={{
                                                                                                width: "50vh",
                                                                                                whiteSpace: "nowrap",
                                                                                                overflow: "hidden",
                                                                                                textOverflow: "ellipsis"
                                                                                            }}
                                                                                        >
                                                                                            {tour.title}
                                                                                        </h6>
                                                                                        <div className="bottom-content">
                                                                                            <div className="badge-light">
                                                                                                <small className="title-small">
                                                                                                    <span><b className="me-1">{tour.duration} {t("days")}</b>{tour.address}</span>
                                                                                                </small>
                                                                                            </div>
                                                                                            <div className="d-flex align-items-center" style={{ whiteSpace: "nowrap" }}>
                                                                                                <h2 className="title mb-0 me-2">{t("inr")} {(Number(tour?.sale_price) || 0).toFixed(0)}</h2>
                                                                                                <del className="me-2">{t("inr")} {(Number(tour?.price) || 0).toFixed(0)}</del>
                                                                                                <span className="badge badge-success">
                                                                                                    {t("save")} {t("inr")} {((Number(tour?.price) || 0) - (Number(tour?.sale_price) || 0)).toFixed(0)}
                                                                                                </span>
                                                                                            </div>



                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </div>

                                                                        </NavLink>
                                                                        <div className="trip-card-btn">
                                                                            <button className="btn btn-primary" style={{ marginRight: "10px" }} onClick={() => downloads(tour.slug)}>
                                                                                {t("Download")}    <FaDownload />
                                                                            </button>
                                                                            <button
                                                                                className="btn btn-primary"
                                                                                onClick={() => handleShow1(tour.slug)}
                                                                            >
                                                                                {t("RequestCallback")}
                                                                            </button>
                                                                        </div>


                                                                    </SwiperSlide>

                                                                </>
                                                            );
                                                        }))}

                                                        </Swiper>
                                                    )}
                                                </div>
                                            </div>
                                        </>
                                    );
                                })
                            )}

                        </div>
                    </div>
                </div>
            </div>

            <Rewes />


            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Museum Of The Future</Modal.Title>
                </Modal.Header>
                <Modal.Body><p className='fs-14'>The Museum of Future, Dubai is a new era museum themed on modern technology. As the name suggests, this museum exhibits the amalgamation of the items from future, ranging from games and gadget ideas which further make a visit here too much fun. Whether you are planning to visit here with your kids or just want to explore it on your own, this museum will surely amuse you.

                    <b>Planning to visit Dubai? Don’t miss out on curated </b>Dubai Packages.</p></Modal.Body>

            </Modal>

            <Modal className='normal_model' show={show1} onHide={handleClose1}>
                <Modal.Header className='border-0' closeButton>
                    <Modal.Title>
                        <h6 className='mb-0'>{t("save")}<span>{t("OFF")}</span></h6>
                        <small className='LeadForm_discountHelpText__AbNHL'>{t("needing")}</small>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='pt-0'>
                    <div className='row'>
                        <div className='col-lg-12 custom_tabs_content style-1'>

                            <Form onSubmit={handleSumbit} className={Name ? "d-none" : 'mt-3'}>
                                <div className='row gx-2'>
                                    <Form.Group controlId="exampleForm.ControlInput1">
                                        <Form.Control type="text" className='form-control-lg' placeholder={t("Names")} name='name' value={SinForm.name} onChange={handleSelectChange} />
                                    </Form.Group>
                                    <Form.Group controlId="exampleForm.ControlInput2">
                                        <Form.Control type="email" className='form-control-lg' name="email" placeholder={t("Emails")} value={SinForm.email} onChange={handleSelectChange} />
                                    </Form.Group>
                                    <div className='col-lg-4'>
                                        <Select
                                            options={options}
                                            // value={selectedOption}

                                            placeholder="+91"
                                            name='Mobail9'

                                        />
                                    </div>
                                    <div className='col-lg-8'>
                                        <Form.Group controlId="exampleForm.ControlInput2">
                                            <Form.Control type="number" className='form-control-lg' placeholder={t("number")} value={SinForm.Phone} name='Phone' onChange={handleSelectChange} />
                                        </Form.Group>
                                    </div>
                                    <div className='col-lg-6'>
                                        <Form.Group controlId="exampleForm.ControlInput1">
                                            <Form.Control type="date" className='form-control-lg' placeholder="" value={SinForm.Startdate} name='Startdate' onChange={handleSelectChange} />
                                        </Form.Group>
                                    </div>
                                    <div className='col-lg-6'>
                                        <Form.Group controlId="exampleForm.ControlInput2">
                                            <Form.Control type="number" className='form-control-lg' placeholder={t("persons")} value={SinForm.person} name="person" onChange={handleSelectChange} />
                                        </Form.Group>
                                    </div>
                                </div>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1" >

                                    <Form.Control as="textarea" rows={4} value={SinForm.texteraa} onChange={handleSelectChange} name='texteraa' placeholder={t("comment")} />
                                </Form.Group>
                                {/* <button className='btn btn-primary'>Send Enquiry</button> */}

                                <Button variant="primary" type='submit'>
                                    {t("anExperts")}
                                </Button>
                            </Form>

                        </div>
                    </div>
                    <div className={Name === '' ? "d-none vaction_tour" : "vaction_tour"}>
                        <div className='d-flex'>
                            <div className='vaction_icon'>
                                <FaCheck />
                            </div>
                            <div style={{ lineHeight: "1" }} >
                                <span className='fs-13 d-block font-w400'>{t("Awesome")} <strong className='text-orange'>{Name}</strong> </span>
                                <small className='fs-10 text-small'>{t("personalisedquotation")}</small>
                            </div>
                        </div>
                        <div className='th-lf-cps-divider'></div>
                        {/* trip_image_strat */}
                        <div className={withs === 0 ? 'row g-2' : 'row g-2 d-none'}>
                            <h6 className='vaction-title '>{t("vacationwith")}</h6>
                            <div className='col-lg-6'>
                                <div className='tour_slected'>
                                    <form>
                                        <label className="form-check-label" htmlFor="exampleCheck-5">
                                            <div className="form-check ps-0">
                                                <span className='form-check-label'>
                                                    <img src={vactionImage} className='w-100' alt="" />
                                                </span>
                                                <input type="checkbox" className="form-check-input" id="exampleCheck-5" name='Solo' onClick={() => handleWith(1)} />

                                            </div>
                                            <div className='text-center mt-2'>
                                                <h6 className='mb-0 fs-14 text-small'>{t("Solo")}</h6>
                                            </div>
                                        </label>

                                    </form>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='tour_slected'>
                                    <form>
                                        <label className="form-check-label" htmlFor="exampleCheck-5">
                                            <div className="form-check ps-0">
                                                <span className='form-check-label'>
                                                    <img src={vactionImage} className='w-100' alt="" />
                                                </span>
                                                <input type="checkbox" className="form-check-input" id="exampleCheck-5" name='Spouse' onClick={() => handleWith(2)} />
                                                <div className='text-center mt-2'>
                                                    <h6 className='mb-0 fs-14 text-small'>{t("Spouse")}</h6>
                                                </div>
                                            </div>
                                        </label>

                                    </form>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='tour_slected'>
                                    <form>
                                        <label className="form-check-label" htmlFor="exampleCheck-5">
                                            <div className="form-check ps-0">
                                                <span className='form-check-label'>
                                                    <img src={vactionImage} className='w-100' alt="" />
                                                </span>
                                                <input type="checkbox" className="form-check-input" id="exampleCheck-5" name='Family' onClick={() => handleWith(3)} />

                                            </div>
                                            <div className='text-center mt-2'>
                                                <h6 className='mb-0 fs-14 text-small'>{t("Family")}</h6>
                                            </div>
                                        </label>

                                    </form>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='tour_slected'>
                                    <form>
                                        <label className="form-check-label" htmlFor="exampleCheck-5">
                                            <div className="form-check ps-0">
                                                <span className='form-check-label'>
                                                    <img src={vactionImage} className='w-100' alt="" />
                                                </span>
                                                <input type="checkbox" className="form-check-input" id="exampleCheck-5" name='Friends' onClick={() => handleWith(4)} />
                                                <div className='text-center mt-2'>
                                                    <h6 className='mb-0 fs-14 text-small'>{t("Friends")}</h6>
                                                </div>
                                            </div>
                                        </label>

                                    </form>
                                </div>
                            </div>
                        </div>
                        {/* trip_image_end */}
                        {/* fixed_date_strat */}
                        <div className={withs ? !fixed ? 'row g-2' : 'row g-2 d-none' : 'row g-2 d-none'}>
                            <h6 className='vaction-title '>{t("traveldate")}</h6>
                            <div className='col-lg-12'>
                                <div className='date_slected'>
                                    <form>
                                        <label className="form-check-label" htmlFor="exampleCheck-8">
                                            <div className="form-check ">
                                                <span className='form-check-label'>
                                                    {t("DateFixed")}
                                                </span>
                                                <input type="checkbox" className="form-check-input" id="exampleCheck-8" name='Date' onClick={() => handlefixd(1)} />

                                            </div>

                                        </label>

                                    </form>
                                </div>
                                <div className='date_slected'>
                                    <form>
                                        <label className="form-check-label" htmlFor="exampleCheck-8">
                                            <div className="form-check ">
                                                <span className='form-check-label'>
                                                    {t("flexible")}
                                                </span>
                                                <input type="checkbox" className="form-check-input" id="exampleCheck-8" name='travel' onClick={() => handlefixd(2)} />

                                            </div>

                                        </label>

                                    </form>
                                </div>
                                <div className='date_slected'>
                                    <form>
                                        <label className="form-check-label" htmlFor="exampleCheck-8">
                                            <div className="form-check ">
                                                <span className='form-check-label'>
                                                    {t("exploring")}
                                                </span>
                                                <input type="checkbox" className="form-check-input" id="exampleCheck-8" name='exploring' onClick={() => handlefixd(3)} />

                                            </div>

                                        </label>

                                    </form>
                                </div>
                            </div>

                        </div>
                        {/* fixed_date_end */}
                        {/* book_date_strat */}

                        <div className={fixed ? !trip ? 'row g-2' : 'row g-2 d-none' : 'row g-2 d-none'}>
                            <h6 className='vaction-title '>{t("thistrip")}</h6>
                            <div className='col-lg-12'>
                                <div className='date_slected'>
                                    <form>
                                        <label className="form-check-label" htmlFor="exampleCheck-8">
                                            <div className="form-check ">
                                                <span className='form-check-label'>
                                                    {t("WithinHours")}
                                                </span>
                                                <input type="checkbox" className="form-check-input" id="exampleCheck-8" name='Within' onClick={() => handletrip(1)} />

                                            </div>

                                        </label>

                                    </form>
                                </div>
                                <div className='date_slected'>
                                    <form>
                                        <label className="form-check-label" htmlFor="exampleCheck-8">
                                            <div className="form-check ">
                                                <span className='form-check-label'>
                                                    {t("WithinHour")}
                                                </span>
                                                <input type="checkbox" className="form-check-input" id="exampleCheck-8" name='days' onClick={() => handletrip(2)} />

                                            </div>

                                        </label>

                                    </form>
                                </div>
                                <div className='date_slected'>
                                    <form>
                                        <label className="form-check-label" htmlFor="exampleCheck-8">
                                            <div className="form-check ">
                                                <span className='form-check-label'>
                                                    {t("WithinHou")}
                                                </span>
                                                <input type="checkbox" className="form-check-input" id="exampleCheck-8" name='week' onClick={() => handletrip(3)} />

                                            </div>

                                        </label>

                                    </form>
                                </div>
                                <div className='date_slected'>
                                    <form>
                                        <label className="form-check-label" htmlFor="exampleCheck-8">
                                            <div className="form-check ">
                                                <span className='form-check-label'>
                                                    {t("WithinHo")}
                                                </span>
                                                <input type="checkbox" className="form-check-input" id="exampleCheck-8" name='Reaseaching' onClick={() => handletrip(4)} />

                                            </div>

                                        </label>

                                    </form>
                                </div>
                            </div>

                        </div>
                        {/* book_date_end */}
                        {/* flight _strat */}

                        <div className={trip && !tickets ? 'row g-2' : 'row g-2 d-none'}>
                            <h6 className="vaction-title">{t("tickets")}</h6>

                            {/* Flight tickets booked option */}
                            <div className="col-lg-12">
                                {/* Already Booked Option */}
                                <div className="date_slected">
                                    <form>
                                        <label className="form-check-label" htmlFor="exampleCheck-1">
                                            <div className="form-check">
                                                <span className="form-check-label">
                                                    {t("booked")}
                                                </span>
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="exampleCheck-1"
                                                    name="booked"
                                                    onChange={() => handletickets(1)} // ✅ Changed to onChange
                                                />
                                            </div>
                                        </label>
                                    </form>
                                </div>

                                {/* Need Help in Booking Flight Tickets */}
                                <div className="date_slected">
                                    <form>
                                        <label className="form-check-label" htmlFor="exampleCheck-2">
                                            <div className="form-check">
                                                <span className="form-check-label">
                                                    {t("helpbooking")}
                                                </span>
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="exampleCheck-2"
                                                    name="flight"
                                                    onChange={() => handletickets(2)} // ✅ Changed to onChange
                                                />
                                            </div>
                                        </label>
                                    </form>
                                </div>

                                {/* Will Book Flight Tickets on My Own */}
                                <div className="date_slected">
                                    <form>
                                        <label className="form-check-label" htmlFor="exampleCheck-3">
                                            <div className="form-check">
                                                <span className="form-check-label">
                                                    {t("bookflight")}
                                                </span>
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="exampleCheck-3"
                                                    name="book"
                                                    onChange={() => handletickets(3)} // ✅ Changed to onChange
                                                />
                                            </div>
                                        </label>
                                    </form>
                                </div>
                            </div>

                        </div>


                        <div className={tickets ? "thank_box" : 'd-none'}>
                            <div className="vaction_icon style-1">
                                <FaCheck />
                            </div>
                            <div>
                                <h6 className="mt-3" style={{ fontWeight: '600' }}>
                                    {t("thank")} <span className="text-orange">{Name}</span>
                                </h6>
                                <p className="mb-0 fs-14">
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ea libero reprehenderit necessitatibus blanditiis.
                                </p>
                            </div>
                        </div>

                        {/* book_date_end */}
                    </div>
                </Modal.Body>
                {/* <Modal.Footer>
                    
                    
                </Modal.Footer> */}
            </Modal>
        </>

    );
};

export default Homepage;
