import React from 'react';
import "../assets/style.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../src/assets/chardham.png';
import brajkos from '../../src/assets/brajkos.png'
import brajkos1 from '../../src/assets/brajkos1.jpg'

const BrajKos = () => {
    return (
        <>
            <div>

                <img className='w-100' src={brajkos} alt="brajkos.png" />
            </div>
            <section className='section-padding'>
                <div className='container'>
                    <div className>
                        <div className='row'>
                            <div className='col-lg-12 tabs'>
                                <h1>Braj Chaurasi Kos Yatra with Om Shiv Shankar Tirth Yatra   </h1>
                                <span>
                                </span> <br />

                                <p classname="about_para">Through Vrindavan’s lanes and Govardhan’s might,
                                    Where Krishna danced in moonlit night.
                                    Each step echoes the flute’s sweet call,
                                    In Braj, the Lord resides in all.
                                    The Yamuna flows with love so deep,
                                    Where Radha’s whispers the breezes keep.
                                    From Nandgaon’s joy to Barsana’s grace,
                                    Every path is Krishna’s embrace.
                                    Come, walk this journey, pure and true,
                                    With Om Shiv Shankar Tirth Yatra guiding you. 🚩
                                </p>
                                <br />
                                <h1>Braj Chaurasi Kos Yatra: Walk in the Footsteps of Shri Krishna   </h1> <br />
                                <p># **Braj Chaurasi Kos Yatra: Walk in the Footsteps of Shri Krishna**  
                                </p>

                                <p>Experience the divine essence of **Braj Chaurasi Kos Yatra**, a sacred journey through **Vrindavan, Govardhan, Barsana, and Nandgaon**,
                                     where every step echoes the leelas of **Shri Krishna**. This yatra, covering **84 kos (252 km)**, is a path of devotion, 
                                     love, and spiritual awakening.  
                                </p>

                                <h3> **Why Join This Yatra?**      </h3>

                                <p>🌿 **Walk the Holy Land** – Relive the pastimes of Krishna in the very places He played, loved, and performed miracles. </p>
                                <p> 🙏 **Seek Divine Blessings** – Visit sacred temples, circumambulate Govardhan Hill, and feel the
                                     presence of the Lord in every moment.  
                                </p>
                                <p>✨ **A Journey of Bhakti and Bliss** – With **Om Shiv Shankar Tirth Yatra**, travel worry-free and immerse yourself in the
                                     **divine love of Krishna**.  
                                </p>

                                <p> **Join us for this sacred pilgrimage and let Krishna’s grace fill your heart!**  </p>
                                <p>🚩Experience the divine essence of Braj Chaurasi Kos Yatra, a sacred journey through Vrindavan, Govardhan, Barsana, 
                                    and Nandgaon, where every step echoes the leelas of Shri Krishna. This yatra, covering 84 kos (252 km),
                                     is a path of devotion, love, and spiritual awakening.
                                </p>

                                <h3>Why Join This Yatra?   </h3>
                                <p>🌿 Walk the Holy Land – Relive the pastimes of Krishna in the very places He played, loved, and performed miracles.
                                </p>
                                <p> 🙏 Seek Divine Blessings – Visit sacred temples, circumambulate Govardhan Hill, and feel the presence of the Lord in every moment.
                                </p>
                                <p> ✨ A Journey of Bhakti and Bliss – With Om Shiv Shankar Tirth Yatra, travel worry-free and immerse yourself in the divine love of Krishna.
                                </p>
                                <p>Join us for this sacred pilgrimage and let Krishna’s grace fill your heart! 🚩
                                </p>
                               
                            </div>
                            

                        </div>
                        <div className='mt-4'>
                            <img className="w-100" src={brajkos1} alt="brajkos1.png" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
export default BrajKos;