import React from "react";
import "../../css/footer.css"; // Import CSS file for styling
import "../../assets/style.css"
import { Link } from 'react-router-dom';
import logoFooter from "../../assets/images/logo-footer.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import footerImage from "../../assets/images/footer-media/india.jpg";
import footer1Image from "../../assets/images/footer-media/bali.jpg";
import footer2Image from "../../assets/images/footer-media/dubai.jpg";
import footer3Image from "../../assets/images/footer-media/thailand.jpg";
import footer4Image from "../../assets/images/footer-media/andaman.jpg";
import footer5Image from "../../assets/images/footer-media/singapore.jpg";
import footer6Image from "../../assets/images/footer-media/ladakh.jpg";
import footer7Image from "../../assets/images/footer-media/hongkong.jpg";
import footer8Image from "../../assets/images/footer-media/srilanka.jpg";
import { useTranslation } from "react-i18next";

import { faFacebookF,faInstagram,faTwitter,faLinkedin,faYoutube    } from '@fortawesome/free-brands-svg-icons';


function Footer() {
      const { t, i18n } = useTranslation();
  
  return (
    <>
    <div className="Divider_divider__yaBCO  "></div>
    <section className='tags section-padding pt-0'>
            <div class="hiring-titk">
                    <h1 class="mb-4">{t("Popular")}</h1>
                 
                 <div className="container">
                 <div class="row">
               <div class="col-12">
                  <div class="link-wrap">
                     {/* <div class="page-link"><a href="#">Teen Dham 11 Jyotirling Tirth Yatra by train</a></div> */}
                     <div class="page-link"> <Link to="/chardhamyatra" onClick={() => window.scrollTo(0, 0)}>Char Dham Yatra Uttarakhand</Link></div>
                     <div class="page-link"> <Link to="/brajkos" onClick={() => window.scrollTo(0, 0)}>Braj Chaurasi Kos Yatra</Link></div>
                     <div class="page-link"> <Link to="/andman" onClick={() => window.scrollTo(0, 0)}>Andaman-Nicobar Yatraa</Link></div>
                     <div class="page-link"> <Link to="/rameshwaram" onClick={() => window.scrollTo(0, 0)}>Ek Dham Rameshwaram Dham by Flight</Link></div>
                     <div class="page-link"> <Link to="/puriNepal" onClick={() => window.scrollTo(0, 0)}>Puri-Nepal-Gangasagar Yatra</Link></div>
                     <div class="page-link"> <Link to="/kamakhya" onClick={() => window.scrollTo(0, 0)}>Kamakhya Devi, Sikkim, Nepal Yatra</Link></div>
                     <div class="page-link"> <Link to="/nepal" onClick={() => window.scrollTo(0, 0)}>Ek Dham Rameshwaram Dham Rail Yatra</Link></div>
                     <div class="page-link"> <Link to="/bhutan" onClick={() => window.scrollTo(0, 0)}> Bhutan Yatra</Link></div>
                     <div class="page-link"> <Link to="/bangkok" onClick={() => window.scrollTo(0, 0)}>Bangkok-Cambodia Yatra</Link></div>
                     <div class="page-link"> <Link to="/srilanka" onClick={() => window.scrollTo(0, 0)}> Sri Lanka Ramayan Yatra</Link></div>
                  </div>
               </div>
            </div>
                 </div>


            </div>
        </section>
      <section className="footer-section">
          <div className="container">
              <div className="footer-main">
                 <div className="row">
                      <div className="col-lg-3 col-md-4 col-sm-6 border-right">
                          <div className="footer_link">
                              <h2 className="">ABOUT  Om Shankar tirthyatra</h2>
                              <ul>
                                <li> <Link to="/about" onClick={() => window.scrollTo(0, 0)} >About</Link></li>
                                <li><Link to="/" onClick={() => window.scrollTo(0, 0)} >Home</Link></li>
                                <li><Link to="/blog" onClick={() => window.scrollTo(0, 0)} >Blog</Link></li>
                                {/* <li><Link to="/termsofservice" onClick={() => window.scrollTo(0, 0)} >Terms of Service</Link></li> */}
                               
                                <li><Link to="/accountDetails" onClick={() => window.scrollTo(0, 0)} >Account Details</Link></li>
                                <li><Link to="/terms-condition" onClick={() => window.scrollTo(0, 0)}  >Tearms Of Service</Link></li>
                                <li><Link to="/support" >Support</Link></li>
                              </ul>
                          </div>
                      </div>
                      <div className="col-lg-3 col-md-4 col-sm-6 border-right">
                          <div className="footer_link">
                              <h2 className="">FOR Support</h2>
                              <ul>
                                {/* <li><a className="">Account</a></li> */}
                                <li><Link to="/contect" onClick={() => window.scrollTo(0, 0)}>Contact us</Link></li>
                              </ul>
                              <h2 className="">FOR BRANDS</h2>
                              <ul>
                              <li><Link to="/carrier" onClick={() => window.scrollTo(0, 0)} >Carrier With us</Link></li>
                               
                                
                              </ul>
                          </div>
                      </div>
                     
                      <div className="col-lg-3 col-md-5 col-sm-6 mt-lg-0 mt-md-4 mt-3">
                          <div className="footer_link">
                              <h2 className="">TRAVEL DESTINATIONS</h2>
                              <div className="travel_destination">
                                <div className="travel_img">
                                    <a href="#"><img src={footerImage}  alt="" /></a>
                                    
                                    <span className="travel_text">India</span>
                                </div>
                                <div className="travel_img">
                                    <a href="#"><img src={footer1Image}  alt="" /></a>
                                    <span className="travel_text">BALI</span>
                                </div>
                                <div className="travel_img">
                                    <a href="#"><img src={footer2Image}  alt="" /></a>
                                    <span className="travel_text">Dubai</span>
                                </div>
                                <div className="travel_img">
                                    <a href="#"><img src={footer3Image}  alt="" /></a>
                                    <span className="travel_text">THAILAND</span>
                                    
                                </div>
                                <div className="travel_img">
                                    <a href="#"><img src={footer4Image}  alt="" /></a>
                                     <span className="travel_text">ANDAMAN</span> 
                                </div>
                                <div className="travel_img">
                                    <a href="#"><img src={footer5Image}  alt="" /></a>
                                     <span className="travel_text">SINGAPORE</span> 
                                </div>
                                <div className="travel_img">
                                    <a href="#"><img src={footer6Image}  alt="" /></a>
                                    <span className="travel_text">LADAKH</span> 
                                </div>
                                <div className="travel_img">
                                    <a href="#"><img src={footer7Image}  alt="" /></a>
                                    <span className="travel_text">HONGKONG</span> 
                                </div>
                                <div className="travel_img">
                                    <a href="#"><img src={footer8Image}  alt="" /></a>
                                    <span className="travel_text">SRILANKA</span> 
                                </div>
                              </div>
                          </div>
                      </div>
                 </div>
              </div>
              <div className="footer-box">
                  <div className="logo_footer">
                    <img src={logoFooter} alt="Example" style={{ width: "14%", filter:"brightness(55.5)" }} />
                  </div>
                  <div className="social_box">
                      <ul>
                        <li><a href="https://www.facebook.com/omshivshankartrithyatra" className="icon-box"><FontAwesomeIcon icon={faFacebookF} /></a ></li>
                        <li><a href="https://www.instagram.com/omshivshankaryatraofficial?igsh=MWMyZ2poN2hldTRxYw==" className="icon-box"><FontAwesomeIcon icon={faInstagram } /></a ></li>
                        <li><a href="https://twitter.com/omsstirthyatra" className="icon-box"><FontAwesomeIcon icon={faTwitter } /></a ></li>
                        <li><a href="https://www.linkedin.com/public-profile/settings?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_self_edit_contact-info%3B25gaLVv8Qa6DFpMAkAhzuQ%3D%3D" className="icon-box"><FontAwesomeIcon icon={faLinkedin} /></a ></li>
                        <li><a href="" className="icon-box me-0"><FontAwesomeIcon icon={faYoutube } /></a ></li>
                      </ul>
                      
                  </div>
                  <div className="extra-footer-content text-center">
                        <b className="mb-3">© 2024 OM Shanka All rights reserved.</b>
                        <p className="mt-3">The content and images used on this site are copyright protected and copyrights vests with the respective owners. The usage of the content and images on this website is intended to promote the works and no endorsement of the artist shall be implied. Unauthorized use is prohibited and punishable by law.</p>
                  </div>
              </div>
          </div>
      </section>
    </>
  );
}

export default Footer;
