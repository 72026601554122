import React from 'react';
import "../assets/style.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import srilanka from '../../src/assets/srilanka.png'
import srilanka1 from '../../src/assets/srilanka1.jpg'


const Srilanka = () => {
    return (
        <>
            <div>

                <img className='w-100' src={srilanka} alt="srilanka.png" />
            </div>
            <section className='section-padding'>
                <div className='container'>
                    <div className>
                        <div className='row'>
                            <div className='col-lg-12 tabs'>
                                <h1>Sri Lanka Ramayan Yatra: A Sacred Trail
                                </h1>
                                <span>
                                </span> <br />

                                <p classname="about_para">Where oceans whisper and forests sigh,
                                    Echoes of Ram still touch the sky.
                                    Footsteps blessed on sacred land,
                                    A journey divine, a yatra grand.
                                    Ashok Vatika’s trees still tell,
                                    The tale where Sita once did dwell.
                                    Mountains stand where battles roared,
                                    Where dharma’s light through darkness soared.
                                    Come, seek the path so pure, so bright,
                                    With faith and devotion as your light.
                                    With Om Shiv Shankar Tirth Yatra by your side,
                                    Walk with Ram, in grace and pride. 🚩

                                </p>
                                <br />
                                <h1>Sri Lanka Ramayan Yatra: Walk the Path of Lord Ram
                                </h1> <br />
                                <p>Embark on a divine journey through Sri Lanka, where the epic Ramayana comes to life. This sacred yatra takes you to sites believed to be linked to Lord Ram, Mata Sita, and Ravana, offering a deep spiritual experience.
                                </p>

                                <h3>Why Choose the Sri Lanka Ramayan Yatra?
                                </h3>
                                <p>🌿 Sacred Sites of the Ramayana – Visit ancient temples, Ashok Vatika, and the legendary battlefield where Lord Ram defeated Ravana.

                                </p>
                                <p>🙏 A Journey of Faith and Devotion – Experience the divine energy of places where history and mythology blend beautifully.
                                </p>
                                <p>🏞️ Breathtaking Natural Beauty – Explore Sri Lanka’s serene beaches, lush forests, and majestic hills along this sacred trail.
                                </p>
                                <p> ✨ A Well-Planned Pilgrimage – Travel comfortably with Om Shiv Shankar Tirth Yatra, ensuring a smooth, guided spiritual experience.
                                </p>
                               
                                <p>Join us for the Sri Lanka Ramayan Yatra and walk in the footsteps of Lord Ram! Book now! 🚩
                                </p>
                              
                            </div>

                        </div >
                        <div className='mt-4'>
                            <img className="w-100" src={srilanka1} alt="srilanka1.png" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Srilanka;