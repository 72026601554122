import React, { useState } from 'react';
import "../../assets/style.css";
import { IoArrowBackSharp } from "react-icons/io5";
import Form from 'react-bootstrap/Form';
import { apiRequest } from '../../ApiService/Allapi';
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function ChangePassword({ onBack }) {
    const langs = localStorage.getItem("i18nextLng") ?? "en";
    const [lang, setLang] = useState(langs);
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()
    const [data, setData] = useState({
        old_pwd: "",
        new_pwd: "",
        cnf_pwd: ""
    });

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const body = {
            old_pwd: data.old_pwd,
            new_pwd: data.new_pwd,
            cnf_pwd: data.cnf_pwd
        };

        try {
            const result = await apiRequest(`update-password?lang=${lang}`, "POST", body);
            if (result.status === true) {
                localStorage.clear("token", "name")
                Swal.fire({
                    title: result.message,
                    icon: "success",
                });

                setTimeout(() => {
                    navigate("/");
                    window.location.reload();
                }, 3000);
            }
            else {
                Swal.fire({
                    title: result.message,
                    icon: "error"
                });
            }

        } catch (error) {
            Swal.fire({
                title: error.message,
                icon: "error",
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <div className='edit_box'>
                <div className='back_btn'>
                    <a href="#" onClick={(e) => { e.preventDefault(); onBack(); }}>
                        <IoArrowBackSharp />
                    </a>
                </div>

                <div className='card'>
                    <div className='card-header'>
                        <h4>{t("password")}</h4>
                    </div>
                    <div className='card-body'>
                        <Form onSubmit={handleSubmit}>
                            <div className='row'>
                                <div className='col-lg-8'>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>{t("CurrentPassword")}</Form.Label>
                                        <Form.Control
                                            type="password"
                                            placeholder={t("CurrentPassword")}
                                            name="old_pwd"
                                            value={data.old_pwd}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                                        <Form.Label>{t("NewPassword")}</Form.Label>
                                        <Form.Control
                                            type="password"
                                            placeholder={t("NewPassword")}
                                            name="new_pwd"
                                            value={data.new_pwd}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                                        <Form.Label>{t("ConfirmPassword")}</Form.Label>
                                        <Form.Control
                                            type="password"
                                            placeholder={t("ConfirmPassword")}
                                            name="cnf_pwd"
                                            value={data.cnf_pwd}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                </div>

                                <div className='d-flex align-items-center justify-content-end'>
                                    <button
                                        type="button"
                                        className='btn btn-light me-1'
                                        onClick={onBack}
                                    >
                                        {t("Cancel")}
                                    </button>
                                    <button
                                        type="submit"
                                        className='btn btn-primary w-auto'
                                        disabled={loading}
                                    >
                                        {loading ? "Updating..." : t("Update")}
                                    </button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ChangePassword;
