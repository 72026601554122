import axios from "axios";

const apiClient = axios.create({
  baseURL: "https://omshivyatra.digiport.online/api/", 
  timeout: 10000, // Request timeout (10 seconds)
  headers: {
    "Content-Type": "application/json",
  },
});

apiClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token"); 
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    console.error("Request Error:", error);
    return Promise.reject(error); 
  }
);

apiClient.interceptors.response.use(
  (response) => response, 
  (error) => {
    if (error.response) {
      console.error("API Error:", error.response?.data || error.message);
      if (error.response.status === 401) {
        console.error("Unauthorized access - redirecting to login...");
      }
    } else if (error.request) {
      console.error("No response received from server:", error.request);
    } else {
      console.error("Request Setup Error:", error.message);
    }

    return Promise.reject(error.response?.data || error.message || "Unknown Error");
  }
);

// Common API function
export const apiRequest = async (endpoint, method = "GET", data = null, headers = {}) => {
  try {
    const response = await apiClient({
      url: endpoint,
      method,
      data,
      headers, 
    });
    
    return response.data; 
  } catch (error) {
   
    throw error;
  }
};

export default apiClient;
