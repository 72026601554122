
import React, { useEffect, useState } from 'react';
import "../../assets/style.css";
import { LuUsers } from "react-icons/lu";
// import EmptyImge from "../../assets/images/Empty-Booking-68410c94.svg"

// import { GrView } from "react-icons/gr";
import { useTranslation } from "react-i18next";
import { FaMoneyBillAlt } from "react-icons/fa";
import { CiCalendarDate } from "react-icons/ci";
function Booking() {
    const { t, i18n } = useTranslation();

    return (
        <>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='booking'>
                            {/* <div className='booking'>
            <img src={EmptyImge} style={{width:'20%'}} alt="" />
            <h6 className='mb-0 ms-2'>Seems you don’t have any bookings yet</h6>

        </div> */}



                            <div className="ticket">
                                <div className="ticket-content">
                                    <h2 class="mb-2">Test Activity Product</h2>
                                    <small className='d-block' style={{ color: "#8e8e8e" }}>new variant</small>
                                    <span class="badge booking-light-warning">Confirmation Padding</span>
                                </div>
                                <div className='main-booking-box'>
                                    <div className='booking-points'>
                                        <div>
                                            <svg version="1.0" id="Layer_1"
                                                width="16px" height="16px" viewBox="0 0 64 64" enable-background="new 0 0 64 64" >
                                                <g>
                                                    <path fill="none" stroke="#000000" stroke-width="2" stroke-miterlimit="10" d="M57,18c-1.504,1.504-2.705,2-5,2
                                            c-4.59,0-8-3.41-8-8c0-2.295,0.496-3.496,2-5l-6-6L1,40l6,6c1.504-1.504,2.705-2,5-2c4.59,0,8,3.41,8,8c0,2.295-0.496,3.496-2,5
                                            l6,6l39-39L57,18z"/>
                                                    <line fill="none" stroke="#000000" stroke-width="2" stroke-miterlimit="10" x1="26" y1="15" x2="30" y2="19" />
                                                    <line fill="none" stroke="#000000" stroke-width="2" stroke-miterlimit="10" x1="45" y1="34" x2="49" y2="38" />
                                                </g>
                                                <line fill="none" stroke="#000000" stroke-width="2" stroke-miterlimit="10" x1="32" y1="21" x2="36" y2="25" />
                                                <line fill="none" stroke="#000000" stroke-width="2" stroke-miterlimit="10" x1="39" y1="28" x2="43" y2="32" />
                                            </svg>
                                        </div>
                                        <div className='ms-2'>
                                            <small>
                                                {t("PackageCode")}
                                            </small>
                                            <h6>BP9B27BLR</h6>
                                        </div>
                                    </div>
                                    <div className='booking-points'>
                                        <div>
                                            <svg version="1.0" id="Layer_1"
                                                width="16px" height="16px" viewBox="0 0 64 64" enable-background="new 0 0 64 64" >
                                                <g>
                                                    <path fill="none" stroke="#000000" stroke-width="2" stroke-miterlimit="10" d="M57,18c-1.504,1.504-2.705,2-5,2
                                            c-4.59,0-8-3.41-8-8c0-2.295,0.496-3.496,2-5l-6-6L1,40l6,6c1.504-1.504,2.705-2,5-2c4.59,0,8,3.41,8,8c0,2.295-0.496,3.496-2,5
                                            l6,6l39-39L57,18z"/>
                                                    <line fill="none" stroke="#000000" stroke-width="2" stroke-miterlimit="10" x1="26" y1="15" x2="30" y2="19" />
                                                    <line fill="none" stroke="#000000" stroke-width="2" stroke-miterlimit="10" x1="45" y1="34" x2="49" y2="38" />
                                                </g>
                                                <line fill="none" stroke="#000000" stroke-width="2" stroke-miterlimit="10" x1="32" y1="21" x2="36" y2="25" />
                                                <line fill="none" stroke="#000000" stroke-width="2" stroke-miterlimit="10" x1="39" y1="28" x2="43" y2="32" />
                                            </svg>
                                        </div>
                                        <div className='ms-2'>
                                            <small>PNR</small>
                                            <h6>BP9B27BLR</h6>
                                        </div>
                                    </div>
                                    {/* third */}
                                    <div className='booking-points'>
                                        <div>
                                            <LuUsers />
                                        </div>
                                        <div className='ms-2'>
                                            <small>{t("Traveler")}</small>
                                            <h6>1</h6>
                                        </div>
                                    </div>
                                    {/* fouth */}
                                    <div className='booking-points'>
                                        <div>
                                            <FaMoneyBillAlt />
                                        </div>
                                        <div className='ms-2'>
                                            <small>{t("TotalAmount")}</small>
                                            <h6>{t("inr")} 1.27</h6>
                                        </div>
                                    </div>
                                    <div className='booking-points'>
                                        <div>
                                            <CiCalendarDate />
                                        </div>
                                        <div className='ms-2'>
                                            <small>{t("DateofTraveler")}</small>
                                            <h6>12-12-2024</h6>
                                        </div>
                                    </div>

                                </div>
                                <div class="booking_separetor"></div>
                            </div>





                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Booking;
