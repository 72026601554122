import React, { useEffect, useState } from 'react';
import "../../assets/style.css";
import WalletImage from  "../../assets/images/Thrill-Cash-e0872421.svg";
import Wallet1Image from  "../../assets/images/thrill-cash-plus-0b663ff9.svg";
import Wallet2Image from  "../../assets/images/Empty-Wallet-6cf47853.svg";
import { useTranslation } from "react-i18next";






function Wallet() {
    const { t, i18n } = useTranslation()
     
    return (
      <>
        <div className='wallet_box'>
           
           <div className='row'>
                <div className='col-lg-6'>
                    <div className='wallet-main'>
                        <div className='wallet-media'>
                             <img src={Wallet1Image} alt="" />
                        </div>
                        <div className='wallet-content'>
                            <h6 className='text-orange'>OmShankar PLUS</h6>
                            <h2 className='mb-0'>{t("inr")} 0</h2>
                        </div>
                    </div>
                </div>
                <div className='col-lg-6'>
                    <div className='wallet-main'>
                        <div className='wallet-media'>
                             <img src={WalletImage} alt="" />
                        </div>
                        <div className='wallet-content'>
                            <h6 className='text-orange'>OmShankar Cash</h6>
                            <h2 className='mb-0'>{t("inr")} 0</h2>
                        </div>
                    </div>
                </div>
                <div className='col-lg-12'>
                    <h4 style={{fontSize:'17px',marginBottom:"20px"}}>{t("WalletHistory")}</h4>
                    <hr />
                    <div className='d-flex align-items-center'>
                       <span className='badge badge-primary me-2 '>OmShankar Plus</span>
                       <span className='badge badge-primary text-dark bg-white disabled '>OmShankar Cash</span>
                    </div>
                    <div className='booking'>
                        <img src={Wallet2Image} alt="" />
                        <h6 className='mb-0 ms-2'>{t("transactionswallet")}</h6>
                    </div>
                </div>
           </div>

        </div>
      </>
    );
}

export default Wallet;
