import React, { useEffect, useState } from 'react';
import "../../assets/style.css";





function Quotations() {
 
     
    return (
      <>
        <div className='quotations'>
           
            <span className='mb-0'>No Quotations Found</span>

        </div>
      </>
    );
}

export default Quotations;
