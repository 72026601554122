import React from 'react';
import "../assets/style.css"
import 'bootstrap/dist/css/bootstrap.min.css';
const TermsofService = () => {
    return (
        <>
            <h1>Terms & Policy –  </h1>
            <div>
                
            </div>
        </>
    )
}
export default TermsofService;