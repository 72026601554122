import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend'; // JSON से डेटा लोड करने के लिए
import hi from './lang/hi/translation';
import en from './lang/en/translation';

i18n
  .use(Backend) // JSON फाइल से लोड करने के लिए
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    lng: localStorage.getItem("i18nextLng") ?? "en",  
    fallbackLng: "en",
    interpolation: { escapeValue: false },
    backend: {
      loadPath: "/locales/{{lng}}/translation.json" // JSON फाइल का path
    },
    resources: {
        en: en,
        hi: hi
    }
  });

export default i18n;
