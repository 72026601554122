import React from 'react';
import "../assets/style.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import Andman from '../../src/assets/andman.png'
import Andman1 from '../../src/assets/andman1.jpg'

const AndamanNicobar = () => {
    return (
        <>
            <div>

                <img className='w-100' src={Andman} alt="Andman.png" />
            </div>
            <section className='section-padding'>
                <div className='container'>
                    <div className>
                        <div className='row'>
                            <div className='col-lg-12 tabs'>
                                <h1>Andaman-Nicobar Yatra</h1>
                                <span>
                                </span> <br />

                                <p classname="about_para">Where ocean sings and waves bow low,
                                    A sacred breeze begins to flow.
                                    The sunlit sands, the endless blue,
                                    A journey pure, serene, and true.
                                    Temples stand by waters bright,
                                    Blessings shine in golden light.
                                    Nature’s hymn and prayers blend,
                                    A yatra where the soul transcends.
                                    Come, sail with faith, so vast, so free,
                                    With Om Shiv Shankar Tirth Yatra guiding thee. 🚩

                                </p>
                                <br />
                                <h1>Andaman-Nicobar Yatra: Where Devotion Meets Nature’s Bliss
                                </h1> <br />
                                <p>Set sail on a journey of serenity and spirituality with Om Shiv Shankar Tirth Yatra, where
                                    the pristine blue waters of Andaman & Nicobar
                                    welcome you to a land of peace and devotion.   </p>

                                <h3>Why Join This Yatra?  </h3>

                                <p>🌊 A Divine Escape – Experience the tranquil beauty of the islands, where nature itself feels like a temple.
                                </p>
                                <p> 🙏 Sacred Sites & Spiritual Peace – Visit ancient temples and feel the divine energy in the heart of the ocean.

                                </p>
                                <p> 🏝️ A Perfect Blend of Faith & Relaxation – Immerse in devotion while enjoying the breathtaking beauty of white
                                    sand beaches and crystal-clear waters.
                                </p>
                                <p>With Om Shiv Shankar Tirth Yatra, embark on a soul-soothing journey where faith, nature, and peace come together. Book your Andaman-Nicobar Yatra today! 🚩
                                </p>
                            </div>

                        </div>
                        <div className='mt-4'>
                            <img src={Andman1} alt="Andman2.png" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default AndamanNicobar;