import React, { useEffect, useState } from 'react';
import "../assets/style.css";
import Form from 'react-bootstrap/Form';
import { FaEye } from "react-icons/fa6";
import { FaEyeSlash } from "react-icons/fa";
import Swal from "sweetalert2";
import { apiRequest } from '../ApiService/Allapi';
import { useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
function ResetPassword() {
    const { t, i18n } = useTranslation();
    const { token } = useParams()
    const [Loading, setLoading] = useState(false)

    const [Data, setdata] = useState({
        pwd: '',
        cnf_pwd: ""

    })

    const handlechange = (e) => {
        setdata({ ...Data, [e.target.name]: e.target.value });
    }

    const handlesumbit = async (e) => {
        e.preventDefault();

        const body = {
            pwd: Data.pwd,
            cnf_pwd: Data.cnf_pwd
        };

        try {
            const result = await apiRequest(`reset-password/${token}`, "POST", body);
            if (result.status === true) {


                Swal.fire({
                    title: result.message,
                    icon: "success"
                }).then(() => {
                    window.location.href = '/';
                });

            } else {
                Swal.fire({
                    title: result.message,
                    icon: "error"
                });
            }
        } catch (error) {
            console.log()
            Swal.fire({
                title: error.message,
                icon: "error"
            });
            console.error(error);
        } finally {
            setLoading(false); // Re-enable the button after response
        }
    };





    const user = {
        imageUrl: 'https://ui-assets-gc.thrillophilia.com/packs/media/tours-app/reset-pwd-ae60fc65.svg',
        imageSize: 200,
    };
    return (
        <>
            <div className='reset_password_box'>
                <div className='row justify-content-center'>
                    <div className='reset_wrapper'>
                        <div className='col-xl-4 col-lg-6 col-md-8 col-11'>
                            <div className='reset_password'>
                                <img
                                    className="avatar"
                                    src={user.imageUrl}
                                    alt={'Photo of ' + user.name}
                                    style={{
                                        width: user.imageSize,
                                        height: user.imageSize
                                    }}
                                />
                                <h2 className='reset-pass__heading '>{t("ANew")}</h2>
                                <Form onSubmit={handlesumbit}>
                                    <Form.Group className="mb-3 position-relative" controlId="exampleForm.ControlInput1">
                                        <Form.Label>{t("NewPassword")}</Form.Label>
                                        <Form.Control type="password" placeholder={t("NewPassword")} name='pwd' value={Data.pwd} onChange={handlechange} />
                                        <span className='eye'>
                                            <FaEye />
                                        </span>
                                    </Form.Group>
                                    <Form.Group className="mb-3 position-relative" controlId="exampleForm.ControlInput2">
                                        <Form.Label>{t("ConfirmPasswordx")}</Form.Label>
                                        <Form.Control type="password" placeholder={t("ConfirmPasswordx")} name='cnf_pwd' value={Data.cnf_pwd} onChange={handlechange} />
                                        <span className='eye'>
                                            <FaEyeSlash />
                                        </span>
                                    </Form.Group>
                                    <button className='btn btn-outline-white'>Submit</button>
                                </Form>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </>

    )
}

export default ResetPassword;
