import React from 'react';
import "../assets/style.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../src/assets/chardham.png';
import purinepal from '../../src/assets/purinepal.jpg'
import purinepal1 from '../../src/assets/purinepal1.png'

const PuriNepal = () => {
    return (
        <>
            <div>

                <img className='w-100' src={purinepal1} alt="purinepal.jng" />
            </div>
            <section className='section-padding'>
                <div className='container'>
                    <div className>
                        <div className='row'>
                            <div className='col-lg-12 tabs'>
                                <h1>Puri-Nepal-Gangasagar Yatra  </h1>
                                <span>
                                </span> <br />

                                <p classname="about_para">Where ocean waves and rivers meet,
                                    And holy lands bless pilgrim feet.
                                    From Jagannath’s gaze in Puri’s land,
                                    To Nepal’s peaks, so tall, so grand.
                                    The Ganga flows with sacred grace,
                                    At Gangasagar’s holy place.
                                    A journey pure, of faith and light,
                                    Where devotion shines ever bright.
                                    Come, seek the path, divine and true,
                                    With Om Shiv Shankar Tirth Yatra guiding you. 🚩

                                </p>
                                <br />
                                <h1>Puri-Nepal-Gangasagar Yatra: A Journey of Divine Blessings
                                </h1> <br />
                                <p>Embark on a sacred pilgrimage with Om Shiv Shankar Tirth Yatra, where spirituality and devotion come alive.
                                     From the holy land of Puri, home to Lord Jagannath, to the sacred temples of Nepal and the divine waters of Gangasagar,
                                      this yatra offers a life-changing experience.

                                </p>

                                
                                <h3> **Why Join This Yatra?**      </h3>

                                <p>🛕 Seek Lord Jagannath’s Blessings in Puri – Experience the divine aura of one of the holiest temples in India.
                                </p>
                                <p>  🏔️ Visit Nepal’s Sacred Temples – Bow before Lord Pashupatinath and feel the spiritual energy of the Himalayas.

                                </p>
                                <p>🌊 Purify Your Soul at Gangasagar – Take a holy dip where the Ganga meets the ocean, a path to ultimate salvation.

                                </p>

                                <p> At Om Shiv Shankar Tirth Yatra, we ensure a comfortable, well-organized, and spiritually fulfilling journey. Join us and make this yatra a divine experience! 🚩
                                </p>

                            </div>


                        </div>
                        <div className='mt-4'>
                            <img  className="w-100" src={purinepal} alt="purinepal1.png" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
export default PuriNepal;