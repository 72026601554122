import React from "react";
import "../../css/header.css"; // Import CSS file for styling
import "../../assets/style.css"
import logoImage from "../../assets/images/logo.jpg";
import flagImage from "../../assets/images/flag.png";
import Form from 'react-bootstrap/Form';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import InputGroup from 'react-bootstrap/InputGroup';
import Swal from "sweetalert2";
import { useEffect, useState, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { apiRequest } from "../../ApiService/Allapi";
import { LuLogOut } from "react-icons/lu";
import { NavLink, useNavigate } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import { TbBrandBooking } from "react-icons/tb";
import { MdHelpOutline } from "react-icons/md";
import { RiArrowDropDownLine } from "react-icons/ri";
import Select from 'react-select';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MdLanguage } from "react-icons/md";
import { Slider } from "@mui/material";
import _ from "lodash";
import { FaDownload } from "react-icons/fa6";
// home

function Header() {
  const langs = localStorage.getItem("i18nextLng") ?? "en";
  const [lang, setLang] = useState(langs);
  const [showForgetPassword, setShowForgetPassword] = useState(false);
  const placeholderText = "Search for something..." // Placeholder text
  const typingSpeed = 100; // Speed of typing in milliseconds
  const delayBeforeRestart = 2000; // Delay before restarting animation in milliseconds
  const inputRef = useRef(null);

  useEffect(() => {
    let index = 0;
    let isDeleting = false;

    const typePlaceholder = () => {
      if (inputRef.current) {
        if (!isDeleting) {
          // Typing the text
          inputRef.current.placeholder = placeholderText.substring(0, index + 1);
          index++;
          if (index === placeholderText.length) {
            isDeleting = true; // Start deleting after typing completes
          }
        } else {
          // Deleting the text
          inputRef.current.placeholder = placeholderText.substring(0, index - 1);
          index--;
          if (index === 0) {
            isDeleting = false; // Start typing again
          }
        }
      }
    };

    const typingInterval = setInterval(typePlaceholder, typingSpeed);

    return () => clearInterval(typingInterval); // Cleanup on unmount
  }, [typingSpeed, placeholderText]);
  const [selectedOption, setSelectedOption] = useState(null);
  const options = [
    { value: '+91', label: '+91' },
    { value: '+92', label: '+92' },
    { value: '+93', label: '+93' },
    { value: 'contact', label: 'Contact' }
  ];

  const [loading, setLoading] = useState(false);
  const [showModalOne, setShowModalOne] = useState(false);
  const [showModalTwo, setShowModalTwo] = useState(false);
  const [activeTab, setActiveTab] = useState('home');
  const [isLoading, setIsLoading] = useState(false);
  const [Fpassword, setFpassword] = useState(false)
  const naviget = useNavigate()
  const token = localStorage.getItem("token")
  const navigate = useNavigate()
  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setFpassword(false)
  };
  const [show1, setShow1] = useState(false);



  const [firstLetter, setFirstLetter] = useState('');

  useEffect(() => {
    const name = localStorage.getItem('name'); // Get name from localStorage
    if (name) {
      setFirstLetter(name.charAt(0)); // Extract the first letter
    }
  }, []);




  const [SinData, setSiData] = useState({
    name: "",
    email: "",
    password: "",
    Phone: "",
    ConfirmPassword: ""
  });

  const handlechange = (e) => {
    setSiData({ ...SinData, [e.target.name]: e.target.value });
  };


  const handleSelectChange = (option) => {
    setSelectedOption(option);
    console.log('Selected option:', option);
  };

  const handlesubmit = async (e) => {
    e.preventDefault();

    const body = {
      name: SinData.name,
      email: SinData.email,
      password: SinData.password,
      phone: SinData.Phone,
      c_password: SinData.ConfirmPassword,
    };

    try {
      const result = await apiRequest(`register?lang=${lang}`, "POST", body);

      if (result.status === true) {
        localStorage.setItem("token", result?.token);

        Swal.fire({
          title: result.message,
          icon: "success",
        });


        setSiData({
          name: "",
          email: "",
          password: "",
          Phone: "",
          ConfirmPassword: "",
        });


      } else {
        Swal.fire({
          title: result.message,
          icon: "error",
        });
      }
    } catch (error) {
      Swal.fire({
        title: error.message || "Something went wrong!",
        icon: "error",
      });
      console.error(error);
    } finally {
      setIsLoading(false); // Re-enable the button after response
    }
  };





  //   login 

  const [data, setData] = useState({
    email: "",
    password: "",
  });




  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    const body = {
      email: data.email,
      password: data.password,
    };

    try {
      const result = await apiRequest(`login?lang=${lang}`, "POST", body);

      if (result.status === true) {


        localStorage.setItem("token", result?.token);
        localStorage.setItem("name", result?.data?.name);
        localStorage.setItem("email", result?.data?.email);

        Swal.fire({
          title: result.message || "successfully Login",
          icon: "success"
        });

        naviget("/");
        window.location.reload()
      } else {
        Swal.fire({
          title: result.message,
          icon: "error"
        });
      }
    } catch (error) {
      console.log()
      Swal.fire({
        title: error.message,
        icon: "error"
      });
      console.error(error);
    } finally {
      setIsLoading(false); // Re-enable the button after response
    }

  }
  const username = localStorage.getItem("name")


  const logout = async () => {

    setLoading(true);


    try {

      const token = localStorage.getItem("token");


      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const res = await apiRequest(`log-out?lang=${lang}`, "GET", null, headers);
      if (res.status === true) {
        localStorage.clear("token", "name")
        Swal.fire({
          title: res.message || "successfully Logout",
          icon: "success",
        });

        navigate("/");

      } else {
        Swal.fire({
          title: res.message,
          icon: "error",
        });
      }
    }
    finally {
      setLoading(false);
    }

  }


  const [Valus, setValus] = useState({
    Email: '',
  });

  const handleCha = (e) => {
    setValus({ ...Valus, [e.target.name]: e.target.value });
  };

  const handlesumbiit = async (e) => {
    e.preventDefault();
    console.log("Form submission initiated");

    const body = {
      email: Valus.Email,
    };

    try {
      const result = await apiRequest(`forgot-password?lang=${lang}`, "POST", body);

      if (result.status === true) {
        Swal.fire({
          title: result.message,
          icon: "success"
        });
        naviget("/");

      } else {
        Swal.fire({
          title: result.message,
          icon: "error"
        });
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "error"
      });
      console.error(error);
    } finally {
      setIsLoading(false); // Reset loading state if necessary
    }
  };

  // Your form JSX

  const handleClose1 = () => setShow1(false);
  const showModal = () => setShow1(true);



  const { t, i18n } = useTranslation();

  // भाषा बदलने का फ़ंक्शन
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("i18nextLng", lng);
  };



  const [price, setPrice] = useState([140000, 280000]);

  const handleChang = (event, newValue) => {
    setPrice(newValue);
  }

  const [formData, setFormData] = useState({ textField: "" });
  const [citys, setCitys] = useState([]); // API Data

  const [showSuggestions, setShowSuggestions] = useState(false);
  const [debouncedValue, setDebouncedValue] = useState(""); // Debounced Input Value

  const handleChan = (e) => {
    setFormData({ textField: e.target.value });
    setShowSuggestions(true);
  };

  const handleFocus = () => {
    if (citys.length > 0) setShowSuggestions(true);
  };

  const handleBlur = (e) => {
    setTimeout(() => setShowSuggestions(false), 200);
  };

  const handleSelect = (name) => {
    setFormData({ textField: name });
    setShowSuggestions(false);
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await apiRequest(`get-location?lang=${lang}`, "GET", null);

      if (res?.status) {

        setCitys(res.data || []);

      } else {
        console.error("Error:", res?.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  // ✅ Call API on Input Change (Debounced)
  useEffect(() => {
    const delay = setTimeout(() => {
      setDebouncedValue(formData.textField);
    }, 500)

    return () => clearTimeout(delay);
  }, [formData.textField]);

  useEffect(() => {
    if (debouncedValue) fetchData();
  }, [debouncedValue]);


  return (
    <>
      {/* header_top */}
      {/* <div className="header-top">
        <div className="animation_slider">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="38" viewBox="0 0 24 38" fill="none"><path d="M24 0H14.5L5 38H14.5L24 0Z" fill="white" fill-opacity="0.5"></path><path d="M12 0H9.95238L0 38H2.04762L12 0Z" fill="white" fill-opacity="0.5"></path></svg>
        </div>
        <div className="container">
          <h1> <span>Christmas Sale</span>Save Upto<span>70% off</span>On your  trip</h1>
        </div>
      </div> */}
      {/* header_top_end */}
      {/* main-header-strat */}
      <div className="container  style-2">
        <header className="header">
          <div className="header_compont">
            <div className="logo">
              <NavLink to='/'>   <img src={logoImage} alt="Example" style={{ width: "98px" }} /></NavLink>
            </div>
            <InputGroup className="header_search">
              <InputGroup.Text id="basic-addon1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  fill="none"
                  viewBox="0 0 16 16"
                  role="img"
                >
                  <path
                    stroke="#515151"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.4"
                    d="M7.333 12.667A5.333 5.333 0 107.333 2a5.333 5.333 0 000 10.667zM14 14l-2.9-2.9"
                  ></path>
                </svg>
              </InputGroup.Text>
              <Form.Control
                ref={inputRef}
                aria-label="Username"
                aria-describedby="basic-addon1"
                onClick={showModal}
              />
            </InputGroup>

            <div className="d-flex align-items-center">
              {/* <button className="btn btn-primary" style={{ width: "100px" }}>
                {t("booklet")} <FaDownload />
              </button> */}

              <Dropdown className="me-3 dropdown-hover">
                <Dropdown.Toggle className="coustom-dropdown" variant="" id="dropdown-pages">
                  <span>About us</span>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item as="div">
                    <Link to="/about" onClick={() => window.scrollTo(0, 0)} className="dropdown-link">ℹ️ About</Link>
                  </Dropdown.Item>
                  <Dropdown.Item as="div">
                    <Link to="/Blog" onClick={() => window.scrollTo(0, 0)} className="dropdown-link">🏠 Blog</Link>
                  </Dropdown.Item>
                  <Dropdown.Item as="div">
                    <Link to="/contect" onClick={() => window.scrollTo(0, 0)} className="dropdown-link">📞 Contact</Link>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>


              <Dropdown>
                <Dropdown.Toggle className="coustom-dropdown" variant="" id="dropdown-basic">
                  <span>{t("hello")}</span>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item as="button" onClick={() => { changeLanguage("hi"); window.location.reload(); }}>
                    <MdLanguage />
                    <span className="header_text"> हिंदी </span>
                  </Dropdown.Item>

                  <Dropdown.Item as="button" onClick={() => {
                    changeLanguage("en");
                    window.location.reload();
                  }}>
                    <MdLanguage />
                    <span className="header_text"> English </span>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>



              {token
                ?
                // <NavLink to="/my-profile"> <Button variant="" className="haeder_btn">
                //     My-Profile
                //   </Button>
                //   </NavLink> 
                <Dropdown>
                  <Dropdown.Toggle className="coustom-dropdown" variant="" id="dropdown-basic">
                    <div className="image"> <span className="image_text">{firstLetter}</span>
                    </div>    <span>{username}</span>         </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <NavLink to="/my-profile"><Dropdown.Item href="#action">
                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-users"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg><span className="header_text">{t("MyProfile")}</span></Dropdown.Item></NavLink>
                    <NavLink to="/booking">  <Dropdown.Item href="#/action-2"><TbBrandBooking className="" /><span className="header_text">{t("MyBookings")}</span></Dropdown.Item></NavLink>
                    {/* <NavLink to="/faq">   <Dropdown.Item href="#/action-3"><MdHelpOutline className="" /><span className="header_text">Support & FAQs</span></Dropdown.Item></NavLink> */}
                    <Dropdown.Item href="#/action-3"><LuLogOut /><span className="header_text" onClick={logout}>{t("Logout")}</span></Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

                :
                <Button variant="" onClick={() => setShowModalTwo(true)} className="haeder_btn">
                  {t("LOgins")}
                </Button>
              }
            </div>

          </div>


        </header>
        <div class="seprator"></div>
        {/* main-header-end */}





      </div>



      {/* Second Modal */}
      <Modal className="login-model" show={showModalTwo} onHide={() => setShowModalTwo(false)}>
        <Modal.Header className="p-0 border-0" closeButton>

        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="row coustom-row">
            <div className="col-lg-7  px-0">
              <div className="login_model">
                <div className="login_text">
                  <h2>Your Adventure Starts Here</h2>
                  <p>Experience 20,000+ Tours And Activities from 1,200+ Suppliers</p>
                </div>
              </div>
            </div>
            <div className="col-lg-5  px-0">
              <div className="custom_tabs">


                <ul className="nav nav-tabs">
                  <li className="nav-item">
                    <button
                      className={`nav-link ${activeTab === 'home' ? 'active' : ''}`}
                      onClick={() => handleTabChange('home')}
                    >
                      {t("LOgins")}
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      className={`nav-link ${activeTab === 'profile' ? 'active' : ''}`}
                      onClick={() => handleTabChange('profile')}
                    >
                      {t("Sign")}
                    </button>
                  </li>

                </ul>
              </div>
              <div className="tab-content  me-2 mt-3 custom_tabs_content">
                {activeTab === 'home' && <div className="tab-pane px-4 active">


                  {
                    Fpassword === false ? (
                      <>
                        <Form onSubmit={handleSubmit}>
                          <h1>{t("YourAccount")}</h1>
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Control
                              type="email"
                              placeholder={t("Emails")}
                              name="email"
                              value={data.email}
                              onChange={handleChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                            <Form.Control
                              type="password"
                              placeholder={t("passwords")}
                              name="password"
                              value={data.password}
                              onChange={handleChange}
                            />
                            <Link
                              href="/forget-password"
                              className="text-orange float-end"
                              style={{
                                fontSize: "12px",
                                fontWeight: "500",
                                marginTop: "8px",
                              }}
                              onClick={() => setFpassword(true)}
                            >
                              {t("ForgetPassword")}
                            </Link>
                          </Form.Group>
                          <button className="btn-login btn-primary mt-3">{t("LoginContinue")}</button>
                        </Form>
                      </>
                    ) : (
                      <Form onSubmit={handlesumbiit}>
                        <div className=""  >
                          <h1>{t("ForgottenPassword")}</h1>
                          <small className="text-small" style={{ fontSize: '12px' }}>{t("resetyour")}</small>

                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1" >
                            <Form.Control
                              type="email"
                              placeholder={t("Emails")}
                              name="Email"
                              value={Valus.Email}
                              onChange={handleCha}
                            />
                            <button className="btn btn-primary mt-3" type="submit">{t("Continue")}</button>

                          </Form.Group>

                          <small class="fs-12 text-small">{t("Returnto")}<b><a href="" class="text-small font-500 ms-1">{t("LOgins")}</a></b></small>
                        </div>
                      </Form>


                    )
                  }



                </div>}
                {activeTab === 'profile' && <div className="tab-pane px-sm-5 px-3 custom_tabs_content active">
                  <h1>{t("CreateYourAccount")} </h1>

                  <Form onSubmit={handlesubmit}>
                    <Form.Group className="mb-2" controlId="exampleForm.ControlInput3">

                      <Form.Control type="text" placeholder={t("Name")} name="name" value={SinData.name} onChange={handlechange} />
                    </Form.Group>
                    <Form.Group className="mb-2" controlId="exampleForm.ControlInput4">

                      <Form.Control type="email" placeholder={t("Email")} name="email" value={SinData.email} onChange={handlechange} />
                    </Form.Group>
                    <div className="row">
                      <div className="col-lg-3 col-3 pe-0">
                        <Select
                          options={options}
                          value={selectedOption}
                          onChange={handleSelectChange}
                          placeholder="+91"
                        />
                      </div>
                      <div className="col-lg-9 col-9">
                        <Form.Group className="mb-2" controlId="exampleForm.ControlInput4">

                          <Form.Control type="number" placeholder={t("PhoneNumber")} name="Phone" value={SinData.Phone} onChange={handlechange} />

                        </Form.Group>
                      </div>
                    </div>
                    <Form.Group className="mb-2" controlId="exampleForm.ControlInput4">

                      <Form.Control type="password" placeholder={t("passwords")} name="password" value={SinData.password} onChange={handlechange} />

                    </Form.Group>
                    <Form.Group className="mb-2" controlId="exampleForm.ControlInput4">

                      <Form.Control type="password" placeholder={t("ConfirmPassword")} name="ConfirmPassword" value={SinData.ConfirmPassword} onChange={handlechange} />

                    </Form.Group>
                    <small className="fs-12 text-small">{t("PrivacyPolicy")}</small>

                    <button className="btn-login btn-primary mt-3">{t("Sign")}</button>
                    <small className="fs-12 text-small">{t("Already")}<b><a href="" className="text-small font-500 ms-2">{t("LOgins")}</a></b></small>

                  </Form>
                </div>}

              </div>
            </div>
          </div>
        </Modal.Body>

      </Modal>

      <Modal className='normal_model' show={show1} onHide={handleClose1}>
        <Modal.Header className='border-0 d-block' closeButton>
          <Modal.Title>
            <InputGroup className="header_search model_search">
              <InputGroup.Text id="basic-addon1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  fill="none"
                  viewBox="0 0 16 16"
                  role="img"
                >
                  <path
                    stroke="#515151"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.4"
                    d="M7.333 12.667A5.333 5.333 0 107.333 2a5.333 5.333 0 000 10.667zM14 14l-2.9-2.9"
                  ></path>
                </svg>
              </InputGroup.Text>
              {/* ✅ Suggestions List */}


              <div className="search-container">
                <input
                  type="text"
                  name="textField"
                  value={formData.textField}
                  onChange={handleChan}
                  onFocus={handleFocus} // 🔹 Focus पर suggestions दिखेंगे
                  onBlur={handleBlur} // 🔹 Blur होने पर थोड़ा delay ताकि क्लिक हो सके
                  placeholder="Type a user name"
                  className="form-control search-input"
                  autoComplete="off"
                />



                {loading && <p className="loading-text">Loading...</p>}
              </div>

            </InputGroup>
            {showSuggestions && (
              <ul className="suggestions-list">
                {citys.length > 0 ? (
                  citys.map((item, index) => (
                    <li
                      key={index}
                      className="suggestion-item"
                      onMouseDown={() => handleSelect(item.name)} // 🔹 Select करने पर input में दिखाएं
                    >
                      {item.name}
                    </li>
                  ))
                ) : (
                  <li className="suggestion-item">{t("Nodatafound")}</li>
                )}
              </ul>
            )}
          </Modal.Title>
        </Modal.Header>



        <Modal.Body className='pt-0'>
          {/* <div className="Product_Type">
                      <h6>{t("ProductType")}</h6>
                      <div className="product_btn">
                          <span>{t("Tour")}</span>
                          <span>{t("Activities")}</span>
                      </div>
                   </div> */}
          <div class="SearchContentSection_horizantalSeperator__taBm5"></div>
          <div className="Product_Type">
            <h6>{t("ProductType")}</h6>
            <div className="product_btn">

              {/* <Form>
                {['checkbox'].map((type) => (
                  <div key={`default-${type}`} className="mb-3">
                    <Form.Check // prettier-ignore
                      type={type}
                      id={`default-${type}`}
                      label={`UptoDay ${type}`}
                    />
                    <Form.Check // prettier-ignore
                      type={type}
                      id={`default-${type}`}
                      label={`UptoDays ${type}`}
                    />
                    <Form.Check // prettier-ignore
                      type={type}
                      id={`default-${type}`}
                      label={`UptoDayss ${type}`}
                    />
                    <Form.Check // prettier-ignore
                      type={type}
                      id={`default-${type}`}
                      label={`UptoDaysss ${type}`}
                    />
                    <Form.Check // prettier-ignore
                      type={type}
                      id={`default-${type}`}
                      label={`(UptoDayssss) ${type}`}
                    />


                  </div>
                ))}
              </Form> */}

              <span>{t("UptoDay")}</span>
              <span>{t("UptoDays")}</span>
              <span>{t("UptoDayss")}</span>
              <span>{t("UptoDaysss")}</span>
              <span>{t("UptoDayssss")}</span>
            </div>
          </div>
          <div class="SearchContentSection_horizantalSeperator__taBm5"></div>
          <div className="Product_Type">
            <h6>{t("PriceRange")}</h6>
            <div className="p-4">
              <div className="flex flex-col items-center my-3">
                <Slider
                  value={price}
                  onChange={handleChang}
                  min={0}
                  max={300000}
                  step={5000}
                  valueLabelDisplay="auto"
                />
                <div className="flex justify-between w-full">
                  <span>{(price[0] / 1000).toFixed(1)}L</span>
                  <span>{(price[1] / 1000).toFixed(1)}L</span>
                </div>
              </div>

              {/* Min & Max Inputs */}
              <div className="flex justify-between gap-4">
                {/* Min Price Input */}
                <div className="flex flex-col w-full">
                  <label className="text-sm font-medium mb-1">Min</label> &nbsp;
                  <input
                    type="number"
                    value={price[0]}
                    onChange={(e) => setPrice([+e.target.value, price[1]])}
                    className="border p-2 w-full rounded"
                    style={{ width: "40%" }}
                  />
                  &nbsp; &nbsp;
                  <label className="text-sm font-medium mb-1">Max</label>
                  &nbsp;
                  <input
                    type="number"
                    value={price[1]}
                    onChange={(e) => setPrice([price[0], +e.target.value])}
                    className="border p-2 w-full rounded"
                    style={{ width: "37%" }}
                  />
                </div>


              </div>


              {/* Checkbox */}

            </div>

          </div>

        </Modal.Body>
        <Modal.Footer className="d-flex align-items-center justify-content-between">
          <button className="btn btn-primary w-auto">{t("ClearAll")}</button>
          <button className="btn btn-primary w-auto">{t("SearchProducts")}</button>

        </Modal.Footer>
      </Modal>

    </>
  );
}

export default Header;