import React, { useEffect, useState } from 'react';
import "../assets/style.css";
import dubaiImage from "../assets/images/dubai-1.jpg";
import { MdOutlineForwardToInbox } from "react-icons/md";
import { LuSwatchBook } from "react-icons/lu";
import { HiMiniUsers } from "react-icons/hi2";
import { CiWallet } from "react-icons/ci";
import { FaRegUser } from "react-icons/fa";
import { IoMdHelpCircleOutline } from "react-icons/io";
import Profile from '../components/profile-component/profile';
import Booking from '../components/profile-component/booking';
import Quotations from '../components/profile-component/quotations';
import Wallet from '../components/profile-component/wallet';
import Passengers from '../components/profile-component/my-passengers';
import FAQ from '../components/profile-component/faq'
import Swal from "sweetalert2";
import { apiRequest } from '../ApiService/Allapi';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

function Myprofile() {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [User, setUser] = useState([]);
  const [activeTab, setActiveTab] = useState('profile'); // Initial state for activeTab
  const naviget = useNavigate()
  const langs = localStorage.getItem("i18nextLng") ?? "en";
  const [lang, setLang] = useState(langs);
  const getTour = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");

      if (!token) {
        naviget("/")
      }
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const res = await apiRequest(`get-profile?lang=${lang}`, "GET", null, headers);
      if (res.status === true) {
        setUser(res.data);
        Swal.fire({
          title: res.message,
          icon: "success",
        });

      } else {
        Swal.fire({
          title: res.message || "Failed to fetch data",
          icon: "error",
        });
      }
    } catch (error) {

      if (error.res && error.res.status === 401) {
        Swal.fire({
          title: "Session expired. Please log in again.",
          icon: "error"
        });

      } else {
        console.error("Error:", error);
        Swal.fire({
          title: error.message,
          icon: "error"
        });
        naviget("/");
      }
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getTour();
    const savedTab = localStorage.getItem("activeTab");
    if (savedTab) {
      setActiveTab(savedTab);
    }
  }, [lang]);

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    localStorage.setItem("activeTab", tabName);
  }


  return (
    <>
      <section className='section-padding'>
        <div className='container style-1'>
          <div className='row'>
            <div className='col-lg-4 col-md-5'>
              <div className='profile_wrapper'>
                <div className='profile_media'>
                  <img src={dubaiImage} alt="" />
                </div>
                <div className='profile_content'>
                  <h6 className='full-name'>{User?.name}</h6>
                  <p className='d-block mt-2'><MdOutlineForwardToInbox className='me-1' />{User?.email}</p>
                </div>
                <div className='profile_tabs'>
                  <ul>
                    <li>
                      <a
                        href="#"
                        className={activeTab === 'profile' ? 'text-orange' : 'text-light-1'}
                        onClick={() => handleTabClick('profile')}
                      >
                        <HiMiniUsers className='me-2 fs-16' />{t("MyProfile")}
                      </a>
                    </li>
                    {/* <li>
                      <a 
                        href="#"
                        className={activeTab === 'booking' ? 'text-orange' : 'text-light-1'}
                        onClick={() => handleTabClick('booking')}
                      >
                        <LuSwatchBook className='me-2 fs-16' />{t("MyBookings")}
                      </a>
                    </li> */}
                    <li>
                      <a
                        href="#"
                        className={activeTab === 'quotation' ? 'text-orange' : 'text-light-1'}
                        onClick={() => handleTabClick('quotation')}
                      >
                        <CiWallet className='me-2 fs-16' />{t("MyQuotation")}
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        className={activeTab === 'wallet' ? 'text-orange' : 'text-light-1'}
                        onClick={() => handleTabClick('wallet')}
                      >
                        <CiWallet className='me-2 fs-16' />{t("MyWallet")}
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        className={activeTab === 'passengers' ? 'text-orange' : 'text-light-1'}
                        onClick={() => handleTabClick('passengers')}
                      >
                        <FaRegUser className='me-2 fs-16' />{t("MyPassengers")}
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        className={activeTab === 'faq' ? 'text-orange' : 'text-light-1'}
                        onClick={() => handleTabClick('faq')}
                      >
                        <IoMdHelpCircleOutline className='me-2 fs-16' />{t("SupportFAQS")}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className='col-lg-8 col-md-7'>
              {activeTab === 'profile' && <Profile />}
              {activeTab === 'booking' && <Booking />}
              {activeTab === 'quotation' && <Quotations />}
              {activeTab === 'wallet' && <Wallet />}
              {activeTab === 'passengers' && <Passengers />}
              {activeTab === 'faq' && <FAQ />}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Myprofile;
