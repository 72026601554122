import React from 'react'
import { FaStar } from "react-icons/fa";
import Clientss from '../assets/images/clent.jpg'
import { useTranslation } from "react-i18next";

function Rewes() {
    const { t, i18n } = useTranslation();
  return (
   <>
   
   <div class="Divider_divider__yaBCO  "></div>
       <section className='bg-primary-1 section-padding'>
            
       <div className='hiring-titk'>
            <h1>{t("ClientSay")}</h1>
       </div>

       <div className='container'>
            <div className='row'>
                <div className='col-lg-4'>
                <div class="single-testimonial-item">
                     <div class="description">
                        <p>Best of the best facilities in Yatra. Very very coprative &amp; friendly staff. Break fast, lunch &amp; denner very remarkable. We had two time journey with this yarta company.</p>
                     </div>
                     <div class="author-meta">
                        <img src={Clientss} alt="Pramod kumar Sharma" title="Pramod kumar Sharma"/>
                        <div class="content">
                           <p class="name">Pramod kumar Sharma</p>
                           <p class="city-name">Delhi</p>
                           <ul class="client-star">
                              <li><FaStar/></li>
                              <li><FaStar/></li>
                              <li><FaStar/></li>
                              <li><FaStar/></li>
                              <li><FaStar/></li>
                           </ul>
                        </div>
                     </div>
                  </div>
                               

                 </div>
                 <div className='col-lg-4'>
                <div class="single-testimonial-item">
                     <div class="description">
                        <p>Best of the best facilities in Yatra. Very very coprative &amp; friendly staff. Break fast, lunch &amp; denner very remarkable. We had two time journey with this yarta company.</p>
                     </div>
                     <div class="author-meta">
                        <img src={Clientss} alt="Pramod kumar Sharma" title="Pramod kumar Sharma"/>
                        <div class="content">
                           <p class="name">Pramod kumar Sharma</p>
                           <p class="city-name">Delhi</p>
                           <ul class="client-star">
                              <li><FaStar/></li>
                              <li><FaStar/></li>
                              <li><FaStar/></li>
                              <li><FaStar/></li>
                              <li><FaStar/></li>
                           </ul>
                        </div>
                     </div>
                  </div>
                               

                 </div>
                 <div className='col-lg-4'>
                <div class="single-testimonial-item">
                     <div class="description">
                        <p>Best of the best facilities in Yatra. Very very coprative &amp; friendly staff. Break fast, lunch &amp; denner very remarkable. We had two time journey with this yarta company.</p>
                     </div>
                     <div class="author-meta">
                        <img src={Clientss} alt="Pramod kumar Sharma" title="Pramod kumar Sharma"/>
                        <div class="content">
                           <p class="name">Pramod kumar Sharma</p>
                           <p class="city-name">Delhi</p>
                           <ul class="client-star">
                              <li><FaStar/></li>
                              <li><FaStar/></li>
                              <li><FaStar/></li>
                              <li><FaStar/></li>
                              <li><FaStar/></li>
                           </ul>
                        </div>
                     </div>
                  </div>
                               

                 </div>

            </div>
        </div>

       </section>

   </>

  )
}
  export default Rewes 
