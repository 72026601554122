const hi = {
    translation: {
        hello: "भाषा",
        inr:"₹",
        days:'दिनों में',
        StayCategory:"श्रेणी",
        TripHighlights:"यात्रा की मुख्य बातें",
        DestinationRoutes:"गंतव्य मार्ग",
        SendEnquiry:"जांच भेजें",
        ChooseTrip:"यात्रा अवधि चुनें",
        StartingFrom:"से शुरू",
        AllImages:"सभी छवियाँ देखें",
        Itinerary:"यात्रा कार्यक्रम",
        SummarisedView:"सारांशित दृश्य",
        Activities:"गतिविधियाँ",
        stay:"रहना",
        Transfers:"स्थानांतरण",
        Whatsinsidethepackage:"पैकेज के अंदर क्या है?",
        Inclusions:"समावेशन",
        Exclusions:"नहीं है समावेशन",
        RelatedProducts:"संबंधित उत्पाद",
        Reviews:"समीक्षा",
        TravellerGallery:"यात्री छवि गैलरी",
        Million:"सौ लाख",
        Happycustomers:"चारों ओर 65 से अधिक देशों के खुश ग्राहक|",
        Cumulativeratings:"विभिन्न प्लेटफार्मों पर हमारी यात्राओं की संचयी रेटिंग।",
        Curatedlove:"प्यार से क्यूरेट किया गया",
        Expertguided:"सावधानीपूर्वक योजना के साथ विशेषज्ञ-निर्देशित यात्राएँ।",
        Wearealways:"यात्रा से पहले, पोस्ट करने और यात्रा के दौरान आपकी मदद के लिए हम हमेशा मौजूद हैं।",
        Moreon:"अधिक",
        totaltour:"बुकिंग की तारीख से 0 दिन पहले कुल टूर लागत का 100.0% भुगतान करना होगा",
        ConfirmationPolicy:"पुष्टिकरण नीति",
        RefundPolicy:"भुगतान वापसी की नीति",
        CancellationPolicy:"रद्दीकरण नीति",
        PaymentTermPolicy:"भुगतान अवधि नीति",
        save:"पैसे बचाएं",
        PerAdult:"प्रति वयस्क",
        Destination:"गंतव्य",
        Sightseeing:"गतिविधि एवं दर्शनीय स्थल",
        RequestCallback:"अनुरोध करें",
        OFF:"50% की छूट",
        needing:"आपकी यात्रा को बेहतर बनाने में आपकी सहायता के लिए हमें आपके कुछ बुनियादी विवरणों की आवश्यकता होगी",
        anExperts:"किसी विशेषज्ञ से जुड़ें",
        Awesome:"बहुत बढ़िया",
        personalisedquotation:"वैयक्तिकृत उद्धरण में कुछ और विवरण",
        vacationwith:"आप इस छुट्टी की योजना किसके साथ बना रहे हैं?",
        Solo:"एकल",
        Spouse:"जीवनसाथी",
        Family:"परिवार",
        Friends:"दोस्त",
        traveldate:"क्या आपकी यात्रा की तारीख तय है?",
        DateFixed:"तिथि निश्चित",
        flexible:"मेरी यात्रा की तारीखें 15 तक लचीली हैं",
        exploring:"मैं अभी केवल खोज कर रहा हूं",
        thistrip:"आप कितनी जल्दी यह यात्रा बुक करना चाहेंगे?",
        WithinHours:"48 घंटे के भीतर",
        WithinHour:"3 से 4 दिन के अंदर",
        WithinHou:"एक सप्ताह के अंदर",
        WithinHo:"बस पुनः शिक्षण",
        tickets:"क्या आपने अपनी फ्लाइट टिकट बुक कर ली है?",
        booked:"मेरी फ्लाइट टिकट बुक हो गई हैं.",
        helpbooking:"मुझे फ्लाइट टिकट बुक करने में भी मदद चाहिए।",
        bookflight:"नहीं, मैं खुद ही फ्लाइट टिकट बुक करूंगा।",
        thank:"धन्यवाद",
        MyProfile:"मेरी प्रोफाइल",
        edit:"मूल प्रोफ़ाइल संपादित करें",
        change:"फ़ोन नंबर बदलें",
        password:"पासवर्ड बदलें",
        Name:"नाम",
        Email:"ईमेल",
        Phone:"फ़ोन",
        Address:"पता विवरण",
        Edit:"संपादन करना",
        City:"शहर",
        Country:"देश",
        State:"राज्य",
        ZipCode:"ज़िप कोड",
        Saves:"सेव करे",
        CountryCode:"कंट्री कोड",
        PhoneNumber:"फ़ोन नंबर",
        CurrentPassword:"वर्तमान पासवर्ड",
        NewPassword:"नया पासवर्ड",
        ConfirmPassword:"पासवर्ड की पुष्टि करें ;",
        Cancel:"रद्द करना",
        Update:"अपडेट करे",
        FirstName:"पहला नाम",
        LastName:"उपनाम",
        MyBookings:"मेरी बुकिंग",
        MyQuotation:"मेरा उद्धरण",
        MyWallet:"मेरा खाता",
        MyPassengers:'मेरे यात्री',
        SupportFAQS:'समर्थन प्रश्न',
        Passengersadded:"कोई यात्री नहीं जोड़ा गया",
        AddNew:"नया जोड़ो",
        AdPassenger:"नया यात्री जोड़ें",
        Gender:"लिंग",
        Birth:"जन्म तिथि",
        SAVEDETAILS:"विवरण सहेजें",
        Delete:"हटाये",
        WalletHistory:" खाता इतिहास",
        transactionswallet:"लगता है आपके खाता में कोई लेन-देन नहीं है",
        FAQs:"पूछे जाने वाले प्रश्न",
        bookingtours:"मुझे पर्यटन और गतिविधियों की बुकिंग में रुचि है। मैं किसी से कैसे बात कर सकता हूँ?",
        bookingtour:"हमें यह जानकर ख़ुशी हुई कि आप हमारे साथ बुकिंग करने में रुचि रखते हैं। हमारे प्रतिनिधि से संपर्क करने के लिए थ्रिलोफिलिया के होम पेज पर जाएं और अपनी पसंद का गंतव्य खोजें और अनुरोध कॉलबैक पर टैप करें। हमारी टीम आपसे संपर्क करेगी.",
        visa:"क्या आप वीज़ा सहायता प्रदान करते हैं?",
        visas:"हाँ, हमारे द्वारा बुक किए गए बहु-दिवसीय पर्यटन और अवकाश पैकेजों के लिए हम वीज़ा खरीद में अपने ग्राहकों की सहायता करते हैं।",
        Thrillophilia:"थ्रिलोफिलिया द्वारा प्रदान किए जाने वाले विभिन्न प्रकार के उत्पाद और सेवाएँ क्या हैं?",
        Thrillophili:"जब गतिविधियों और अनुभवों की बात आती है तो थ्रिलोफिलिया देश का सबसे रोमांचकारी नाम है। हम दुनिया भर में छोटी गतिविधियाँ, बहु-दिवसीय पर्यटन, व्यापक अवकाश पैकेज प्रदान करते हैं।",
        paasss:"मैं अपना पासवर्ड कैसे बदलूं?",
        paass:"हाँ, हमारे द्वारा बुक किए गए बहु-दिवसीय पर्यटन और अवकाश पैकेजों के लिए हम वीज़ा खरीद में अपने ग्राहकों की सहायता करते हैं।",
        number:"फ़ोन नंबर लिखो",
        Emails:"ईमेल टाइप करें",
        Names:"अपना नाम डालें",
        persons:'व्यक्ति संख्या',
        comment:"टिप्पणी लिखो",
        LOgins:"लॉग इन करें",
        Logout:"लॉग आउट",
        Sign:'साइन अप करें',
        YourAccount:'अपने अकाउंट में लॉग इन करें',
        ForgetPassword:"पासवर्ड भूल गए?",
        LoginContinue:"लॉगिन करें और जारी रखें",
        ForgottenPassword:"अपना पासवर्ड भूल गये?",
        resetyour:"चिंता न करें, हम आपका पासवर्ड रीसेट करने में सहायता के लिए आपको एक संदेश भेजेंगे।",
        Continue:"जारी रखना",
        passwords:"पासवर्ड टाइप करे",
        Returnto:"को वापस",
        CreateYourAccount:"अपना खाता बनाएं",
        PrivacyPolicy:"शामिल होकर, आप शर्तों और गोपनीयता नीति से सहमत होते हैं।",
        Already:"क्या आपके पास पहले से एक खाता मौजूद है?",
        Tour:"यात्रा",
        ProductType:"उत्पाद का प्रकार",
        UptoDay:"1 दिन तक",
        UptoDays:"2 से 3 दिन",
        UptoDayss:"3 से 5 दिन",
        UptoDaysss:"5 से 7 दिन",
        UptoDayssss:"7+ दिन",
        PriceRange:"मूल्य सीमा",
        ClearAll:"सभी साफ करें",
        SearchProducts:"उत्पाद खोजें",
        ViewAll:"सभी को देखें",
        SearchHere:"यहां तलाश करो",
        helpyou:"नमस्ते, हम आपकी कैसे मदद कर सकते हैं?",
        searchterm:"यहां खोज शब्द दर्ज करें",
        Browsearticles:"आलेख ब्राउज़ करें",
        Browsearticlese:"कैसे करें का अन्वेषण करें और हमारे ज्ञान आधार से सर्वोत्तम अभ्यास सीखें",
        Submiticket:"एक टिकट जमा करें",
        Submitickets:"सहायता टिकट फॉर्म भरकर अपनी समस्या का वर्णन करें",
        Knowledgebase:"ज्ञानधार",
        Knowledgebases:"सभी आलेख देखें",
        sankar:"ओम शंकर के साथ अपने समर्थन अनुभव को उन्नत करें!",
        sankars:"अपनी बुकिंग में महारत हासिल करने के लिए यहां आपका अंतिम वीडियो गाइड है",
        Related:"ग्राहक संबंधी प्रश्न",
        Relateds:"आपकी त्वरित सहायता के लिए अक्सर पूछे जाने वाले प्रश्न और प्रासंगिक समाधान",
        Affiliation:"संबद्धता एवं amp; कवरेज संबंधी प्रश्न",
        popular:"सर्वाधिक लोकप्रिय लेख",
        allarticles:"सभी आलेख देखें",
        query:"प्रश्न/सहायता के लिए फोन/ईमेल पर कैसे जुड़ें?",
        Modified:'बुधवार, 19 फरवरी, 2020 को शाम 6:32 बजे संशोधित',
        site:"साइट पर टूर कैसे बुक करें।",
        Modifieds:"बुधवार, 22 सितंबर, 2021 को दोपहर 2:11 बजे संशोधित",
        received:"मैंने ऑनलाइन बुकिंग की है और मुझे पुष्टिकरण ईमेल वाउचर या कोई अन्य विवरण नहीं मिला है।",
        Modifiedse:"गुरु, 18 अप्रैल, 2019 को शाम 7:11 बजे संशोधित",
        cancellation:"ओम शंकर की रद्दीकरण नीतियां क्या हैं?",
        Modifiedt:"मंगलवार, 25 मई, 2021 को शाम 5:31 बजे संशोधित",
        Tirthyatra:"ओम शंकर तीर्थयात्रा",
        Shapingthe:"आकार देना",
        Shapingth:'यात्रा के भविष्य को',
        Shapingths:"लोगों के विश्व भ्रमण के तरीके को बदलना",
        Shapingths:"लोगों के विश्व भ्रमण के तरीके को बदलना",
        Our:"हमारा",
        Mission:"उद्देश्य",
        Missions:"ओम शिवशंकर तीर्थयात्रा में, हम यात्रा अनुभवों के परिदृश्य को बदलने की एक भव्य खोज पर हैं। हमारी आकांक्षा गंतव्यों से परे है - हम जुनून को जगाने, चिरस्थायी यादों को तराशने और हर खोजकर्ता के दिलों में गूंजने वाले रोमांच को गढ़ने का मार्ग प्रशस्त करने का प्रयास करते हैं। इस महाकाव्य यात्रा में हमारे साथ शामिल हों, जहां हर अभियान में रोमांच का सार समाहित है!",
        Namese:"चित्रा गुरनानी डागा",
        ceo:"सीईओ और सह-संस्थापक",
        Namess:"अभिषेक डागा",
        co:"सह संस्थापक",
        Meetthe:"मिलना",
        Architects:"अनुभवों के वास्तुकार",
        powers:"हम एक शक्तिशाली जनजाति हैं",
        powerss:"असाधारण रूप से प्रतिभाशाली और भावुक पेशेवर",
        ChitraGurnaniDag:"शो धावक",
        IdeaBox:"आइडिया बॉक्स",
        TarunDadlani:"तरूण डडलानी",
        TarunDadlan:"पार्टी का जीवन",
        TarunDadlans:"उपाध्यक्ष - विकास",
        AbhishekPuri:"अभिषेक पुरी",
        AbhishekPur:"मैन सिटी फैन",
        RajdeepMandrekar:"राजदीप मांड्रेकर",
        RajdeepMandreka:"पोकर लड़का",
        RajdeepMandrekas: "इंजीनियरिंग प्रमुख",
        GirdharSinghSodhas:"गिरधर सिंह सोढ़ा",
        GirdharSinghSodha:"भारी चालक",
        GirdharSinghSodhasa:"एसईओ प्रबंधक",
        RamRatanMishra:"रामरतन मिश्र",
        RamRatanMishraa:"संज्ञानात्मक मावेन",
        RamRatanMishr:"डिजिटल मार्केटिंग मैनेजर",
        ChitranshSingh:"चित्रांश सिंह",
        ChitranshSing:"ऑफ-रोडर",
        ChitranshSin:"सह उपाध्यक्ष",
        Seeyourselfhere:"अपने आप को यहाँ देखें?",
        JoinUs:"हमसे जुड़ें!",
        Innovation:"विचारों, नवप्रवर्तन और विकास में परास्नातक",
        guide:"ओम शंकर के उन नेताओं से मिलें जो नवप्रवर्तन की राह दिखाते हैं। वे प्रत्येक कार्य में प्रतिभा का पुट, हास्य का पुट और अपरंपरागत प्रतिभा की चिंगारी जोड़ते रहते हैं। तकनीकी जादूगरों से लेकर बिक्री डायनेमो तक, वे एक ऐसे कार्यस्थल के वास्तुकार हैं जहां हर दिन एक रोमांचक यात्रा है। आइए, हमारे साथ जुड़ें और उस सहज, आश्चर्यजनक और बेजोड़ विशेषज्ञता का जश्न मनाएं जो इस टीम को वास्तव में असाधारण बनाती है।",
        omshiv:"ॐ शिव शंकर",
        Thrillophilia:"यात्रा ?",
        Thrillophili:"साहसिक पथ पर चलना, एक समय में एक उल्लेखनीय स्मृति।",
        Igniting:'प्रज्वलित करना',
        Vision:"दृष्टि",
        Infusing:"संचार करना",
        launched:"चित्रा गुरनानी डागा और अभिषेक डागा का साझा सपना, थ्रिलोफिलिया 2011 में लॉन्च किया गया था। केवल एक साल के भीतर, एक ऑनलाइन प्लेटफ़ॉर्म के रूप में शुरू हुआ यह भारत के सबसे पसंदीदा साहसिक यात्रा सेवा प्रदाताओं में से एक बन गया। 2011-13 से, थ्रिलोफिलिया ने अपने क्षितिज का विस्तार करना जारी रखा और यहां तक ​​कि बी2बी क्षेत्र में भी कदम रखा। कंपनी ने Google, सिस्को, SAP, Microsoft, मर्सिडीज और कई प्रमुख ब्रांडों जैसे 1000+ कॉर्पोरेट ग्राहकों को सेवा प्रदान की। हमने 300K से अधिक यात्रियों की सेवा की और खुद को कॉर्पोरेट यात्रा अनुभवों में अग्रणी के रूप में स्थापित किया।",
        Investments:"निवेश",
        Breakthroughs:"सफलताओं",
        significant:"इस महत्वपूर्ण वर्ष में रणनीतिक विस्तार के साथ-साथ महत्वपूर्ण निवेश भी देखने को मिला, जिसने थ्रिलोफिलिया को बाजार में अग्रणी बनने के लिए आवश्यक प्रोत्साहन प्रदान किया। अगले दो वर्षों में, हमने गतिविधियों और अनुभवों को बढ़ाने का जोखिम उठाया, प्रौद्योगिकी में निवेश किया और चलते-फिरते बुकिंग गतिविधियों के लिए एक बी2सी ऐप लॉन्च किया। अगले तीन वर्षों (2014-17) में विकास दर साल-दर-साल 200% तक बढ़ गई। 2016 के अंत तक, हमने अपना परिचालन आधार बेंगलुरु से जयपुर स्थानांतरित कर दिया और अपने विकास उद्देश्यों को पूरा करने के लिए टीम के आकार का विस्तार किया।",
        Recognition:"नवाचार और उद्योग की पहचान का एक वर्ष",
        destinations:"3 मिलियन से अधिक उपयोगकर्ता आधार के साथ, थ्रिलोफिलिया ने 125+ गंतव्यों में 12,500+ गतिविधियों को क्यूरेट किया और भारत की गतिविधियों में अग्रणी के रूप में उभरा। अनुभव यात्रा बाजार। हमें यात्रा उद्योग में व्यापक मान्यता और प्रशंसा मिली। अनुभवात्मक यात्रा की बढ़ती मांग के जवाब में, हमने अगले दो वर्षों में मल्टी-डे टूर सेगमेंट में उद्यम करने के लिए कुछ भारतीय गंतव्यों का चयन किया। पायलट परीक्षणों ने इस आशाजनक श्रेणी के लिए हमारे आत्मविश्वास को बढ़ाया।",
        Thriving:"कोविड नामक सर्वनाश के माध्यम से एक साथ मिलकर आगे बढ़ना",
        embarked:"कोविड-19 के प्रकोप के दौरान, जबकि दुनिया स्थिर थी, हम, थ्रिलोफिलिया में, अपनी सामग्री और प्रौद्योगिकी बुनियादी ढांचे में बदलाव की यात्रा पर निकले। हमारे निरंतर प्रयासों और कभी न हार मानने वाले रवैये के परिणामस्वरूप कंपनी के राजस्व में पूर्व-कोविड वर्षों की तुलना में 10 गुना वृद्धि हुई। महामारी के बाद विकसित हो रहे यात्रा परिदृश्य को पहचानते हुए, हमारी टीम ने नवीन अवधारणाओं को आगे बढ़ाया, जिससे 53 टेकऑफ़्स का निर्माण हुआ - जो डिजिटल-फर्स्ट ट्रैवल ब्रांडों का घर और थ्रिलोफिलिया की वर्तमान मूल कंपनी है। वित्त वर्ष 2022-23 में, थ्रिलोफिलिया ने निवेशकों से अपनी 30% हिस्सेदारी वापस खरीद ली, जिससे हमें पूर्ण स्वायत्तता मिली और नवाचार और विकास के एक नए अध्याय के लिए मंच तैयार हुआ।",
        Autonomy:'पूर्ण स्वायत्तता के साथ उत्कृष्टता के नए शिखर छूना|',
        unprecedented:"2023 में, थ्रिलोफिलिया अभूतपूर्व ऊंचाइयों पर पहुंच गया, उपयोगकर्ता जुड़ाव और वैश्विक आउटरीच में उल्लेखनीय मील के पत्थर हासिल किए। इस वर्ष, थ्रिलोफिलिया ने अंतरराष्ट्रीय बहु-दिवसीय टूर बाज़ार में प्रवेश किया, जिससे हमारे ग्राहकों के लिए अनुभवों और गतिविधियों की एक  खुल गई। हमने दुबई, अबू धाबी, थाईलैंड और कई अन्य स्थानों के प्रतिष्ठित पर्यटन बोर्डों के साथ रणनीतिक साझेदारी भी स्थापित की है। अंतर्राष्ट्रीय बहु-दिवसीय पर्यटन की शुरूआत और रणनीतिक साझेदारियों के परिणामस्वरूप 2023 में 500+ करोड़ का कारोबार हुआ। हमारा अगला मिशन 53 टेकऑफ़ के साथ बहु-अरब डॉलर का पर्यटन व्यवसाय बनाना, 100 से अधिक अंतर्राष्ट्रीय ब्रांड स्थापित करना और 2027 तक 25,000 करोड़ से अधिक का राजस्व उत्पन्न करना है।",
        Tirthyatra:"यदि ओम शिवशंकर तीर्थयात्रा पर",
        PictureGallery:"चित्र गैलरी",
        Spotlight:"सुर्खियों में",
        Adventures:"जानें कि दुनिया हमारे साहसिक कार्यों और अनुभवों के बारे में क्या कह रही है",
        Funding:"सफलता की कहानी: ओम शिव शंकर तीर्थयात्रा की फंडिंग गाथा के लिए 53 टेकऑफ़ जैविक विकास",
        Fundings:"53टेकऑफ़ तीन साल के भीतर भारत की अग्रणी मल्टी-डे टूर कंपनी बन गई, जिसने 2023 में 800,000+ यात्रियों को सेवा प्रदान की। 500+ करोड़ के टर्नओवर और पिछले वर्ष की तुलना में 2.5 गुना वृद्धि की रिपोर्ट करते हुए, उन्होंने ओम शिवशंकर तीर्थयात्रा में निवेशक शेयरों को वापस खरीदकर अपने दृष्टिकोण पर नियंत्रण भी हासिल कर लिया।",
        Insights:"अंतर्दृष्टि, प्रेरणाएँ और",
        Impact:"प्रभाव",
        Stories:"विचारों, कहानियों, समाचारों और प्रेरणा की दुनिया में गोता लगाएँ",
        Specially:"एक विशेष रूप से सक्षम जोड़े की लद्दाख यात्रा की एक प्रेरक कहानी",
        outperform:"कभी-कभी, हमें अपने कामकाजी जीवन में केवल एक ऐसे व्यक्ति की आवश्यकता होती है जो हमें किसी भी स्थिति में बेहतर प्रदर्शन करने के लिए प्रेरित करे। लगभग एक इच्छा के सच होने के रूप में, हमें ओम शिव शंकर तीर्थयात्रा की वजह चित्रा गुरनानी डागा का आशीर्वाद मिला है, जिन्होंने हमें सफलता की ओर बढ़ने में मदद और मार्गदर्शन किया है।",
        Takeoffs:"टेकऑफ",
        focusing:"डिजिटल-फर्स्ट ट्रैवल ब्रांडों का एक समूह है, जो अपने पर्यटन को बढ़ाकर और नए ट्रैवल ब्रांडों को विकसित करके एक शक्तिशाली ट्रैवल समूह बनने पर ध्यान केंद्रित कर रहा है।",
        consistent:"वर्तमान में 75 से अधिक देशों में ग्राहकों को सेवाएं प्रदान करते हुए, कंपनी ऑनलाइन यात्रा, प्रौद्योगिकी, उपभोक्ता समझ और वितरण में अपनी दक्षता का लाभ उठा रही है। एक बात जो इसके सभी ब्रांडों में एक समान बनी हुई है, वह है ग्राहकों को सर्वोत्तम यात्रा अनुभव प्रदान करना। अब तक जो ब्रांड चलाए जा रहे हैं उनमें ओम शिवशंकर तीर्थयात्रा, कपल एस्केप्स, रेयर सफारिस और मायट्रैवलपास शामिल हैं।",
        conglomerate:"अगले 5 वर्षों में एक बहु-अरब डॉलर का यात्रा समूह बनने के लिए तैयार है, जिसका मिशन दुनिया भर में 100+ बड़े यात्रा ब्रांड बनाना है।",
        OurPresence:"हमारी उपस्थिति",
        JaipurIndia:"जयपुर, भारत",
        JaipurIndias:"सीतापुरा औद्योगिक क्षेत्र, सीतापुरा, जयपुर, राजस्थान",
        UAE:"दुबई, संयुक्त अरब अमीरात",
        UAe:"दुबई सिलिकॉन ओएसिस, डीडीपी, बिल्डिंग ए, दुबई, संयुक्त अरब अमीरात",
        Delaware:"डेलावेयर, यूएसए",
        Delawar:"सुइट 403-बी, 1013 सेंटर रोड, विलमिंगटन, न्यू कैसल, डेलावेयर",
        Future:"आइए यात्रा के भविष्य का निर्माण करें",
        TOGETHER:"एक साथ",
        ambitious:"ओम शिवशंकर तीर्थयात्रा सिर्फ एक कैरियर अवसर नहीं है; यह नए विचारों, महत्वाकांक्षी व्यक्तियों, तेज़ गति वाली संस्कृति और अप्रतिबंधित विकास के लिए प्रजनन भूमि है। यदि आप अपनी क्षमता को खोजने, बदलाव लाने और एक रोमांचक पेशेवर यात्रा में शामिल होने के लिए तैयार हैं - तो हमें चुनें! केवल आपके लिए तैयार किए गए बटन पर क्लिक करें, चाहे आप मार्केटिंग, बिक्री, तकनीक या उससे आगे के लिए आकर्षित हों - आपका साहसिक कार्य आपका इंतजार कर रहा है",
        Heightsm:"यात्रा अनुभवों और प्रौद्योगिकी को नई ऊंचाइयों तक ले जाने वाली हमारी चाय से जुड़ें",
        Building:"इमारत",
        explore:"खोजे",
        Trending:"रुझान",
        Female:"महिला",
        Male:"पुरुष",
        India:"भारत",
        USA:"यूएसए",
        Nodatafound:"डेटा नहीं मिला",
        Support:"सहायता",
        review_score:'समीक्षा',
        Bigger:"बड़ा समूह? 50% तक की विशेष छूट प्राप्त करें!",
        unforgettable:"हम आपके समूह के लिए अनुकूलित अविस्मरणीय रोमांच बनाते हैं।",
        Download:"टूर डाउनलोड",
        cities:'कोई शहर उपलब्ध नहीं',
        ANew:"एक नया पासवर्ड चुनें",
        PackageCode:'पैकेज कोड',
        Traveler:"यात्री",
        TotalAmount:"कुल राशि",
        DateofTraveler:"यात्री की तिथि",
        passwordsss:"मेरा प्रोफाइल पेज जाओ फिर आपका पासवर्ड चेंज पेज दिखायी देगा आप पासवर्ड चेंज कर सकते हैं",
        day:"दिन",
        Popular:"लोकप्रिय पेज",
        booklet:"पुस्तिका",
        ClientSay:"हमारे ग्राहकों की राय - शिव शंकर तीर्थ यात्रा के बारे में",
        GetInTouch:"संपर्क में रहो",
        TirthYatraIndia:"यात्रा कंपनी",
        helps:"हम एक संगठित तरीके से तीर्थयात्रा आयोजित करते हैं जिसमें तीर्थयात्रियों को उच्च गुणवत्ता वाला भोजन, सत्संग कार्यक्रम और चिकित्सा सुविधाएं प्रदान की जाती हैं।",
        sandesh:"संदेश",
        BestServices:"चारधाम यात्रा का अनुभव",
        











      }
};

export default hi;