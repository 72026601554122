import React, { useEffect, useState } from 'react';
import "../../assets/style.css";
import { IoArrowBackSharp } from "react-icons/io5";
import Form from 'react-bootstrap/Form';
import Swal from "sweetalert2";
import { apiRequest } from '../../ApiService/Allapi';
import { useTranslation } from "react-i18next";

function Edit({ onBack }) {
    const langs = localStorage.getItem("i18nextLng") ?? "en";
    const [lang, setLang] = useState(langs);
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(false);
    const username = localStorage.getItem("name")
    const [data, setData] = useState({
        name: username,
        l_name: ''
    });

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };


    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission behavior
        setLoading(true); // Set loading to true

        const body = {
            name: data.name,
            l_name: data.l_name,
        };

        try {
            const result = await apiRequest(`edit-profile?lang${lang}`, "POST", body);
            if (result.status === true) {
                localStorage.setItem("name", result.user.name)
                Swal.fire({
                    title: result.message,
                    icon: "success",
                });
                onBack();
                window.location.reload();
            }
            else {
                      Swal.fire({
                        title: result.message,
                        icon: "error"
                      });
                    }
        } catch (error) {
            Swal.fire({
                title: error.message,
                icon: "error",
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <div className='edit_box'>
                <div className='back_btn'>
                    <a href="#" onClick={(e) => { e.preventDefault(); onBack(); }}>
                        <IoArrowBackSharp />
                    </a>
                </div>

                <div className='card'>
                    <div className='card-header'>
                        <h4>{t("MyProfile")}</h4>
                    </div>
                    <div className='card-body'>
                        <Form onSubmit={handleSubmit}>
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>{t("FirstName")}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder={t("FirstName")}
                                            name="name"
                                            value={data.name}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                </div>

                                <div className='col-lg-6'>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                                        <Form.Label>{t("LastName")}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder={t("LastName")}
                                            name="l_name"
                                            value={data.l_name}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                </div>
                            </div>

                            <div className='d-flex align-items-center justify-content-end'>
                                <button
                                    type="button"
                                    className='btn btn-light me-1'
                                    onClick={onBack}
                                >
                                    {t("Cancel")}
                                </button>
                                <button
                                    type="submit"
                                    className='btn btn-primary w-auto'
                                    disabled={loading}
                                >
                                    {loading ? "Updating..." : t("Update")}
                                </button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Edit;









