import React from 'react';
import "../assets/style.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../src/assets/chardham.png';
import kamakya from '../../src/assets/kamakya.png'
import kamakya1 from '../../src/assets/kamakya1.jpg'

const Kamakhya = () => {



    return (
        <>
            <div>

                <img className='w-100' src={kamakya} alt="kamakya.png" />
            </div>
            <section className='section-padding'>
                <div className='container'>
                    <div className>
                        <div className='row'>
                            <div className='col-lg-12 tabs'>
                                <h1>Kamakhya-Darjeeling Yatra with Om Shiv Shankar Tirth Yatra
                                </h1>
                                <span>
                                </span> <br />

                                <p classname="about_para">Where faith and mountains meet the sky,
                                    A sacred call, so pure, so high.
                                    Kamakhya’s blessings, strong and deep,
                                    Where Goddess’ grace makes hearts weep.
                                    The hills of Darjeeling, calm and bright,
                                    Wrapped in mist, bathed in light.
                                    A journey where the soul takes flight,
                                    In devotion’s glow and nature’s sight.
                                    Come, walk this path, serene and true,
                                    With Om Shiv Shankar Tirth Yatra guiding you. 🚩

                                </p>
                                <br />
                                <h1>Kamakhya-Darjeeling Yatra with Om Shiv Shankar Tirth Yatra 🌺🏔️
                                </h1> <br />
                                <p>Join Om Shiv Shankar Tirth Yatra on a sacred journey where devotion meets the beauty of the Himalayas. 
                                    Our Kamakhya-Darjeeling Yatra is designed to offer you spiritual bliss and scenic 
                                    tranquility in one unforgettable pilgrimage.

                                </p>

                              

                                <h3>Why Travel with Us?    </h3>

                                <p>🙏 Divine Blessings at Kamakhya Temple – Experience the power of Maa Kamakhya, one of the most revered Shakti Peethas, where faith transforms into miracles.
                                </p>
                                <p> 🏔️ Soulful Serenity in Darjeeling – After seeking divine blessings, immerse yourself in the peaceful hills of Darjeeling, where nature’s beauty refreshes the heart and mind.

                                </p>
                                <p>🚆 Comfortable & Hassle-Free Journey – At Om Shiv Shankar Tirth Yatra, we take care of every detail, ensuring a smooth, well-organized trip so you can focus on your spiritual journey.

                                </p>

                                <p>✨ More Than Just a Tour—A Spiritual Awakening – Whether you seek divine energy, peace, or adventure, this yatra offers the perfect blend of faith and nature.
                                </p>
                                <p>Come, experience devotion and beauty with Om Shiv Shankar Tirth Yatra. Book your Kamakhya-Darjeeling Yatra today! 🚩

                                </p>

                             

                            </div>


                        </div>
                        <div className='mt-4'>
                            <img  className="w-100" src={kamakya1} alt="kamakya1.png" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );

}
export default Kamakhya;