import React from 'react';
import "../assets/style.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import bhutan from '../../src/assets/bhutan.png'

const Bhutan = () => {
    return (
        <>
            <div>

                <img className='w-100' src={bhutan} alt="bhutan.png" />
            </div>
            <section className='section-padding'>
                <div className='container'>
                    <div className>
                        <div className='row'>
                            <div className='col-lg-12 tabs'>
                                <h1>Bhutan Yatra
                                </h1>
                                <span>
                                </span> <br />

                                <p classname="about_para">Where mountains whisper and prayer flags sway,
                                    A land of peace calls you away.
                                    In quiet winds and rivers bright,
                                    Find your soul in golden light.
                                    Happiness blooms in hearts so free,
                                    A journey of love and serenity.
                                    Come, embrace this tranquil shore,
                                    With Om Shiv Shankar Tirth Yatra—seek peace once more.
                                </p>
                                <br />
                                <h1>Discover Peace and Spirituality with a Bhutan Yatra
                                </h1> <br />
                                <p>Bhutan, a mystical land nestled in the Himalayas, is the perfect destination for those seeking peace, spirituality, and a deep connection with nature. With its serene environment, rich cultural heritage, and commitment to happiness, a journey to this sacred land is more than just a trip—it’s an experience that nourishes the soul.
                                </p>

                                <h3>Why Embark on a Bhutan Yatra?
                                </h3>
                                <p>1. A Spiritual Awakening

                                </p>
                                <p> This land is deeply rooted in spiritual traditions, offering an atmosphere that promotes meditation, self-reflection, and inner peace. The rhythmic chants, the fluttering prayer flags, and the serene surroundings create a divine experience unlike any other.
                                </p>
                                <p>2. Breathtaking Natural Beauty
                                </p>
                                <p> Towering mountains, lush green valleys, and pristine rivers make this journey a feast for the senses. Every moment spent here brings a sense of calm and rejuvenation, allowing travelers to disconnect from the chaos of everyday life and embrace tranquility.
                                </p>
                                <p>3. A Way of Life Centered on Happiness
                                </p>
                                <p>Unlike anywhere else in the world, this unique country values happiness and well-being above material success. The warm hospitality, simple lifestyle, and deep-rooted traditions inspire visitors to find joy in life’s small moments.
                                </p>
                                <p> 4. A Seamless and Enriching Experience
                                </p>
                                <p> With a focus on preserving its culture and environment, Bhutan offers a peaceful and well-organized travel experience. Every aspect of the journey is designed to provide a sense of harmony, making it ideal for those seeking spiritual and personal growth.
                                </p>
                                <h3>Bhutan Yatra – 10 Days Spiritual & Scenic Journey</h3>
                                <p>Experience the Land of Happiness with Om Shiv Shankar Tirth Yatra!
                                </p>
                                <p>📅 Duration: 10 Days</p>
                                <p className='border-bottom border-3 pb-3'>📍 Destinations Covered: Jalpaiguri– phuentsholing – thimphu – paro - punakha
                                </p>


                                <p>🗓️ Day-Wise Itinerary
                                </p>
                                <h5>📍 Day 1: Journey starts </h5>
                                <p>✈️ journey will start from Jaipur, Kota, Delhi, Bhopal   </p>

                                <h5>📍 Day 3: walking towards heaven
                                </h5>
                                <p>🏯 Arrival in Jalpaiguri
                                </p>
                                <p>🚗 Departure for Phuentsholing
                                </p>
                                <p>🏨 Travellers will take rest at Phuentsholing
                                </p>

                                <h5>📍 Day 4: Himalayan views
                                </h5>
                                <p>🚗 Scenic drive to Thimphu – Stunning natural views
                                </p>
                                <p> 🏯 Visit Chukha dam, one of Bhutan’s most beautiful monasteries
                                </p>
                                <p> 🌉 Photo memories
                                </p>
                                <p>🏨rest at Thimpu</p>


                                <h5>📍 Day 5: Enriching the scenery of Thimpu</h5>
                                <p>🌿 Travel to Buddha point, Memorial chorten, BBS Tower , National library   </p>
                                <p> 🏯 Visit Takin Zoo, Kisan bazar , zangthopelri lhakhang, changlimithang stadium                                </p>

                                <h5>📍 Day 6: Enriching Punakha </h5>
                                <p>🚗 Experiencing the natural beauty of punakha
                                </p>
                                <p> 🛕 Night stay at Punakha
                                </p>

                                <h5>📍 Day 7: Pora
                                </h5>
                                <p>🚗Scenic drive of Pora and visiting different places while enjoying natural scenery.
                                </p>
                                <p> 🛍️ Night stay at pora
                                </p>

                                <h5>📍 Day 8: Pora to Phuentsholing
                                </h5>
                                <p>🥾 departure for jaigaon
                                </p>
                                <p> 🏞️ night stay ar Jaigaon
                                </p>

                                <h5>📍 Day 9: Jaigaon to new cooch behar
                                </h5>
                                <p>🚗 departure for delhi
                                </p>

                                <h5>📍 Day 10: Departure
                                </h5>
                                <p className='border-bottom border-3 pb-3' >✈️ Travel ended with beautiful memories!
                                </p>

                                <h3>🌟 Package Inclusions</h3>
                                <p>✔ Accommdation in quality hotels</p>
                                <p>✔ Daily 1 time satwik breakfast and 2 times Tea   </p>
                                <p> ✔ Daily 2 times satwik Vegetarian Meals ( Lunch & Dinner) </p>
                                <p>✔ Transport – Comfortable AC vehicle for sightseeing & transfers   </p>
                                <p> ✔ Professional Guide   </p>
                                <p> ✔ Bhutan Travel Permit & Entry Fees  </p>
                                <h3>🚫 Exclusions </h3>
                                <p> ❌ Personal expenses (shopping, tips, beverages)    </p>
                                <p className='border-bottom border-3 pb-3'> ❌ Any extra meals/snacks beyond the package</p>


                                <h4>💰 Tour Pricing Per Person   </h4>
                                <table>
                                   <thead>
                                    <tr>
                                        <th>Category</th>
                                        <th>Price Range (Per Person)</th>
                                        <th>Hotel Type</th>
                                        <th>Transport</th>
                                        </tr>
                                   </thead>
                                   <tbody>
                                        <tr>
                                            <td>Budget</td>
                                            <td>₹42,500</td>
                                            <td>Standard AC Hotels</td>
                                            <td>Shared Transport  </td>
                                        </tr>
                                   </tbody>
                                    
                                </table>

                                <h3 className='mt-3'> 📞 How to Book?</h3>
                                <p  className='mt-3'>📞 Call: 9460938009</p>
                             <p> 📩 Email: omshivshankartirthyatra01@gmail.com</p>
                             <p>🚀 Limited Seats! Book Now & Experience the Divine Beauty of Bhutan! </p>

                            </div>

                        </div >
                        <div className='mt-4'>
                            {/* <img src={chardham1} alt="chardham1.png" /> */}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Bhutan;