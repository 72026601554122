import React from 'react';
import "../assets/style.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import bangkok from '../../src/assets/bangkok.png'
import bangkok1 from '../../src/assets/bangkok1.jpg'


const Bangkok = () => {
    return (
        <>
            <div>

                <img className='w-100' src={bangkok} alt="bangkok.png" />
            </div>
            <section className='section-padding'>
                <div className='container'>
                    <div className>
                        <div className='row'>
                            <div className='col-lg-12 tabs'>
                                <span className='sh-4'>Where golden spires kiss the blue,  </span>
                                <h1>Bangkok-Cambodia Yatra: A Journey of Divine Grace & Timeless Wonders
                                </h1>
                                <span>
                                </span> <br />

                                <p classname="about_para">Step into a world where faith meets history with Om Shiv Shankar Tirth Yatra.
                                    From the golden temples of Bangkok, radiating peace and blessings, to the majestic Angkor Wat,
                                    an ancient marvel of devotion, this journey is a gateway to spiritual awakening and cultural
                                    richness.

                                </p>
                                <br />

                                <p>Why You Should Not Miss This Yatra?
                                </p>


                                <p>🛕 Seek Blessings in Bangkok – Experience the divine energy of sacred temples, where prayers echo in golden halls.

                                </p>
                                <p> 🏯 Witness the Grandeur of Angkor Wat – Stand in awe of the world’s largest temple, a masterpiece of devotion and history.
                                </p>

                                <p> 🙏 A Journey Beyond Just Travel – Feel peace, embrace faith, and immerse in the divine aura of these sacred lands.
                                </p>
                                <p>At Om Shiv Shankar Tirth Yatra, we don’t just take you on a tour; we take you on a soul-stirring experience. Join us and let this journey transform you! 🚩
                                </p>

                                <p> And whispers of the past shine through.
                                    The Buddha’s grace, the temples high,
                                    A sacred glow beneath the sky.
                                </p>
                                <p> Through Bangkok’s streets, so bright, so wide,
                                    Where faith and culture walk side by side.
                                </p>
                                <p>  To Angkor’s stones, so vast, so deep,
                                    Where ancient prayers forever sleep.
                                </p>
                                <p> To Angkor’s stones, so vast, so deep,
                                    Where ancient prayers forever sleep.
                                </p>
                                <p>A journey blessed, both old and new,
                                    With Om Shiv Shankar Tirth Yatra guiding you. 🚩
                                </p>
                            </div>

                        </div >
                        <div className='mt-4'>
                            <img className="w-100" src={bangkok1} alt="bangkok1.png" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Bangkok;