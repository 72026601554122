import "./App.css";
import Header from "./components/common/header";
import Footer from "./components/common/footer";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Homepage from "./pages/homepage";
import About from "./pages/about";
import Tour from "./pages/tour";
import Myprofile from "./pages/my-profile";
import Profile from "./components/profile-component/profile";
import Booking from "./components/profile-component/booking";
import Quotations from "./components/profile-component/quotations";
import Wallet from "./components/profile-component/wallet";
import Passengers from "./components/profile-component/my-passengers";
import FAQ from "./components/profile-component/faq";
import TermsandCondition from "./pages/terms-condition";
import "../src/assets/style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import ResetPassword from "../src/pages/reset-password";
import Support from "../src/pages/support";
import Edit from "../src/components/profile-component/edit";
import ChangePhone from "../src/components/profile-component/change-phone";
import ChangePassword from "../src/components/profile-component/change-password";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import Contect from "./pages/Contect";
import Rewes from './pages/Rewes'
import Carrier from './pages/Carrier'
import Blog from "./pages/Blog";
import CharDhamYatra from "./pages/charDhamYatra";
import TermsofService from "./pages/TermsofService";
import BrajKos from "./pages/brajKos";
import AndamanNicobar from "./pages/andamanNicobar";
import Rameshwaram from "./pages/rameshwaram";
import PuriNepal from "./pages/puriNepal";
import Kamakhya from "./pages/kamakhya";
import Nepal from "./pages/nepal";
import AccountDetails from "./pages/accountDetails";
import Bhutan from "./pages/bhutan";
import Bangkok from "./pages/bangkok";
import Srilanka from "./pages/srilanka";

function App() {
  return (
    <Router>
      {/* ✅ Header */}
      <Header />

      {/* ✅ Routes */}
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/about" element={<About />} />
        <Route path="/tour" element={<Navigate to="/" replace />} />
        <Route path="/tour/:slug" element={<Tour />} />
        <Route path="/my-profile" element={<Myprofile />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/booking" element={<Booking />} />
        <Route path="/quotations" element={<Quotations />} />
        <Route path="/wallet" element={<Wallet />} />
        <Route path="/my-passengers" element={<Passengers />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/terms-condition" element={<TermsandCondition />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="/support" element={<Support />} />
        <Route path="/edit" element={<Edit />} />
        <Route path="/change-phone" element={<ChangePhone />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/contect" element={<Contect />} />
        <Route path="/rewes" element={<Rewes />} />
        <Route path="/carrier" element={<Carrier />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/chardhamyatra" element={<CharDhamYatra />} />
        <Route path="/termsofservice" element={<TermsofService />} />
        <Route path="/brajkos" element={<BrajKos />} />
        <Route path="/andman" element={<AndamanNicobar />} />
        <Route path="/termsofservice" element={<TermsofService />} />
        <Route path="/rameshwaram" element={<Rameshwaram />} />
        <Route path="/puriNepal" element={<PuriNepal />} />
        <Route path="/kamakhya" element={<Kamakhya />} />
        <Route path="/nepal" element={<Nepal />} />
        <Route path="/accountDetails" element={<AccountDetails />} />
        <Route path="/bhutan" element={<Bhutan />} />
        <Route path="/bangkok" element={<Bangkok />} />
        <Route path="/srilanka" element={<Srilanka />} />


      </Routes>


      <div
        className="support"
        style={{
          position: "fixed",
          bottom: "140px",
          right: "50px",
        }}
      >
        <a href="https://wa.me/+919460938009" target="_blank">
          <FloatingWhatsApp
            phoneNumber="+919876543210"
            accountName="Om ShivShankar Yatra"
            chatMessage="Hello! How can we assist you?"
            avatar="https://example.com/your-avatar.jpg"
          />
        </a>
      </div>


      <a href="tel:0012341234">
        <button class="btn-floating phone">
          <img src="https://i.imgur.com/FZuns9L.png" alt="Phone" />
          <span>(00) 1234-1234</span>
        </button>
      </a>
      {/* ✅ Footer */}
      <Footer />
    </Router>
  );
}

export default App;
