import React, { useEffect, useState } from 'react';
import "../../assets/style.css";
import Dropdown from 'react-bootstrap/Dropdown';
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaRegUser, FaPhone } from "react-icons/fa6";
import { MdOutlineEmail, MdOutlineModeEdit, MdOutlineChangeCircle, MdEdit } from "react-icons/md";
import Form from 'react-bootstrap/Form';
import Swal from "sweetalert2";
import { apiRequest } from '../../ApiService/Allapi';
import { useNavigate } from 'react-router-dom';
import Edit from './edit';
import ChangePhone from '../profile-component/change-phone';
import ChangePassword from '../profile-component/change-password';
import { useTranslation } from "react-i18next"; 
function Profile() {
    const navigate = useNavigate();

    // State to manage visibility of components
    const [showEdit, setShowEdit] = useState(false);
    const [showChangePhone, setShowChangePhone] = useState(false);
    const [showChangePassword, setShowChangePassword] = useState(false);

    const [shows, setshows] = useState(false)

    const [formData, setFormData] = useState({
        city: "",
        Country: "",
        Address: "",
        State: "",
        Code: "",
    });
    const [loading, setLoading] = useState(false);
    const [User, setUser] = useState([]);

     const langs = localStorage.getItem("i18nextLng") ?? "en";
        const [lang, setLang] = useState(langs);

    useEffect(() => {
        const fetchProfile = async () => {
            setLoading(true);

            try {
                const token = localStorage.getItem("token");
                if (!token) {
                    navigate("/");
                }

                const headers = { Authorization: `Bearer ${token}` };
                const response = await apiRequest(`get-address?lang=${lang}`, "GET", null, headers);

                if (response.status===true) {
                    setUser(response.user);
                    setFormData({
                        city: response.data.city,
                        State: response.data.state || "",
                        Country: response.data.country || "",
                        Code: response.data.zip_code || "",
                        Address: response.data.full_addr || "",
                    });

                    Swal.fire({
                        title: response.message || "Fetched successfully",
                        icon: "success",
                    });
                } else {
                    if (response.logout) {
                        Swal.fire({
                            title: "Session expired. Please log in again.",
                            icon: "error",
                        });
                        localStorage.clear();
                        navigate("/");
                    }
                    Swal.fire({
                        title: response.message || "Failed to fetch profile data.",
                        icon: "error",
                    });
                }
            } catch (error) {
                if (error.logout) {
                    Swal.fire({
                        title: "Session expired. Please log in again.",
                        icon: "error",
                    });
                    localStorage.clear();
                    navigate("/");
                } else {
                    Swal.fire({
                        title: error.message,
                        icon: "error",
                    });
                }
                console.error("Error:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchProfile();
    }, [navigate,lang]);

    const handlechange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handlesumbit = async (e) => {
        e.preventDefault();

        const body = {
            city: formData.city,
            state: formData.State,
            country: formData.Country,
            zip_code: formData.Code,
            full_addr: formData.Code,
        };

        try {
            const result = await apiRequest(`address-store?lang=${lang}`, "POST", body);
            if (result.status === true) {
                // localStorage.setItem("token", result?.token);
                Swal.fire({
                    title: result.message,
                    icon: "success",
                });
            } else {
                Swal.fire({
                    title: result.message,
                    icon: "error",
                });
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                Swal.fire({
                    title: "Session expired. Please log in again.",
                    icon: "error",
                });
                navigate("/");
            }
        } finally {
            setLoading(false);
        }
    };

    const handleBackButton = () => {
        setShowEdit(false);
        setShowChangePhone(false);
        setShowChangePassword(false);
    };

    const { t, i18n } = useTranslation();

    return (
        <>
            <div className="profile-right-content">
                {showEdit && <Edit onBack={handleBackButton} />}
                {showChangePhone && <ChangePhone onBack={handleBackButton} />}
                {showChangePassword && <ChangePassword onBack={handleBackButton} />}

                {!showEdit && !showChangePhone && !showChangePassword && (
                    <>
                        <div className="card">
                            <div className="card-header">
                                <h4>{t("MyProfile")}</h4>
                                <Dropdown className="profile_dropdown_main">
                                    <Dropdown.Toggle
                                        className="profile_dropdown"
                                        variant=""
                                        id="dropdown-basic"
                                    >
                                        <BsThreeDotsVertical className="fs-18" />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => setShowEdit(true)}>
                                            <MdOutlineModeEdit className="me-0" /> {t("edit")}
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => setShowChangePhone(true)}>
                                            <FaPhone />{t("change")}
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => setShowChangePassword(true)}>
                                            <MdOutlineChangeCircle /> {t("password")}
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="profile_details">
                                            <div className="d-flex">
                                                <FaRegUser />
                                                <div>
                                                    <span>{t("Name")}</span>
                                                    <h6>{User?.name}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="profile_details">
                                            <div className="d-flex">
                                                <MdOutlineEmail />
                                                <div>
                                                    <span>{t("Email")}</span>
                                                    <h6>{User?.email}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="profile_details">
                                            <div className="d-flex">
                                                <FaPhone />
                                                <div>
                                                    <span>{t("Phone")}</span>
                                                    <h6>{User?.phone}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div className="card">
                            <div className="card-header">
                                <h2>{t("Address")}</h2>
                                <div>

                                    {shows === true ? "" : <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        setshows(true)
                                    }}>

                                        <MdEdit className="me-1" /> {t("Edit")}
                                    </a>}

                                </div>
                            </div>
                            <div className="card-body">
                                {shows &&
                                    (<Form onSubmit={handlesumbit}>
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label className="fs-14">
                                                        {t("City")} <span className="text-danger">*</span>
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        className="from-control-lg"
                                                       placeholder={t("City")}
                                                        name="city"
                                                        value={formData.city}
                                                        onChange={handlechange}
                                                    />
                                                </Form.Group>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label className="fs-14">
                                                        {t("Country")} <span className="text-danger">*</span>
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        className="from-control-lg"
                                                        placeholder={t("Country")}
                                                        name="Country"
                                                        value={formData.Country}
                                                        onChange={handlechange}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-lg-6">
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label className="fs-14">
                                                        {t("State")} <span className="text-danger">*</span>
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        className="from-control-lg"
                                                        placeholder={t("State")}
                                                        name="State"
                                                        value={formData.State}
                                                        onChange={handlechange}
                                                    />
                                                </Form.Group>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label className="fs-14">
                                                        {t("ZipCode")} <span className="text-danger">*</span>
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        className="from-control-lg"
                                                        placeholder={t("ZipCode")}
                                                        name="Code"
                                                        value={formData.Code}
                                                        onChange={handlechange}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-lg-12">
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label className="fs-14">
                                                        {t("Address")} <span className="text-danger">*</span>
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        className="from-control-lg"
                                                        placeholder={t("Address")}
                                                        name="Address"
                                                        value={formData.Address}
                                                        onChange={handlechange}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <button
                                                className="btn btn-primary ms-auto w-auto float-end"
                                                type="submit"
                                            >
                                                {t("Saves")}
                                            </button>
                                        </div>
                                    </Form>)}

                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    );

}

export default Profile;
