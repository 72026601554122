import React, { useEffect, useState } from 'react';
import { MdOutlineForwardToInbox } from "react-icons/md";
import passangerImage from "../../assets/images/empty_placeholder_leafs.png";
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import Swal from "sweetalert2";
import { apiRequest } from '../../ApiService/Allapi';
import { useTranslation } from "react-i18next";
function Passengers() {
   const { t, i18n } = useTranslation()
  const [FormData, setFormData] = useState({
    f_name: "",
    l_name: "",
    email: "",
    country_code: "",
    phone: "",
    gender: "",
    dob: ""
  });

  const [data, setData] = useState([]);

  // Define options for gender and country code
  const genderOptions = [
    { value: '1', label: t("Male") },
    { value: '2', label: t("Female") },
  ];

  const countryCodeOptions = [
    { value: '+91', label:t("India") },
    { value: '+101', label: t("USA") }
  ];

  // States for storing selected options
  const [selectedGender, setSelectedGender] = useState(null);  // For gender
  const [selectedCountryCode, setSelectedCountryCode] = useState(null);  // For phone code

  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    
    setFormData({
      f_name: '',
      email: '',
      country_code: '',
      phone: '',
      l_name: '',
      dob: '',
      gender: ''
    })
    setShow(true)
  };
  const handleshowss = (id, f_name, l_name, email, country_code, phone, gender, dob) => {
    FormData.id = id
    FormData.email = email
    FormData.country_code = country_code
    FormData.phone = phone
    FormData.f_name = f_name
    FormData.l_name = l_name
    FormData.dob = dob
    FormData.gender = gender
    setShow(true)
  };

  const handleChange = (e) => {
    setFormData({ ...FormData, [e.target.name]: e.target.value });
  };

 const langs = localStorage.getItem("i18nextLng") ?? "en";
        const [lang, setLang] = useState(langs);


  useEffect(() => {
    const fetchProfile = async () => {
      setLoading(true);
      try {
        const response = await apiRequest(`get-passenger?lang=${lang}`, "GET", null);
        if (response.status && response.data) {
          setData(response.data); // Set the passengers data
          setFormData({
            f_name: response.data.f_name,
            email: response.data.email || "",
            country_code: response.data.country_code || "",
            phone: response.data.phone || "",
            l_name: response.data.l_name || "",
            dob: response.data.dob || "",
            gender: response.data.gender || "",
          });
          // Set initial selected gender and country code
          setSelectedGender(genderOptions.find(option => option.value === response.data.gender));
          setSelectedCountryCode(countryCodeOptions.find(option => option.value === response.data.country_code));
          Swal.fire({
            title: response.message,
            icon: "success"
          });
        } else {
          Swal.fire({
            title: response.message,
            icon: "error"
          });
        }
      } catch (error) {
        console.log('error', error.message)
      } finally {
        setLoading(false);
      }
    };
    fetchProfile();
  }, [lang]);

  const Handlesubmit = async (e) => {
    e.preventDefault();
    const body = {
      id: FormData.id,
      f_name: FormData.f_name,
      email: FormData.email,
      country_code: selectedCountryCode?.value,  // Get the selected country code
      phone: FormData.phone,
      l_name: FormData.l_name,
      dob: FormData.dob,
      gender: selectedGender?.value,  // Get the selected gender
    };

    try {
      const result = await apiRequest(`add-passenger?lang=${lang}`, "POST", body);
      if (result.status === true) {
        
        Swal.fire({
          title: result.message,
          icon: "success"
        });
        setFormData({
          f_name: "",
          l_name: "",
          email: "",
          country_code: "",
          phone: "",
          gender: "",
          dob: ""
        }); // Reset the form

        setData(result.passengers);

        setSelectedGender(null); 
        setSelectedCountryCode(null);
        handleClose(); 
      } else {
        Swal.fire({
          title: result.message,
          icon: "error"
        });
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "error"
      });
      console.error(error);
    } finally {
      setLoading(false); 
    }
  };

  const token = localStorage.getItem("token");

  const deletePassenger = async (id) => {
    try {
      const body = { id }; 
      const headers = { Authorization: `Bearer ${token}` };
  
      const result = await apiRequest(`delete-passenger?lang=${lang}`, "POST", body,headers);
  
      if (result.status === true) {
        Swal.fire({
          title: "Passenger Deleted Successfully!",
          icon: "success",
        });
        window.location.reload();
      } else {
        Swal.fire({
          title: "Deletion Failed",
          text: result.message,
          icon: "error",
        });
      }
    } catch (error) {
      console.error("Error deleting passenger:", error);
      Swal.fire({
        title: "Error",
        text: "Something went wrong!",
        icon: "error",
      });
    }
  };
  
  return (
    <>
      <div className='passengers'>
        {/* Check if data is empty or null */}
        {data.length === 0 ? (
          <div className='card'>
            <div className='card-body'>
              <div className='pass_box'>
                <span>{t("Passengersadded")}</span>
                <div className='passenger-media my-4'>
                  <img src={passangerImage} alt="No Passengers" />
                </div>
                <Button variant="primary" onClick={handleShow}>{t("AddNew")}</Button>
              </div>
            </div>
          </div>
        ) : (
          <div className='card'>
            <div className='card-header'>
              <h4 className=''>{t("MyPassengers")}</h4>
              <a href="#" className='text-orange' onClick={() => handleShow()}>+ {t("AddNew")}</a>
            </div>
            <div className='card-body'>
              {/* Render passenger data if available */}
              {data.map((item, index) => {
                return (
                  <div className='pass' key={index}>
                    <div className='profile_media_1'>
                      <span>{item.f_name[0]}</span> {/* Display first letter of the name as placeholder */}
                    </div>
                    <div className='pass_content'>
                      <h6>{item.f_name} - {item.gender === "1" ? "Male" : "Female"} <span>({item.dob})</span></h6>
                      <div className="email-box contact-box__item">
                        <MdOutlineForwardToInbox className='me-2' />
                        <span>{item.email}</span>
                      </div>
                    </div>
                    <div className='ms-auto'>
                      <Button variant="secondary" style={{ width: "115px" }} onClick={() => handleshowss(item.id, item.f_name, item.l_name, item.email, item.country_code, item.phone, item.gender, item.dob)}>{t("Edit")}</Button>&nbsp;
                      <Button variant="danger" style={{ width: "80px" }} onClick={()=>deletePassenger(item.id)}>{t("Delete")}</Button>

                    </div>
                    <hr />
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>




      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{t("AdPassenger")}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form onSubmit={Handlesubmit}>
            <div className='row'>
              <div className='col-lg-6'>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Control type="hidden" className='from-control-lg' placeholder="id" name="id" value={FormData.id} />
                  <Form.Label>{t("Name")} <span className='text-danger'>*</span></Form.Label>
                  <Form.Control type="text" className='from-control-lg' placeholder={t("Names")} name="f_name" value={FormData.f_name} onChange={handleChange} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>{t("Email")} <span className='text-danger'>*</span></Form.Label>
                  <Form.Control type="email" className='from-control-lg'  placeholder={t("Emails")} name="email" value={FormData.email} onChange={handleChange} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>{t("Gender")} <span className='text-danger'>*</span></Form.Label>
                  <Select
                    options={genderOptions}
                    placeholder={t("Gender")}
                    value={selectedGender} // Use the state to bind the selected value
                    onChange={setSelectedGender} // Update selected gender on change
                  />
                </Form.Group>
              </div>
              <div className='col-lg-6'>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>{t("LastName")}<span className='text-danger'>*</span></Form.Label>
                  <Form.Control type="text" className='from-control-lg'  placeholder={t("LastName")} name='l_name' value={FormData.l_name} onChange={handleChange} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>{t("Phone")} <span className='text-danger'>*</span></Form.Label>
                  <div className='row'>
                    <div className='col-lg-4'>
                      <Select
                        options={countryCodeOptions}
                        value={selectedCountryCode} // Use the state to bind the selected value
                        onChange={setSelectedCountryCode} // Update selected country code on change
                        placeholder="+91"
                      />
                    </div>
                    <div className='col-lg-8'>
                      <Form.Control type="number" className='from-control-lg' placeholder={t("PhoneNumber")} name='phone' value={FormData.phone} onChange={handleChange} />
                    </div>
                  </div>
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>{t("Birth")}<span className='text-danger'>*</span></Form.Label>
                  <Form.Control type="date" className='from-control-lg' name='dob' value={FormData.dob} onChange={handleChange} />
                </Form.Group>
              </div>
            </div>
            <button className='btn btn-primary mt-auto' type='submit'>{t("SAVEDETAILS")}</button>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default Passengers;
