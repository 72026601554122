import React, { useState } from 'react';
import "../assets/style.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import Lottie from "lottie-react";
import groovyWalkAnimation from "../assets/images/world.json";
import teamImage from "../assets/images/team/chitra_maam.jpg";
import team2Image from "../assets/images/about/abhishek_puri.jpg";
import team3Image from "../assets/images/about/rajdeep.jpg";
import team4Image from "../assets/images/about/girdhar.jpg";
import team5Image from "../assets/images/about/chitransh.jpg";
import team6Image from "../assets/images/about/ram.jpg";
import team1Image from "../assets/images/team/tarun.jpg";
import cityImage from "../assets/images/tajmal.svg";
import city1Image from "../assets/images/dubai.svg";
import city2Image from "../assets/images/usa.svg";
import takeImage from "../assets/images/takeoff.jpg";
import tripImage from "../assets/images/Inspirations/trip1.jpg";
import founderImage from "../assets/images/about/thrillophilia_founders.png";
import trip2Image from "../assets/images/Inspirations/trip2.jpg";
import trip3Image from "../assets/images/Inspirations/trip3.jpg";
import spotImage from "../assets/images/The-Week-Logo-Big.png";
import mintImage from "../assets/images/mint.svg";
import teleImage from "../assets/images/telegraph.svg";
import gallaryImage from "../assets/images/gallary/gallary.jpg";
import gallary1Image from "../assets/images/gallary/01_gallary.jpg";
import gallary2Image from "../assets/images/gallary/02_gallary.jpg";
import gallary3Image from "../assets/images/gallary/03_gallary.jpg";
import lifeImage from "../assets/images/4_Pic.jpg";
import singlelogoImage from "../assets/images/logo-footer.png";
import linkImage from "../assets/images/link.svg";
import { Swiper, SwiperSlide } from 'swiper/react';
import Teamfirst from "../assets/images/about/abhishek.jpg";
import yearImage from "../assets/images/2011.png"
import { useTranslation } from "react-i18next";
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';
import { Pagination } from 'swiper/modules';





const About = () => {
    const { t, i18n } = useTranslation();
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const link = 'javascript:void(0)';


    return (
        <>
            <section>
                <div className='container'>
                    <div className='banner-content'>
                        <div className='row'>
                            <div className='col-lg-7'>
                                <span className='about_title'>{t("Tirthyatra")}</span>
                                <h1 className="about_heding">{t("Shapingthe")} <span className="gradend-light">{t("Shapingth")}</span></h1>
                                <p classname="about_para">{t("Shapingths")}</p>
                            </div>
                            <div className='col-lg-5'>
                                <div className="lotti-box">
                                    <Lottie animationData={groovyWalkAnimation} loop={true} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* our mission */}
            <section className='bg-light section-padding c-padding'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6 align-self-center '>
                            <h2 className='section-title'>{t("Our")} <span className='section-span'>{t("Mission")}</span></h2>
                            <p className="contentText">{t("Missions")}</p>
                        </div>
                        <div className='col-lg-6 '>
                            <div className="position-relative">
                                <div className='mission-media'>
                                    <img src={founderImage} alt="Example" />
                                </div>
                                <div className="mission_co_founder">
                                    <h6> {t("Namese")}</h6>
                                    <small>{t("ceo")}</small>

                                </div>
                                <span className="mission_span">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="46" height="19" viewBox="0 0 46 19" fill="none"><path d="M45 18.9346L40.4184 15.4215L45.7517 13.2103L45 18.9346ZM0.635582 10.5926C7.89481 2.86533 16.6005 -0.242682 24.548 0.648061C32.4959 1.53884 39.6238 6.42323 43.7227 14.552L42.8298 15.0023C38.8743 7.15779 32.0302 2.49291 24.4366 1.64184C16.8427 0.790726 8.4374 3.74827 1.36442 11.2773L0.635582 10.5926Z" fill="#F37022"></path></svg>
                                </span>
                                <div className="mission_co_founder style-1">
                                    <h6>{t("Namess")}</h6>
                                    <small>{t("co")}</small>

                                </div>
                                <span className="mission_span-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="27" viewBox="0 0 40 27" fill="none"><path d="M-0.000495585 22.0429L5.76909 21.8302L3.06855 26.9332L-0.000495585 22.0429ZM39.5031 0.196717C38.834 10.7778 34.1062 18.7212 27.4142 23.0999C20.7219 27.4789 12.1162 28.2578 3.77688 24.6061L4.178 23.6901C12.2256 27.2141 20.4727 26.4469 26.8667 22.2631C33.261 18.0792 37.8531 10.4432 38.5051 0.133604L39.5031 0.196717Z" fill="#F37022"></path></svg>
                                </span>


                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Architects-section-strat */}
            <section className="section-padding-1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h2 className='sub-section-title'>{t("Meetthe")} <span style={{ color: "#fcaf17" }} >{t("Architects")}</span></h2>
                            <p className='about_para fw-500'>{t("powers")}<span style={{ color: "#f47625", fontSize: '17px', fontWeight: '600' }}> 800+</span>{t("powerss")}</p>
                        </div>
                        <div className="col-lg-12">
                            <div className="founder-box">
                                {/* team_start */}
                                <div className="team">
                                    <div className="team-media">
                                        <img src={teamImage} alt="Example" />
                                    </div>
                                    <div className='team_content'>
                                        <h6>{t("Namese")}</h6>
                                        <p>{t("ChitraGurnaniDag")}</p>
                                        <small>{t("ceo")}</small>

                                    </div>
                                </div>
                                <div className="team">
                                    <div className="team-media">
                                        <img src={Teamfirst} alt="Example" />
                                    </div>
                                    <div className='team_content'>
                                        <h6>{t("Namess")} </h6>
                                        <p>{t("IdeaBox")}</p>
                                        <small>{t("co")}</small>

                                    </div>
                                </div>
                                <div className="team">
                                    <div className="team-media">
                                        <img src={team1Image} alt="Example" />
                                    </div>
                                    <div className='team_content'>
                                        <h6>{t("TarunDadlani")} </h6>
                                        <p>{t("TarunDadlan")}</p>
                                        <small>{t("TarunDadlans")}</small>

                                    </div>
                                </div>
                                <div className="team">
                                    <div className="team-media">
                                        <img src={team2Image} alt="Example" />
                                    </div>
                                    <div className='team_content'>
                                        <h6>{t("AbhishekPuri")} </h6>
                                        <p>{t("AbhishekPur")}</p>
                                        <small>{t("TarunDadlans")} </small>

                                    </div>
                                </div>
                                <div className="team">
                                    <div className="team-media">
                                        <img src={team3Image} alt="Example" />
                                    </div>
                                    <div className='team_content'>
                                        <h6>{t("RajdeepMandrekar")}</h6>
                                        <p>{t("RajdeepMandreka")}</p>
                                        <small>{t("RajdeepMandrekas")}</small>

                                    </div>
                                </div>
                                <div className="team">
                                    <div className="team-media">
                                        <img src={team4Image} alt="Example" />
                                    </div>
                                    <div className='team_content'>
                                        <h6>{t("GirdharSinghSodha")}</h6>
                                        <p>{t("GirdharSinghSodhas")}</p>
                                        <small>{t("GirdharSinghSodhasa")}</small>

                                    </div>
                                </div>
                                <div className="team">
                                    <div className="team-media">
                                        <img src={team6Image} alt="Example" />
                                    </div>
                                    <div className='team_content'>
                                        <h6>{t("RamRatanMishra")}</h6>
                                        <p>{t("RamRatanMishraa")}</p>
                                        <small>{t("RamRatanMishr")}</small>

                                    </div>
                                </div>
                                <div className="team">
                                    <div className="team-media">
                                        <img src={team5Image} alt="Example" />
                                    </div>
                                    <div className='team_content'>
                                        <h6>{t("ChitranshSingh")}</h6>
                                        <p>{t("ChitranshSing")}</p>
                                        <small>{t("ChitranshSin")}</small>

                                    </div>
                                </div>
                                <div className="team">
                                    <div className="team-media placeholder_team">
                                        <h6>{t("Seeyourselfhere")}</h6>
                                    </div>
                                    <div className='team_content'>
                                        <button className="btn btn-about-white">{t("JoinUs")}</button>

                                    </div>
                                </div>
                                {/* team_end */}

                            </div>
                        </div>
                    </div>


                </div>
            </section>
            <div className="Divider_divider__yaBCO  "></div>
            {/* hiring-section-start */}
            <section className="section-padding pt-0">
                <div className="container">
                    <div className="hiring-titk">
                        <h1>{t("Innovation")}</h1>
                        <p>{t("guide")}</p>
                    </div>
                </div>
            </section>
            {/* hiring-section_end */}
            {/* life_section_start */}
            <div class="ThrillophiliaExpeditionSection_thrilloExpeditionContainer__FcRMv">
                <div class="container">
                    <div class="ThrillophiliaExpeditionSection_heading__oVZF6">
                        <div class="MultiColorHeading_multiColorHeadingContainer__QjlxL"><span class="MultiColorHeading_firstText__xf0Wa ">{t("omshiv")}</span><span class="MultiColorHeading_secondText__6QtSP ">{t("Thrillophilia")}</span></div><span class="MultiColorHeading_subheading__Gk1ie ">{t("Thrillophili")}</span></div>
                    <div
                        class="ThrillophiliaExpeditionSection_expeditionItemContainer__2_Iw0 ">
                        <div class="ThrillophiliaExpeditionSection_progressMeterContainer__CGD06"><span class="ThrillophiliaExpeditionSection_yearNumber___c4pg ThrillophiliaExpeditionSection_normalNumber__yR8ck ThrillophiliaExpeditionSection_mobileHidden__P3SvI">2011</span>
                            <div class="ThrillophiliaExpeditionSection_bigDotContainer__Rk4EJ ThrillophiliaExpeditionSection_normalDot__AblcJ">
                                <div class="ThrillophiliaExpeditionSection_popperDot__272nN "></div>
                            </div>
                            <div class="ThrillophiliaExpeditionSection_dottedLine__xfbwX">
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_verticalBar__IsiZQ" ></div>
                            </div>
                        </div>
                        <div class="ThrillophiliaExpeditionSection_imageSectionContainer__2KT_N">
                            <div class="ThrillophiliaExpeditionSection_imagePoster__Mm10g"><span><img src={yearImage} class="gm-lazy gm-added gm-observing gm-loaded gm-observing-cb" /></span></div>
                        </div>
                        <div class="ThrillophiliaExpeditionSection_contentSectionContainer__A9al2">
                            <div class="ThrillophiliaExpeditionSection_firstTitleContent__n3WKk">
                                <div class="MultiColorHeading_multiColorHeadingContainer__QjlxL"><span class="MultiColorHeading_firstText__xf0Wa ThrillophiliaExpeditionSection_expedetionContentTitle__67FXW">{t("Igniting")} </span><span class="MultiColorHeading_secondText__6QtSP ThrillophiliaExpeditionSection_expedetionContentTitle__67FXW">{t("Vision")}</span></div>
                                <div
                                    class="MultiColorHeading_multiColorHeadingContainer__QjlxL"><span class="MultiColorHeading_firstText__xf0Wa ThrillophiliaExpeditionSection_expedetionContentTitle__67FXW">{t("Infusing")}</span><span class="MultiColorHeading_secondText__6QtSP ThrillophiliaExpeditionSection_expedetionContentTitle__67FXW">{t("omshiv")}</span></div>
                            </div><span class="ThrillophiliaExpeditionSection_expedetionDescription__w4mWH">{t("launched")}</span></div>
                    </div>
                    <div class="ThrillophiliaExpeditionSection_expeditionItemContainer__2_Iw0 ThrillophiliaExpeditionSection_invertedExpedetion__tIT3K">
                        <div class="ThrillophiliaExpeditionSection_progressMeterContainer__CGD06"><span class="ThrillophiliaExpeditionSection_yearNumber___c4pg ThrillophiliaExpeditionSection_normalNumber__yR8ck ThrillophiliaExpeditionSection_mobileHidden__P3SvI">2014</span>
                            <div class="ThrillophiliaExpeditionSection_bigDotContainer__Rk4EJ ThrillophiliaExpeditionSection_normalDot__AblcJ">
                                <div class="ThrillophiliaExpeditionSection_popperDot__272nN "></div>
                            </div>
                            <div class="ThrillophiliaExpeditionSection_dottedLine__xfbwX">
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_verticalBar__IsiZQ"></div>
                            </div>
                        </div>

                        <div class="ThrillophiliaExpeditionSection_contentSectionContainer__A9al2"><span class="ThrillophiliaExpeditionSection_expedetionTitle__cs_DK">{t("Investments")} &amp; {t("Breakthroughs")}</span><span class="ThrillophiliaExpeditionSection_expedetionDescription__w4mWH">{t("significant")}</span></div>
                        <div class="ThrillophiliaExpeditionSection_imageSectionContainer__2KT_N">
                            <div class="ThrillophiliaExpeditionSection_imagePoster__Mm10g"><span><img src={yearImage} class="gm-lazy gm-added gm-observing gm-loaded gm-observing-cb" /></span></div>
                        </div>
                    </div>

                    <div class="ThrillophiliaExpeditionSection_expeditionItemContainer__2_Iw0 ">
                        <div class="ThrillophiliaExpeditionSection_progressMeterContainer__CGD06"><span class="ThrillophiliaExpeditionSection_yearNumber___c4pg ThrillophiliaExpeditionSection_normalNumber__yR8ck ThrillophiliaExpeditionSection_mobileHidden__P3SvI">2017</span>
                            <div class="ThrillophiliaExpeditionSection_bigDotContainer__Rk4EJ ThrillophiliaExpeditionSection_normalDot__AblcJ">
                                <div class="ThrillophiliaExpeditionSection_popperDot__272nN "></div>
                            </div>
                            <div class="ThrillophiliaExpeditionSection_dottedLine__xfbwX">
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_verticalBar__IsiZQ" ></div>
                            </div>
                        </div>
                        <div class="ThrillophiliaExpeditionSection_imageSectionContainer__2KT_N">
                            <div class="ThrillophiliaExpeditionSection_imagePoster__Mm10g"><span><img src={yearImage} class="gm-lazy gm-added gm-observing gm-loaded gm-observing-cb" /></span></div>
                        </div>
                        <div class="ThrillophiliaExpeditionSection_contentSectionContainer__A9al2"><span class="ThrillophiliaExpeditionSection_expedetionTitle__cs_DK">{t("Recognition")}</span><span class="ThrillophiliaExpeditionSection_expedetionDescription__w4mWH">{t("destinations")}</span></div>
                    </div>
                    <div class="ThrillophiliaExpeditionSection_expeditionItemContainer__2_Iw0 ThrillophiliaExpeditionSection_invertedExpedetion__tIT3K">
                        <div class="ThrillophiliaExpeditionSection_progressMeterContainer__CGD06"><span class="ThrillophiliaExpeditionSection_yearNumber___c4pg ThrillophiliaExpeditionSection_normalNumber__yR8ck ThrillophiliaExpeditionSection_mobileHidden__P3SvI">2020</span>
                            <div class="ThrillophiliaExpeditionSection_bigDotContainer__Rk4EJ ThrillophiliaExpeditionSection_normalDot__AblcJ">
                                <div class="ThrillophiliaExpeditionSection_popperDot__272nN "></div>
                            </div>
                            <div class="ThrillophiliaExpeditionSection_dottedLine__xfbwX">
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_dotItems__CBVI_"></div>
                                <div class="ThrillophiliaExpeditionSection_verticalBar__IsiZQ" ></div>
                            </div>
                        </div>

                        <div class="ThrillophiliaExpeditionSection_contentSectionContainer__A9al2"><span class="ThrillophiliaExpeditionSection_expedetionTitle__cs_DK">{t("Thriving")}</span><span class="ThrillophiliaExpeditionSection_expedetionDescription__w4mWH">{t("embarked")}</span></div>
                        <div class="ThrillophiliaExpeditionSection_imageSectionContainer__2KT_N">
                            <div class="ThrillophiliaExpeditionSection_imagePoster__Mm10g"><span><img src={yearImage} class="gm-lazy gm-added gm-observing gm-loaded gm-observing-cb" /></span></div>
                        </div>
                    </div>
                    <div class="ThrillophiliaExpeditionSection_expeditionItemContainer__2_Iw0 ">
                        <div class="ThrillophiliaExpeditionSection_progressMeterContainer__CGD06"><span class="ThrillophiliaExpeditionSection_yearNumber___c4pg ThrillophiliaExpeditionSection_normalNumber__yR8ck ThrillophiliaExpeditionSection_mobileHidden__P3SvI">2023</span>
                            <div class="ThrillophiliaExpeditionSection_bigDotContainer__Rk4EJ ThrillophiliaExpeditionSection_normalDot__AblcJ">
                                <div class="ThrillophiliaExpeditionSection_popperDot__272nN "></div>
                            </div>
                        </div>
                        <div class="ThrillophiliaExpeditionSection_imageSectionContainer__2KT_N">
                            <div class="ThrillophiliaExpeditionSection_imagePoster__Mm10g"><span><img src={yearImage} class="gm-lazy gm-added gm-observing gm-loaded gm-observing-cb" /></span></div>
                        </div>
                        <div class="ThrillophiliaExpeditionSection_contentSectionContainer__A9al2"><span class="ThrillophiliaExpeditionSection_expedetionTitle__cs_DK">{t("Autonomy")}</span><span class="ThrillophiliaExpeditionSection_expedetionDescription__w4mWH">{t("unprecedented")}</span></div>
                    </div>
                </div>
            </div>
            <section className='section-padding'>
                <div className='container'>
                    <div className="main_life_box">
                        <div className="life_box">
                            <img src={singlelogoImage} alt="images" />
                            <div>
                                <h2>{t("Tirthyatra")} </h2>
                                <a href={link} target="_blank" rel="noopener noreferrer">{t("Tirthyatra")} </a>
                            </div>


                        </div>
                        <div className="life_total-1">
                            <Swiper
                                slidesPerView={4}
                                spaceBetween={20}
                                pagination={{
                                    clickable: true,
                                }}
                                modules={[Pagination]}
                                className="mySwiper"
                            >
                                <SwiperSlide>
                                    <div className="life_media">
                                        <img src={lifeImage} style={{ width: "100%" }} />
                                        <div className="copy_link">
                                            <img src={linkImage} />
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="life_media">
                                        <img src={lifeImage} style={{ width: "100%" }} />
                                        <div className="copy_link">
                                            <img src={linkImage} />
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="life_media">
                                        <img src={lifeImage} style={{ width: "100%" }} />
                                        <div className="copy_link">
                                            <img src={linkImage} />
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="life_media">
                                        <img src={lifeImage} style={{ width: "100%" }} />
                                        <div className="copy_link">
                                            <img src={linkImage} />
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="life_media">
                                        <img src={lifeImage} style={{ width: "100%" }} />
                                        <div className="copy_link">
                                            <img src={linkImage} />
                                        </div>
                                    </div>
                                </SwiperSlide>

                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>
            {/* life_section_end */}
            {/* gallary_section_start */}
            <section className='section-padding'>
                <div className="container">
                    <div className="mb-4">
                        <h2 className="sub-section-title mb-0">{t("Tirthyatra")} <span className="text-warning">{t("PictureGallery")}
                        </span></h2>

                    </div>
                    <div className="row g-2">
                        <div className="col-lg-6 col-md-6">
                            <div className="gallary_box-1">

                                <img src={gallaryImage} className="w-100 rounded" alt="" />

                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="row g-2">
                                <div className="col-lg-6 col-md-6 col-6">
                                    <div className="gallary_box">
                                        <img src={gallary1Image} className="w-100 rounded" alt="" />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-6">
                                    <div className="gallary_box">
                                        <img src={gallary2Image} className="w-100 rounded" alt="" />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-6">
                                    <div className="gallary_box">
                                        <img src={gallary3Image} className="w-100 rounded" alt="" />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-6">
                                    <div className="gallary_box">
                                        <img src={gallaryImage} className="w-100 rounded" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* gallary_section_end */}


            {/* Architects-section-end */}
            <div className='seprator-gradend'></div>
            {/* spot_light-strat */}
            <section className="section-padding pt-0">
                <div className="container">
                    <div>
                        <h2 className="sub-section-title mb-0"><span className="text-warning">{t("Spotlight")}</span></h2>
                        <p className="about_para fw-500 mt-0 mb-4">{t("Adventures")}</p>
                    </div>
                    <Swiper
                        slidesPerView={3}
                        spaceBetween={30}
                        pagination={{
                            clickable: true,
                        }}
                        breakpoints={{
                            // Small devices (mobile)
                            320: {
                                slidesPerView: 1,
                                spaceBetween: 5,
                            },
                            // Medium devices (tablet)
                            600: {

                                slidesPerView: 1,
                                spaceBetween: 20,
                            },
                            640: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                            },
                            // Large devices (desktop)
                            1024: {
                                slidesPerView: 3,
                                spaceBetween: 20,
                            },
                            // Extra large devices
                            1200: {
                                slidesPerView: 3,
                                spaceBetween: 20,
                            },
                            // Extra large devices
                            1440: {
                                slidesPerView: 3,
                                spaceBetween: 20,
                            },
                        }}
                        modules={[Pagination]}
                        className="mySwiper"
                    >
                        <SwiperSlide>
                            <div className="card spot_card bg-light">
                                <div className="card-header">
                                    <img src={spotImage} style={{ width: "40%" }} />
                                </div>
                                <div className="card-body">
                                    <h6>{t("Funding")}</h6>
                                    <p>{t("Fundings")}</p>
                                </div>
                            </div>

                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="card spot_card bg-light">
                                <div className="card-header">
                                    <img src={mintImage} style={{ width: "40%" }} />
                                </div>
                                <div className="card-body">
                                    <h6>{t("Funding")}</h6>
                                    <p>{t("Fundings")}</p>
                                </div>
                            </div>

                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="card spot_card bg-light">
                                <div className="card-header">
                                    <img src={teleImage} style={{ width: "70%" }} />
                                </div>
                                <div className="card-body">
                                    <h6>{t("Funding")}</h6>
                                    <p>{t("Fundings")}</p> </div>
                            </div>

                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="card spot_card bg-light">
                                <div className="card-header">
                                    <img src={spotImage} style={{ width: "40%" }} />
                                </div>
                                <div className="card-body">
                                    <h6>{t("Funding")}</h6>
                                    <p>{t("Fundings")}</p> </div>
                            </div>

                        </SwiperSlide>


                    </Swiper>

                </div>
            </section>

            {/* Inspirations-end */}

            {/* Inspirations-strat */}
            <section className="section-padding pt-0">
                <div className="container">
                    <div>
                        <h2 className="sub-section-title mb-0">{t("Insights")} <span className="text-warning">{t("Impact")}</span></h2>
                        <p className="about_para fw-500 mt-0 mb-4">{t("Stories")}</p>
                    </div>
                    <Swiper
                        style={{
                            '--swiper-navigation-color': '#fff',
                            '--swiper-pagination-color': '#fff',
                        }}
                        loop={true}
                        spaceBetween={10}
                        slidesPerView={'2'}
                        centeredSlides={true}
                        navigation={true}
                        thumbs={{ swiper: thumbsSwiper }}
                        modules={[FreeMode, Navigation, Thumbs]}
                        className="mySwiper2"
                    >
                        <SwiperSlide>
                            <img src={tripImage} className="w-100 rounded " alt="" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={trip2Image} className="w-100 rounded" alt="" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={trip3Image} className="w-100 rounded" alt="" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={tripImage} className="w-100 rounded" alt="" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={trip2Image} className="w-100 rounded" alt="" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={trip3Image} className="w-100 rounded" alt="" />
                        </SwiperSlide>


                    </Swiper>
                    <Swiper
                        onSwiper={setThumbsSwiper}
                        loop={true}
                        spaceBetween={10}
                        slidesPerView={1}
                        freeMode={true}
                        watchSlidesProgress={true}
                        modules={[FreeMode, Navigation, Thumbs]}
                        className="mySwiper"
                    >
                        <SwiperSlide>
                            <div className='thumb_content'>
                                <h2>{t("Specially")}</h2>
                                <p className="about_para fw-500">{t("outperform")}</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='thumb_content'>
                                <h2>{t("Specially")}</h2>
                                <p className="about_para fw-500">{t("outperform")}</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='thumb_content'>
                                <h2>{t("Specially")}</h2>
                                <p className="about_para fw-500">{t("outperform")}</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='thumb_content'>
                                <h2>{t("Specially")}</h2>
                                <p className="about_para fw-500">{t("outperform")}</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='thumb_content'>
                                <h2>{t("Specially")}</h2>
                                <p className="about_para fw-500">{t("outperform")}</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='thumb_content'>
                                <h2>{t("Specially")}</h2>
                                <p className="about_para fw-500">{t("outperform")}</p>
                            </div>
                        </SwiperSlide>


                    </Swiper>

                </div>
            </section>

            {/* Inspirations-end */}
            <div className='seprator-gradend'></div>
            {/* Takeoffs_start */}
            <section className="">
                <div className="container">
                    <div>
                        <h2 className='sub-section-title'>{t("Building")}  <span className="text-warning">53 {("Takeoffs")} (53TO)</span> </h2>
                        <p className="about_para fw-500">{t("Heightsm")}</p>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-6">
                            <div className="takeoff_media mt-4">
                                <img src={takeImage} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="takeoff_content">
                                <ul className="mt-4">
                                    <li><span className="text-warning coustom_about_title">53 {t("Takeoffs")} (53TO)</span>, {t("focusing")}.</li>
                                    <li>{t("consistent")}.</li>
                                    <li>53TO {t("conglomerate")} 2027.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Takeoffs_end */}
            <div className='seprator-gradend'></div>
            <section className="section-padding pt-0">
                <div className="container">
                    <div className="section-h">
                        <h2>{t("OurPresence")}</h2>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="city_box">
                                    <div className="city_box_media">
                                        <img src={cityImage} alt="taj" />
                                    </div>
                                    <div className="city_content">
                                        <h2>{t("JaipurIndia")}</h2>
                                        <p>{t("JaipurIndias")}, 302022</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="city_box">
                                    <div className="city_box_media">
                                        <img src={city1Image} alt="taj" />
                                    </div>
                                    <div className="city_content">
                                        <h2>{t("UAE")}</h2>
                                        <p>{t("UAe")}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="city_box">
                                    <div className="city_box_media">
                                        <img src={city2Image} alt="taj" />
                                    </div>
                                    <div className="city_content">
                                        <h2></h2>
                                        <p> {t("Delawar")}, 19805-1270</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <section>
                <div className='container'>
                    <div>
                        From sacred shrines to lands unknown,
                        We take you where the soul has flown.
                        Be it tirth yatra or distant shore,
                        Every journey, we make pure.

                    </div>
                    <b>Comfort, care, and blessings divine,
                        Your travels, our sacred design.
                    </b>
                    <div>
                        At Om Shiv Shankar Tirth Yatra, we are committed to providing enriching and hassle-free travel experiences. Whether you seek spiritual journeys to sacred
                        sites or international tours for leisure and exploration, we ensure every trip is memorable, comfortable, and fulfilling.
                    </div>
                    <div>
                        We specialize in pilgrimage tours to holy destinations like Char Dham, Kailash Mansarovar, Vaishno Devi, Kashi, and more, guiding
                        devotees with care and devotion. Beyond spiritual travel, we also offer international trips to breathtaking destinations, allowing travelers to explore the beauty of the world with ease and convenience.

                    </div>
                    <div>
                        the beauty of the world with ease and convenience.
                        With a focus on safety, comfort, and customer satisfaction, we handle everything from transportation and accommodation to
                        guided experiences, ensuring you can travel worry-free. Join us for a journey of faith, adventure, and unforgettable memories.

                    </div>
                    <b>📞 Get in touch with us  </b>
                   
                    <b>9460938009  </b>
                  
                
                </div>
            </section>
            {/* travel_together_strat */}
            <section className="bg-light">
                <div className="join_team ">
                    <h2 className=''>{t("Future")} <span style={{ color: "#fcaf17" }}>{t("TOGETHER")}!</span></h2>
                    <p>{t("ambitious")}!</p>
                    <div className="d-flex align-items-center justify-content-center">
                        <button className="btn-shine">{t("JoinUs")}</button>
                    </div>
                </div>
            </section>
            {/* travel-together_end */}


        </>

    );
};

export default About;
