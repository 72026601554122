import React, { useState } from 'react'
import { FaFacebookF, FaTwitter, FaLinkedin, FaTumblr, FaPinterest, FaInstagram,  FaEnvelope } from "react-icons/fa";
import { FaHome } from "react-icons/fa";
import { FaPhone } from "react-icons/fa";
import { useTranslation } from "react-i18next";
function Contect() {
    const { t, i18n } = useTranslation();
const [forms,setforms]=useState({
    name:"",
    email:"",
    phone:"",
    message:"",
})


const handlechange=(e)=>{
setforms({...forms,[e.target.name]:e.target.value})

}
const handlesumbit=(e)=>{
    e.preventDefault();
}




  return (
   <>
    
    <div class="container">
        <section class="section-padding">
            
            <div class="hiring-titk">
                    <h1>{t("GetInTouch")}</h1>
            </div>
        <div class="row">
         <div class="col-md-6 mb-4">
            <div class="contact-shadow">
               <div class="contact-info-area">
                  <h4 class="title">{t("TirthYatraIndia")}</h4>
                  <div class="contact-content">
                     <p class="contact-para">{t("helps")}</p>
                     {/* <div class="contact-img-wrap travel-img"><img src="https://shivshankartirthyatra.com/assets/images/icons/travling.png" alt="travling" title="travling"/></div>
                     <ul class="contact-info-list">
                        <li><i class="fas fa-home"></i><a rel="noopener noreferrer" href="https://goo.gl/maps/CT9skfV8DU1NcGqGA" target="_blank">233, बंकहंडी, रेलवे रोड ऋषिकेश (देहरादून) उत्तराखंड 249201</a></li>
                        <li><i class="fas fa-phone"></i><a href="tel:01352431668">0135 2431668</a>,<a href="tel:01352431768"> 2431768</a>,<a href="tel:9412050868"> 9412050868</a></li>
                        <li><i class="fas fa-envelope"></i><a href="mailto:shivstyc@gmail.com">shivstyc@gmail.com</a></li>
                     </ul> */}
                     <ul class="contact-info-list">
                              <li><FaHome size={24}/><a rel="noopener noreferrer" href="javasript:void(0);" className='text-dark' target="_blank">233, Bankhandi , Railway Road Rishikesh (Dehradun) Uttarakhand 249201</a></li>
                              <li><FaPhone  size={24}/><a href="tel:0135 2431668" className='text-dark'>0135 2431668</a>,<a href="tel:0135 2431768" className='text-dark'>2431768</a>,<a href="tel:+919412050868" className='text-dark'>9412050868</a></li>
                              <li><FaEnvelope size={24}/><a className='text-dark' href="mailto:shivstyc@gmail.com">shivstyc@gmail.com</a></li>
                           </ul>

                     
    <ul className="social-icon flex space-x-4">
      <li className="facebook">
        <a href="https://www.facebook.com/ShriShivShankarTirthYatra/" rel="noopener" title="Facebook Page of shivshankartirthyatra" target="_blank">
          <FaFacebookF size={20} />
        </a>
      </li>
      <li className="twitter">
        <a href="https://twitter.com/tirthyatraindia" rel="noreferrer" title="Twitter" target="_blank">
          <FaTwitter size={20} />
        </a>
      </li>
      <li className="linkedin">
        <a href="https://www.linkedin.com/in/shivshankartirthyatra/" target="_blank" rel="noopener noreferrer">
          <FaLinkedin size={20} />
        </a>
      </li>
      <li className="instagram">
        <a href="https://www.instagram.com/tirthyatraindia/" target="_blank" rel="noopener noreferrer">
          <FaInstagram size={20} />
        </a>
      </li>
      <li className="tumblr">
        <a href="https://www.tumblr.com/blog/shivshankartirthyatra" target="_blank" rel="noopener noreferrer">
          <FaTumblr size={20} />
        </a>
      </li>
      <li className="pinterest">
        <a href="https://in.pinterest.com/shivshankartirthyatra/" target="_blank" rel="noopener noreferrer">
          <FaPinterest size={20} />
        </a>
      </li>
    </ul>
                  </div>
               </div>
            </div>
         </div>
         <div class="col-md-6 mb-4">
            <div class="contact-shadow">
               <div class="contact-form-content-area">
                  <form class="contact-form" id="footer-form">
                     <div class="row">
                        <div class="col-md-12">
                           <div class="form-group">
                              <input type="text" name="name" aria-label="Name" class="form-control" placeholder={t("Names")} value={forms.name} onChange={handlechange}/>
                           </div>
                        </div>
                        <div class="col-md-12">
                           <div class="form-group">
                              <input type="email" name="email" aria-label="Email" class="form-control" placeholder={t("Emails")} value={forms.email}onChange={handlechange}/>
                           </div>
                        </div>
                        <div class="col-md-12">
                           <div class="form-group">
                              <input type="number" name="phone" aria-label="Phone" class="form-control"  placeholder={t("PhoneNumber")} value={forms.phone} onChange={handlechange}/>
                           </div>
                        </div>
                        <div class="col-md-12">
                           <div class="form-group textarea">
                              <textarea cols="30" rows="3" name="message" aria-label="Message" class="form-control"  placeholder={t("sandesh")} value={forms.message} onChange={handlechange}></textarea>
                           </div>
                        </div>
                        <div class="col-md-12">
                           <button class="theme-button" type="submit" onSubmit={handlesumbit}>{t("Saves")}</button>
                        </div>
                     </div>
                  </form>
               </div>
            </div>
         </div>
      </div>
        </section>
    </div>
   
   
   </>

  )
}

export default Contect