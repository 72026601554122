import React, { useEffect, useState } from 'react';
import "../assets/style.css";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import $ from "jquery";


function TermsandCondition() {
    $(document).ready(function () {
        $(window).scroll(function () {
            if ($(document).scrollTop() > 50) {
                $(".details_tabs").addClass("scroll");

            } else {
                $(".details_tabs").removeClass("scroll");
            }
        });
    });
    return (
        <>
            <div className=''>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='tabs'>

                            <Tabs
                                defaultActiveKey="profile"
                                id="uncontrolled-tab-example"
                                className="mb-3 details_tabs style-1"
                            >
                                {/* <Tab eventKey="home" title="Terms & Conditions"> */}
                                <div className='container'>
                                    <h1>Terms and Conditions</h1>
                                    <small>Last updated: October 27, 2022</small>
                                    <p>This web page represents a legal document that serves as the terms of use for our website (“Terms of Use”), www.thrillophilia.com and any associated mobile application (collectively, “Website”), as owned and operated by 53Take off Pvt limited, Barasingha Private Limited, Thrillophilia Inc., Thrillophilia FZCO or Thrillophilia Travel Solutions (“Thrillophilia”).</p>
                                    <p>This web page represents a legal document that serves as the terms of use for our website (“Terms of Use”), www.thrillophilia.com and any associated mobile application (collectively, “Website”), as owned and operated by 53Take off Pvt limited, Barasingha Private Limited, Thrillophilia Inc., Thrillophilia FZCO or Thrillophilia Travel Solutions (“Thrillophilia”).</p>
                                    <p>Capitalised terms, unless otherwise defined, have the meaning specified within the Definitions section below. This Terms of Use, and other posted guidelines within our Website, (collectively “Legal Terms”), constitute the entire and only agreement between you and Thrillophilia, and supersede all other agreements, representations, warranties and understandings with respect to our Website and the subject matter contained herein.</p>
                                    <p>This web page represents a legal document that serves as the terms of use for our website (“Terms of Use”), www.thrillophilia.com and any associated mobile application (collectively, “Website”), as owned and operated by 53Take off Pvt limited, Barasingha Private Limited, Thrillophilia Inc., Thrillophilia FZCO or Thrillophilia Travel Solutions (“Thrillophilia”).</p>
                                    <p>This web page represents a legal document that serves as the terms of use for our website (“Terms of Use”), www.thrillophilia.com and any associated mobile application (collectively, “Website”), as owned and operated by 53Take off Pvt limited, Barasingha Private Limited, Thrillophilia Inc., Thrillophilia FZCO or Thrillophilia Travel Solutions (“Thrillophilia”).</p>
                                    <p>Capitalised terms, unless otherwise defined, have the meaning specified within the Definitions section below. This Terms of Use, and other posted guidelines within our Website, (collectively “Legal Terms”), constitute the entire and only agreement between you and Thrillophilia, and supersede all other agreements, representations, warranties and understandings with respect to our Website and the subject matter contained herein.</p>
                                    <span className='text-files'>Limited License</span>
                                    <p>Thrillophilia grants you a non-exclusive, non-transferable, revocable licence to access and use our Website strictly in accordance with our Legal Terms. Your use of our Website is solely for the purposes as provided herein.</p>
                                    <span className='text-files'>Limited License</span>
                                    <p>Thrillophilia grants you a non-exclusive, non-transferable, revocable licence to access and use our Website strictly in accordance with our Legal Terms. Your use of our Website is solely for the purposes as provided herein.</p>
                                </div>
                                {/* </Tab> */}



                                <Tab eventKey="profile" title="Privacy Policy">


                                    <div className='container'>
                                        <h1>Privacy Policy – Om Shiv Shankar Tirth Yatra  </h1>

                                        <small>1. Information We Collect </small>
                                        <p>We collect the following types of information when you book a tour or use our services: .</p>
                                        <p> * Personal Details: Name, phone number, email, address, and passport details (for international trips). </p>
                                        <p>* Payment Information: Bank details, credit/debit card information (processed securely).</p>
                                        <p>* Travel Preferences: Special requests, dietary needs, and medical conditions (if disclosed).</p>
                                        <p>* Website Data: IP address, browser type, and pages visited on our website.</p>


                                        <small>2. How We Use Your Information</small>
                                        <p>We use your information for: </p>
                                        <p>* Booking and Managing Tours: Processing reservations, payments, and itinerary updates. </p>
                                        <p>* Communication: Sending tour confirmations, updates, and special offers. </p>
                                        <p>* Legal and Security Reasons: Preventing fraud, ensuring safety, and complying with regulations.</p>

                                        <small>3.Sharing of Information </small>
                                        <p>We do not sell or rent your data. However, we may share information with: </p>
                                        <p>* Travel partners (airlines, hotels, transport services) to facilitate your trip. . </p>
                                        <p>* Legal authorities if required by law. . </p>
                                        <p>*Payment processors for secure transactions. .</p>



                                        <small>4. Data Security</small>
                                        <p>We take strict measures to protect your data:</p>
                                        <p>* Encrypted payment processing. </p>
                                        <p>* Restricted access to personal information. </p>
                                        <p>*Regular security audits. </p>



                                        <small>5. Cookies & Tracking Technologies </small>
                                        <p>Our website may use cookies to improve user experience. You can disable cookies in your browser settings. </p>

                                        <small>6. Your Rights & Choices</small>
                                        <p>You have the right to:  </p>
                                        <p>* Access, update, or delete your personal information.   </p>
                                        <p>* Opt-out of marketing emails at any time. </p>
                                        <p>*Request details of data we hold about you.  </p>



                                        <small>7. Changes to This Policy </small>
                                        <p>We may update this Privacy Policy from time to time. The latest version will always be available on our website. </p>



                                        <small>8. Contact Us</small>
                                        <p>For any privacy-related concerns, reach out to: </p>
                                        <p> 📞 [9460938009]  </p>
                                        <p> 📧 [omshivshankartirthyatra01@gmail.com]  </p>



                                    </div>


                                </Tab>
                                <Tab eventKey="profile-1" title="Terms of Service">

                                    <div className='container'>
                                        <h1>Terms & Policy – </h1>

                                        <small>1. Booking & Payment    </small>
                                        <p> - All bookings must be made with full or partial payment as per the package terms.</p>
                                        <p>- The remaining balance must be cleared before departure. </p>
                                        <p>- Payment can be made via online transfer, bank deposit, or other accepted methods. </p>


                                        <small> 2. Cancellation & Refund </small>
                                        <p>-Cancellation charges apply as per the package terms </p>
                                        <p>-Refunds (if applicable) will be processed within [30] days after deducting applicable fees.</p>
                                        <p>-No refunds for last-minute cancellations or no-shows.</p>


                                        <small>3. Travel Documents & Responsibilities  </small>
                                        <p>  Travelers must carry valid identification and necessary travel documents.</p>
                                        <p>   For international trips, obtaining passport is the traveler’s responsibility. However, assistance can be provided upon request.</p>
                                        <p>You can also get your passport made through us.</p>
                                        <p>  For international trips our company will take the resposibility of visa.  </p>
                                        <p> We are not responsible for denied entry due to improper documentation.  </p>



                                        <small>4. Health & Safety
                                        </small>
                                        <p>-Travelers should ensure they are physically fit for the journey.</p>
                                        <p>-We are not liable for health-related issues arising during the trip. </p>


                                        <small> 5. Itinerary & Changes  </small>
                                        <p>    The itinerary is subject to change due to weather, political conditions, or unforeseen circumstances.
                                        </p>
                                        <p>     We reserve the right to modify or cancel any tour if necessary, with prior notice.
                                        </p>

                                        <small> 6. Liability & Responsibility </small>                                       <p>·        Om Shiv Shankar Tirth Yatra acts as a facilitator and is not responsible for delays, losses, or unforeseen circumstances beyond our control.                                      </p>
                                        <p>Travelers are responsible for their belongings. </p>




                                        <small> 7. Code of Conduct </small>
                                        <p>    Travelers are expected to respect local customs, laws, and fellow travelers.    Any misconduct may result in removal from the tour without a refund.      </p>






                                    </div>

                                </Tab>


                            </Tabs>

                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default TermsandCondition;
