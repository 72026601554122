import React, { useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import "../../assets/style.css";
import { useTranslation } from "react-i18next";






function FAQ() {
  const { t, i18n } = useTranslation()
 
     
    return (
      <>
        <div className='faq-box'>
            <h6>{t("FAQs")}</h6>
            <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>{t("bookingtours")}</Accordion.Header>
        <Accordion.Body>
        {t("bookingtour")}
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>{t("visa")}</Accordion.Header>
        <Accordion.Body>
        {t("visas")}
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>{t("Thrillophilia")}</Accordion.Header>
        <Accordion.Body>
        {t("Thrillophili")}
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>{t("paasss")}</Accordion.Header>
        <Accordion.Body>
        {t("passwordsss")}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>

        </div>
      </>
    );
}

export default FAQ;
