import React from 'react';
import "../assets/style.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../src/assets/chardham.png';
import rama from '../../src/assets/rama.png'
import rama1 from '../../src/assets/rama1.jpg'

const Rameshwaram = () => {
    return (
        <>
            <div>

                <img className='w-100' src={rama} alt="rama.png" />
            </div>
            <section className='section-padding'>
                <div className='container'>
                    <div className>
                        <div className='row'>
                            <div className='col-lg-12 tabs'>
                                <h1>Rameshwaram-Kerala Yatra </h1>
                                <span>
                                </span> <br />

                                <p classname="about_para">Where ocean waves in prayer rise,
                                    And temple bells touch the skies.
                                    Rameshwaram calls with blessings pure,
                                    A Jyotirlinga, strong and sure.
                                    Through Kerala’s lands, so lush, so green,
                                    Where rivers flow in sights serene.
                                    A journey blessed, both vast and true,
                                    Where faith and nature welcome you.
                                    Come, walk this path, so bright, so free,
                                    With Om Shiv Shankar Tirth Yatra guiding thee. 🚩

                                </p>
                                <br />
                                <h1>Rameshwaram-Kerala Yatra: A Journey of Divinity & Serenity</h1> <br />
                                <p>Embark on a sacred and serene journey with Om Shiv Shankar Tirth Yatra, where devotion meets the
                                    beauty of nature. From the holy Jyotirlinga of Rameshwaram, blessed by Lord Shiva, to
                                    the tranquil backwaters and temples of Kerala, this yatra offers spiritual bliss and peace.

                                </p>



                                <h3> **Why Join This Yatra?**      </h3>


                                <p>🔱 Rameshwaram – A Gateway to Moksha – Seek blessings at one of the holiest Jyotirlingas and walk the path of Lord Ram.
                                </p>
                                <p>  🌿 Kerala – God's Own Country – Experience divine tranquility in ancient temples, lush greenery, and sacred rivers.

                                </p>
                                <p> 🛕 A Perfect Blend of Devotion & Nature – Purify your soul in Rameshwaram’s holy waters and find peace in Kerala’s serene landscapes.

                                </p>

                                <p> With Om Shiv Shankar Tirth Yatra, enjoy a hassle-free, well-organized, and spiritually uplifting pilgrimage. Book your Rameshwaram-Kerala Yatra today! 🚩  </p>


                            </div>


                        </div>
                        <div className='mt-4'>
                            <img className="w-100" src={rama1} alt="rama1.png" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );

}
export default Rameshwaram;