import React, { useEffect, useState } from 'react';
import "../assets/style.css";
import dubaiImage from "../assets/images/dubai-1.jpg";
import tour1Image from "../assets/images/6rk65fm7o2fy9kudpathwks0tei3_shutterstock_1682937292.jpg";
import tour2Image from "../assets/images/8v3vbq25aw44w1zoo23ib99q81v4_facadeimg.jpg";
import tour3Image from "../assets/images/tour1.jpg";
import dubai2Image from "../assets/images/dubai-2.jpg";
import dubai3Image from "../assets/images/dubai-3.jpg";
import carImage from "../assets/images/car.svg";
import HomeImage from "../assets/images/home.svg";
import coffeImage from "../assets/images/coffe.svg";
import baloonImage from "../assets/images/baloon.svg";
import tourImage from "../assets/images/tourtrip1.jpg";
import inquiresImage from "../assets/images/inquires.jpg"
import vactionImage from "../assets/images/at_solo.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import Accordion from 'react-bootstrap/Accordion';
import moonImage from "../assets/images/moon.svg";
import starImage from "../assets/images/star.svg";
import ProgressBar from 'react-bootstrap/ProgressBar';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import stargrop from "../assets/images/stargroup.svg";
import { FaCheck } from "react-icons/fa";

import endImage from "../assets/images/end_of_trip_desktop.jpg";
import HeartImage from "../assets/images/listing_page_heart_ballon_icon.jpg"
import messageImage from "../assets/images/listing_page_message_icon.png"
import HeartImages from "../assets/images/listing_page_heart_icon.png"
import HeartPhone from "../assets/images/listing_page_24_7_icon.png"
import "swiper/css"; // Core Swiper styles
import "swiper/css/navigation"; // Navigation styles
import "swiper/css/pagination"; // Pagination styles
import Form from 'react-bootstrap/Form';
import LoactionImage from "../assets/images/loactionsvg.svg"
import CheckImage from "../assets/images/check.svg"
import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lightgallery.css'; // Core LightGallery CSS
import 'lightgallery/css/lg-thumbnail.css'; // Thumbnail plugin CSS
import 'lightgallery/css/lg-zoom.css'; // Zoom plugin CSS
import Tab from 'react-bootstrap/Tab';// tab
import Tabs from 'react-bootstrap/Tabs';// tabs
// Plugins
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import { NavLink, Navigate, useNavigate, useParams } from 'react-router-dom';
import { apiRequest } from '../ApiService/Allapi';
import Swal from "sweetalert2";
import $ from "jquery";
import Select from 'react-select';
import Modal from 'react-bootstrap/Modal';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { FaDownload } from "react-icons/fa6";


function Tour() {
    const [isOpen, setIsOpen] = useState(false);
    const { slug } = useParams(); // Access the slug parameter from the route


    const [startDate, setStartDate] = useState(new Date());
    const [selectedOption1, setSelectedOption1] = useState(null);

    const options1 = [
        { value: '+91', label: '+91' },
        { value: '+92', label: '+92' },
        { value: '+93', label: '+93' },
        { value: '+90', label: '+90' }
    ];
    const handleSelectChange1 = (option1) => {
        setSelectedOption1(option1);
        console.log('Selected option:', option1);
    };

    const [Media, setMedia] = useState([])

    const [show1, setShow1] = useState(false);
    const [dataDur, setdataDur] = useState([])
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);

    useEffect(() => {
        // jQuery logic
        $(".cate-1").on("click", function () {
            $(this).find(".form-check-input").prop("checked", true);

        });

        // Cleanup to remove event listener on unmount
        return () => {
            $(".cate-1").off("click");
        };
    }, []);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);



    // code by sharwan
    const [data, setdata] = useState('')
    const [amenity, setAmenity] = useState(['']);
    const [Inclusion, setInclusion] = useState([''])
    const [Exclusions, setExclusions] = useState([''])
    const [Loading, setLoading] = useState(false);
    const [DEstination, SetDEstination] = useState([''])
    const [Category, setCategory] = useState([])
    const langs = localStorage.getItem("i18nextLng") ?? "en";
    const [lang, setLang] = useState(langs);
    const fetchData = async () => {

        setLoading(true);
        try {
            const url = `tour/${slug}?lang=${lang}`;
            const res = await apiRequest(url, "GET", null);

            if (res.status === true) {
                setdata(res.data);
                setAmenity(JSON.parse(res.data.amenity));
                setInclusion(JSON.parse(res.data.include))
                setExclusions(JSON.parse(res.data.exclude))
                setdataDur(res.related_tour)
                setiternarys(res.data.iternary)
                setMedia(res.data.media)
                SetDEstination(res.route)
                setCategory(res.category)

            } else {
                Swal.fire({
                    title: res.message || "Failed to fetch data",
                    icon: "error",
                }).then(() => {
                    window.location.href = '/';
                });
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            Swal.fire({
                title: "Something went wrong, please try again.",
                icon: "error",
            }).then(() => {
                window.location.href = '/';
            });
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchData()
    }, [slug, lang])
    // -----------

    const [selectedOption, setSelectedOption] = useState(null);
    const options = [
        { value: '+91', label: '+91' },
        { value: '+92', label: '+92' },
        { value: '+93', label: '+93' },
        { value: '+94', label: '+91' }
    ];



    const images = [
        {
            src: 'https://media1.thrillophilia.com/filestore/h98n5e622k93b3zybikonselnh1b_20230926_194222.jpg?w=200&dpr=1.3',
            thumb: 'https://media1.thrillophilia.com/filestore/h98n5e622k93b3zybikonselnh1b_20230926_194222.jpg?w=200&dpr=1.3',
            alt: 'Image 1',
        },
        {
            src: 'https://media1.thrillophilia.com/filestore/0ngg6o4e68yfnt4xrlfhmldv5g9y_bbafe06dc9924f01b5eda8afbc52f716.jpg?w=200&dpr=1.3',
            thumb: 'https://media1.thrillophilia.com/filestore/0ngg6o4e68yfnt4xrlfhmldv5g9y_bbafe06dc9924f01b5eda8afbc52f716.jpg?w=200&dpr=1.3',
            alt: 'Image 2',
        },
        {
            src: 'https://media1.thrillophilia.com/filestore/h98n5e622k93b3zybikonselnh1b_20230926_194222.jpg?w=200&dpr=1.3',
            thumb: 'https://media1.thrillophilia.com/filestore/h98n5e622k93b3zybikonselnh1b_20230926_194222.jpg?w=200&dpr=1.3',
            alt: 'Image 3',
        },
        {
            src: 'https://media1.thrillophilia.com/filestore/h98n5e622k93b3zybikonselnh1b_20230926_194222.jpg?w=200&dpr=1.3',
            thumb: 'https://media1.thrillophilia.com/filestore/h98n5e622k93b3zybikonselnh1b_20230926_194222.jpg?w=200&dpr=1.3',
            alt: 'Image 3',
        },
        {
            src: 'https://media1.thrillophilia.com/filestore/0ngg6o4e68yfnt4xrlfhmldv5g9y_bbafe06dc9924f01b5eda8afbc52f716.jpg?w=200&dpr=1.3',
            thumb: 'https://media1.thrillophilia.com/filestore/0ngg6o4e68yfnt4xrlfhmldv5g9y_bbafe06dc9924f01b5eda8afbc52f716.jpg?w=200&dpr=1.3',
            alt: 'Image 2',
        },
        {
            src: 'https://media1.thrillophilia.com/filestore/h98n5e622k93b3zybikonselnh1b_20230926_194222.jpg?w=200&dpr=1.3',
            thumb: 'https://media1.thrillophilia.com/filestore/h98n5e622k93b3zybikonselnh1b_20230926_194222.jpg?w=200&dpr=1.3',
            alt: 'Image 3',
        },
    ];

    const [isExpanded, setIsExpanded] = useState(false);


    const toggleReadMore = () => {
        setIsExpanded(!isExpanded);
    };
    const [activeTab, setActiveTab] = useState(1);

    const username = localStorage.getItem("name")
    const useremail = localStorage.getItem("email")

    const [SinForm, setSiSinForm] = useState({
        name: username,
        email: useremail,
        // Mobail9: "",
        Phone: "",
        Startdate: "",
        person: "",
        texteraa: ""
    });
    const [isLoading, setIsLoading] = useState(false);
    const handleSelectChange = (e) => {
        setSiSinForm({ ...SinForm, [e.target.name]: e.target.value });
    };


    const handleSumbit = async (e) => {
        e.preventDefault();

        const body = {
            name: SinForm.name,
            email: SinForm.email,
            phone: SinForm.Phone,
            message: SinForm.texteraa,
            person: SinForm.person,
            date: SinForm.Startdate,
            slug: slug
        };


        try {
            const result = await apiRequest(`enquiry?lang=${lang}`, "POST", body);
            if (result.status === true) {


                Swal.fire({
                    title: result.message,
                    icon: "success"
                });
                setName(result.data.name)
                setformid(result.data.id)

                // window.location.reload()s
            } else {
                Swal.fire({
                    title: result.message,
                    icon: "error"
                });
            }
        } catch (error) {
            console.log()
            Swal.fire({
                title: error.message,
                icon: "error"
            });
            console.error(error);
        } finally {
            setIsLoading(false); // Re-enable the button after response
        }
    };





    const [withs, setWiths] = useState(0);

    const handleWith = ((para) => {
        setWiths(para)
    })

    const [fixed, setfixed] = useState(0)

    const handlefixd = ((para) => {
        setfixed(para)
    })


    const [trip, settrip] = useState(0)

    const handletrip = (para) => {
        settrip(para)
    }


    const [tickets, settickets] = useState(0)


    const handletickets = async (para) => {
        settickets(para)


        const body = {
            id: formid,
            with: withs,
            fixed: fixed,
            trip: trip,
            ticket: tickets,

        };


        try {
            const result = await apiRequest(`enquiry-addon?lang=${lang}`, "POST", body);
            if (result.status === true) {


                // Swal.fire({
                //     title: result.message,
                //     icon: "success"
                // });

            } else {
                Swal.fire({
                    title: result.message,
                    icon: "error"
                });
            }
        } catch (error) {
            console.log()
            Swal.fire({
                title: error.message,
                icon: "error"
            });
            console.error(error);
        } finally {
            setIsLoading(false)
        }


    }

    const [Name, setName] = useState('')
    const [formid, setformid] = useState(null)
    const [iternarys, setiternarys] = useState([])


    const { t, i18n } = useTranslation();

     const downloads = async (slug) => {
            setLoading(true); // Start loading before making request
    
            try {
                const res = await apiRequest(`download-pdf/${slug}`, "GET", null);
    
                if (res.status === true) {
                    Swal.fire({
                        title: "Download started successfully!",
                        icon: "success",
                    });
    
                    if (res.file_url) {
                        const link = document.createElement("a");
                        link.href = res.file_url;
                        link.setAttribute("download", "filename.pdf"); // Specify default file name
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }
                } else {
                    Swal.fire({
                        title: res.message || "Failed to fetch data",
                        icon: "error",
                    });
                }
            } catch (error) {
                Swal.fire({
                    title: "An error occurred!",
                    text: error.message || "Something went wrong.",
                    icon: "error",
                });
            } finally {
                setLoading(false);
            }
        };

    return (
        <section className='section-padding pt-3'>
            <div className='container'>
                <div className='row g-2'>
                    <div className='col-lg-12'>
                        <div className='tour_media' style={{position:'relative'}}>

                            {data?.media?.length > 0 ? (
                                <img className='w-200 w-100 rounded' onClick={handleShow} src={data?.media[0]?.original_url} alt="" />
                            ) : (
                                <img className='w-200 w-100 rounded' onClick={handleShow} src={tour3Image} alt="" />
                            )}

<div className='view_more'>
                                        {/* <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_420_1971)"><path d="M1.33203 8C1.33203 4.85734 1.33203 3.286 2.30803 2.30934C3.28536 1.33334 4.85603 1.33334 7.9987 1.33334C11.1414 1.33334 12.7127 1.33334 13.6887 2.30934C14.6654 3.28667 14.6654 4.85734 14.6654 8C14.6654 11.1427 14.6654 12.714 13.6887 13.69C12.7134 14.6667 11.1414 14.6667 7.9987 14.6667C4.85603 14.6667 3.2847 14.6667 2.30803 13.69C1.33203 12.7147 1.33203 11.1427 1.33203 8Z" stroke="#202020"></path><path d="M10.6654 6.66667C11.4017 6.66667 11.9987 6.06972 11.9987 5.33334C11.9987 4.59696 11.4017 4 10.6654 4C9.92898 4 9.33203 4.59696 9.33203 5.33334C9.33203 6.06972 9.92898 6.66667 10.6654 6.66667Z" stroke="#202020"></path><path d="M1.33203 8.33334L2.50003 7.31134C2.79279 7.05538 3.17186 6.92023 3.56051 6.93322C3.94916 6.94622 4.31836 7.1064 4.59336 7.38134L7.45336 10.2413C7.6753 10.4632 7.9684 10.5997 8.28106 10.6268C8.59373 10.6539 8.90592 10.5698 9.1627 10.3893L9.36203 10.2493C9.73243 9.98919 10.1801 9.86238 10.6319 9.88963C11.0837 9.91687 11.5129 10.0966 11.8494 10.3993L13.9987 12.3333" stroke="#202020" stroke-linecap="round"></path></g><defs><clipPath id="clip0_420_1971"><rect width="16" height="16" fill="white"></rect></clipPath></defs></svg> */}
                                        <span className='fs-12 font-500' style={{ color: '#202020' }} onClick={handleShow}>{t("AllImages")}</span>
                                    </div>

                        </div>
                    </div>
                    <div className='col-lg-6 col-md-6'>
                        <div className='row g-2'>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                                {/* <div className="details_media">
                                    <img className='w-100 dubai-tour-image'  onClick={handleShow} style={{ borderTopLeftRadius: "8px", borderBottomLeftRadius: "8px" }} src={tour3Image} alt="" />
                                    <span className="details_title">{t("Destination")}</span>
                                </div> */}
                            </div>
                            <div className="col-lg-6 col-md-6 col-6">
                                {/* <div className='details_media'>
                                    <img className='dubai-tour-image' style={{ WebkitBorderTopRightRadius: "8px" }} src={tour2Image}
                                        alt="" />
                                    <span className='details_title'>{t("stay")}</span>
                                </div> */}
                            </div>
                            <div className="col-lg-6 col-md-6 col-6">
                                {/* <div className='details_media'>

                                    <img className='dubai-tour-image' src={tour1Image} alt="" />
                                    <span className='details_title'>{t("Sightseeing")}</span>
                                </div> */}

                            </div>
                            <div className="col-lg-6 col-md-6 col-6">
                                <div className='details_media_1'>
                                    {/* <img className='dubai-tour-image' style={{ WebkitBorderBottomRightRadius: "8px" }} src={dubaiImage} alt="" /> */}
                                  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* tour_content */}
                <div className='tour_content'>
                    <div className="row">
                        <div className="col-lg-8">
                            <div className='tour_content_left'>
                                <h1>{data?.title}</h1>
                                <div className='d-flex align-items-center'>
                                    {/* <div className='badge_days me-2'>
                                       {data.duration} D
                                    </div> */}
                                    <div className='d-flex align-items-center'>
                                        <div className='number'>
                                            <span>{data?.duration}</span>
                                        </div>
                                        <div className='ms-1' style={{ display: 'flex', justifyContent: "center", flexDirection: 'column' }} >
                                            <small className='fs-9'>{t("days")}</small>
                                            <small className='fs-9'> <b>{data?.address}</b> </small>
                                        </div>
                                    </div>
                                </div>
                                <div className="facilites_box">

                                    {amenity.map((item) => {
                                        return (
                                            <>
                                                <div className='d-flex align-items-center'>
                                                    <img src={carImage} alt="" />
                                                    <span className='ms-2'>{item?.title}</span>
                                                </div>
                                            </>
                                        )
                                    })}


                                </div>

                                {/* <div className="choose_trip">
                                    <h6>{t("ChooseTrip")}</h6>
                                    <div className='grid-view'>
                                        {dataDur.length === 0 ?
                                            (
                                                <div className="no-data-found">
                                                    <p>{t("Nodatafound")}</p>
                                                </div>
                                            ) : (dataDur.map((item) => {
                                                return (
                                                    <NavLink to={`/tour/${item.slug}`}  >
                                                        <div className="grid-box">
                                                            <div className="choose_media">
                                                                <img src={tourImage} alt="" />
                                                                <div className="tour_days">
                                                                    {item.duration}
                                                                </div>
                                                            </div>
                                                            <div className="choose_content">
                                                                <small>{t("StartingFrom")}</small>
                                                                <h6>{t("inr")} {item.price}</h6>
                                                            </div>
                                                        </div>
                                                    </NavLink>


                                                )

                                            }))}

                                    </div>

                                </div> */}
                                {/* distance */}
                                {/* <div className='choose_trip'>
                                    <h6>{t("DestinationRoutes")}</h6>
                                    <div className="distance">
                                        {DEstination.map((item, index) => {
                                            let parsedRoutes = [];
                                            try {
                                           
                                                if (item.route) {
                                                    parsedRoutes = JSON.parse(item.route);
                                                }
                                            } catch (error) {
                                                console.error(`Invalid JSON for route at index ${index}:`, error);
                                            }

                                            return (
                                                <NavLink to={`/tour/${item.slug}`}>
                                                    <div className="distance">
                                                        
                                                        <label
                                                            className={
                                                                item.slug === slug
                                                                    ? 'form-check-label  '
                                                                    : 'form-check-label my-2'
                                                            }
                                                            htmlFor="exampleCheck-1"
                                                            style={{
                                                                border: item.slug === slug ? '1px solid #f37002' : '',
                                                                 backgroundColor: item.slug === slug ? 'rgb(243 112 2/10%)' : ''

                                                            }}
                                                        >
                                                            <div className="form-check ps-0">
                                                                <div className="d-flex align-item-center">
                                                                    {parsedRoutes.map((name) => (
                                                                        <div style={{ marginBottom: "0px" }} key={name.title}> {/* Added key for React list rendering */}
                                                                           
                                                                            {/* <span
                                                                                className="form-check-label border-0"
                                                                                style={{
                                                                                    boxShadow: "unset",
                                                                                    fontSize: "13px",
                                                                                    marginLeft: "10px",
                                                                                }}
                                                                            >
                                                                                {name.title}
                                                                            </span>
                                                                        </div> */}
                                                                    {/* ))}
                                                                </div>
                                                            </div>
                                                        </label> */}
                                                       

                                                    {/* </div>
                                                </NavLink>
                                            );
                                        })}
                                    </div> */}

                                {/* </div>  */}
                                {/* Stay Category */}
                                {/* <div className='choose_trip'>
                                    <h6>{t("StayCategory")}</h6>
                                    <div className='cate-1 cate'>

                                        {Category.map((item) => {
                                            return (
                                                <>
                                                    <NavLink to={`/tour/${item.slug}`}>
                                                        {/* <form> */}
                                                        {/* <label className={item.slug == slug ? 'form-check-label' : 'form-check-label'} htmlFor="exampleCheck-1" style={{
                                                            border: item.slug === slug ? '1px solid #f37002' : '',
                                                             backgroundColor: item.slug === slug ? 'rgba(243, 112, 2, 0.1)' : ''

                                                        }}>
                                                            <div className="form-check ps-0" >
                                                                <span className="form-check-label separator">{item.category}</span>
                                                            </div>
                                                        </label> */}

                                                        {/* </form> */}
                                                    {/* </NavLink>

                                                </>
                                            )
                                        })} */}



                                    {/* </div>
                                </div> */} 
                                {/* Stay Category */}
                                <div className="choose_trip">
                                    <h6>{t("TripHighlights")}</h6>
                                    <div dangerouslySetInnerHTML={{ __html: data?.content }} />
                                </div>
                                <div className='col-lg-12'>
                                    <div className='itinerary'>
                                    <div class="itinerary-heading">
                                                        <h1 class="new-package-heading">{data.title}</h1>

                                                    </div>
                                        {iternarys.map((item) => {
                                            return (
                                                <>
                                                    
                                                    <div className='itinerary-days'>
                                                        <Accordion>
                                                            <Accordion.Item eventKey="0">
                                                                <Accordion.Header>{t("day")} {item.day} <strong className='ms-3'>{item.title}</strong>  </Accordion.Header>
                                                                <Accordion.Body>
                                                                  {item.description}
                                                                </Accordion.Body>
                                                            </Accordion.Item>

                                                        </Accordion>

                                                    </div>



                                                </>
                                            )
                                        })}


                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 '>
                            <div className='right_content'>
                            <div className="price_box">
  <div className="d-flex align-items-center flex-wrap">
    <div className="d-flex align-items-center">
      <h6>{t("inr")}{data.sale_price}</h6>
      <small>{t("PerAdult")}</small>
    </div>
&nbsp; &nbsp;&nbsp;
    <div className="ProductPricing_rating__1NjlU">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.4829 5.63406L10.6864 4.90408L8.54222 0.352135C8.48365 0.227506 8.38731 0.126615 8.26829 0.0652895C7.96981 -0.0890138 7.6071 0.0395723 7.45786 0.352135L5.3137 4.90408L0.517213 5.63406C0.384974 5.65384 0.26407 5.71912 0.171503 5.81803C0.0595945 5.93848 -0.0020722 6.10053 5.31712e-05 6.26856C0.00217854 6.4366 0.0679221 6.59688 0.182838 6.71418L3.65316 10.2572L2.83328 15.2602C2.81405 15.3766 2.82635 15.4963 2.86878 15.6057C2.91121 15.7151 2.98207 15.8099 3.07333 15.8793C3.16459 15.9488 3.27259 15.99 3.38509 15.9984C3.4976 16.0068 3.6101 15.982 3.70983 15.9269L8.00004 13.5648L12.2902 15.9269C12.4074 15.9922 12.5434 16.0139 12.6737 15.9902C13.0024 15.9308 13.2235 15.6044 13.1668 15.2602L12.3469 10.2572L15.8172 6.71418C15.9117 6.61724 15.974 6.49064 15.9929 6.35216C16.0439 6.00597 15.8135 5.68549 15.4829 5.63406Z" fill="#19AD6F"></path>
      </svg>
      <span className="ProductPricing_currentRating__DZYu_">{data.review_score}</span>
      <span className="ProductPricing_totalRatings__6Bhy8">  (54.8K)</span>
    </div>
  </div>

  <del>{t("inr")} {data.price}</del>

  {/* 🔹 दोनों बटन को साइड-बाय-साइड लाने के लिए d-flex और gap-3 */}
  <div className="d-flex gap-3 mt-3">
    <button className="btn btn-primary" onClick={handleSumbit}>
      {t("SendEnquiry")}
    </button>

    <button className="btn btn-primary d-flex align-items-center" onClick={() => downloads(data.slug)}>
      {t("Download")} <FaDownload className="ms-2" />
    </button>
  </div>
</div>

                                <div className='price_box mt-3'>
                                    <div className='legal_box'>
                                        <small>{data.title}</small>
                                        <div className="LeadForm_priceWrapper__sm4T9">
                                            <div className="LeadForm_actualPrice__TcFIb">  {t("inr")} {data.sale_price} </div>
                                            <div className="LeadForm_strikeThroughPrice__OhcvW">
                                                {t("inr")} {data.price}
                                            </div>
                                            <span className="LeadForm_savePriceLeftBorderIcon__q_QWZ">
                                                <svg
                                                    width="4"
                                                    height="24"
                                                    viewBox="0 0 4 24"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M2.65992 3L0 6L2.65992 9L0 12L2.65992 15L0 18L2.65992 21L0 24H3.5V0H0L2.65992 3Z"
                                                        fill="#E5F1E8"
                                                    ></path>
                                                </svg>
                                            </span>
                                            <span className="LeadForm_savePrice__y3MK8">
                                                {t("save")} <div> {t("inr")} {data.price - data.sale_price}</div>
                                            </span>
                                            <span className="LeadForm_savePriceRightBorderIcon__aIBTe">
                                                <svg
                                                    width="4"
                                                    height="24"
                                                    viewBox="0 0 4 24"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M2.65992 3L0 6L2.65992 9L0 12L2.65992 15L0 18L2.65992 21L0 24H3.5V0H0L2.65992 3Z"
                                                        fill="#E5F1E8"
                                                    ></path>
                                                </svg>
                                            </span>
                                        </div>
                                        <Form className={Name ? "d-none" : 'mt-3'} onSubmit={handleSumbit}>
                                            <div className='row gx-2'>

                                                <Form.Group controlId="exampleForm.ControlInput1">
                                                    <Form.Control type="text" className='form-control-lg' placeholder={t("Names")} name='name' value={SinForm.name} onChange={handleSelectChange} />
                                                </Form.Group>
                                                <Form.Group controlId="exampleForm.ControlInput2">
                                                    <Form.Control type="email" className='form-control-lg' name='email' placeholder={t("Emails")} value={SinForm.email} onChange={handleSelectChange} />
                                                </Form.Group>
                                                <div className='col-lg-4 col-4'>
                                                    <Select
                                                        options={options}
                                                        // value={selectedOption}

                                                        placeholder="+91"
                                                        name='Mobail9'

                                                    />
                                                </div>
                                                <div className='col-lg-8 col-8'>
                                                    <Form.Group controlId="exampleForm.ControlInput2">
                                                        <Form.Control type="number" className='form-control-lg' placeholder={t("number")} value={SinForm.Phone} name='Phone' onChange={handleSelectChange} />
                                                    </Form.Group>
                                                </div>
                                                <div className='col-lg-6'>
                                                    <Form.Group controlId="exampleForm.ControlInput1">
                                                        <Form.Control type="date" className='form-control-lg' placeholder="" value={SinForm.Startdate} name='Startdate' onChange={handleSelectChange} />
                                                    </Form.Group>
                                                </div>
                                                <div className='col-lg-6'>
                                                    <Form.Group controlId="exampleForm.ControlInput2">
                                                        <Form.Control type="number" className='form-control-lg' placeholder={t("persons")} value={SinForm.person} name="person" onChange={handleSelectChange} />
                                                    </Form.Group>
                                                </div>
                                            </div>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1" >

                                                <Form.Control as="textarea" rows={4} value={SinForm.texteraa} onChange={handleSelectChange} name='texteraa' placeholder={t("comment")} />
                                            </Form.Group>
                                            <button className='btn btn-primary'> {t("SendEnquiry")}</button>


                                        </Form>
                                    </div>
                                </div>
                                <div className={Name === '' ? "d-none vaction_tour" : "vaction_tour"}>
                                    <div className='d-flex'>
                                        <div className='vaction_icon'>
                                            <FaCheck />
                                        </div>
                                        <div style={{ lineHeight: "1" }} >
                                            <span className='fs-13 d-block font-w400'>{t("Awesome")}<strong className='text-orange'>{Name}</strong> </span>
                                            <small className='fs-10 text-small'>{t("personalisedquotation")}</small>
                                        </div>
                                    </div>
                                    <div className='th-lf-cps-divider'></div>
                                    {/* trip_image_strat */}
                                    <div className={withs === 0 ? 'row g-2' : 'row g-2 d-none'}>
                                        <h6 className='vaction-title '>{t("vacationwith")}</h6>
                                        <div className='col-lg-6'>
                                            <div className='tour_slected'>
                                                <form>
                                                    <label className="form-check-label" htmlFor="exampleCheck-5">
                                                        <div className="form-check ps-0">
                                                            <span className='form-check-label'>
                                                                <img src={vactionImage} className='w-100' alt="" />
                                                            </span>
                                                            <input type="checkbox" className="form-check-input" id="exampleCheck-5" name='Solo' onClick={() => handleWith(1)} />

                                                        </div>
                                                        <div className='text-center mt-2'>
                                                            <h6 className='mb-0 fs-14 text-small'>{t("Solo")}</h6>
                                                        </div>
                                                    </label>

                                                </form>
                                            </div>
                                        </div>
                                        <div className='col-lg-6'>
                                            <div className='tour_slected'>
                                                <form>
                                                    <label className="form-check-label" htmlFor="exampleCheck-5">
                                                        <div className="form-check ps-0">
                                                            <span className='form-check-label'>
                                                                <img src={vactionImage} className='w-100' alt="" />
                                                            </span>
                                                            <input type="checkbox" className="form-check-input" id="exampleCheck-5" name='Spouse' onClick={() => handleWith(2)} />
                                                            <div className='text-center mt-2'>
                                                                <h6 className='mb-0 fs-14 text-small'>{t("Spouse")}</h6>
                                                            </div>
                                                        </div>
                                                    </label>

                                                </form>
                                            </div>
                                        </div>
                                        <div className='col-lg-6'>
                                            <div className='tour_slected'>
                                                <form>
                                                    <label className="form-check-label" htmlFor="exampleCheck-5">
                                                        <div className="form-check ps-0">
                                                            <span className='form-check-label'>
                                                                <img src={vactionImage} className='w-100' alt="" />
                                                            </span>
                                                            <input type="checkbox" className="form-check-input" id="exampleCheck-5" name='Family' onClick={() => handleWith(3)} />

                                                        </div>
                                                        <div className='text-center mt-2'>
                                                            <h6 className='mb-0 fs-14 text-small'>{t("Family")}</h6>
                                                        </div>
                                                    </label>

                                                </form>
                                            </div>
                                        </div>
                                        <div className='col-lg-6'>
                                            <div className='tour_slected'>
                                                <form>
                                                    <label className="form-check-label" htmlFor="exampleCheck-5">
                                                        <div className="form-check ps-0">
                                                            <span className='form-check-label'>
                                                                <img src={vactionImage} className='w-100' alt="" />
                                                            </span>
                                                            <input type="checkbox" className="form-check-input" id="exampleCheck-5" name='Friends' onClick={() => handleWith(4)} />
                                                            <div className='text-center mt-2'>
                                                                <h6 className='mb-0 fs-14 text-small'>{t("Friends")}</h6>
                                                            </div>
                                                        </div>
                                                    </label>

                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    {/* trip_image_end */}
                                    {/* fixed_date_strat */}
                                    <div className={withs ? !fixed ? 'row g-2' : 'row g-2 d-none' : 'row g-2 d-none'}>
                                        <h6 className='vaction-title '>{t("traveldate")}</h6>
                                        <div className='col-lg-12'>
                                            <div className='date_slected'>
                                                <form>
                                                    <label className="form-check-label" htmlFor="exampleCheck-8">
                                                        <div className="form-check ">
                                                            <span className='form-check-label'>
                                                                {t("DateFixed")}
                                                            </span>
                                                            <input type="checkbox" className="form-check-input" id="exampleCheck-8" name='Date' onClick={() => handlefixd(1)} />

                                                        </div>

                                                    </label>

                                                </form>
                                            </div>
                                            <div className='date_slected'>
                                                <form>
                                                    <label className="form-check-label" htmlFor="exampleCheck-8">
                                                        <div className="form-check ">
                                                            <span className='form-check-label'>
                                                                {t("flexible")}
                                                            </span>
                                                            <input type="checkbox" className="form-check-input" id="exampleCheck-8" name='travel' onClick={() => handlefixd(2)} />

                                                        </div>

                                                    </label>

                                                </form>
                                            </div>
                                            <div className='date_slected'>
                                                <form>
                                                    <label className="form-check-label" htmlFor="exampleCheck-8">
                                                        <div className="form-check ">
                                                            <span className='form-check-label'>
                                                                {t("exploring")}
                                                            </span>
                                                            <input type="checkbox" className="form-check-input" id="exampleCheck-8" name='exploring' onClick={() => handlefixd(3)} />

                                                        </div>

                                                    </label>

                                                </form>
                                            </div>
                                        </div>

                                    </div>
                                    {/* fixed_date_end */}
                                    {/* book_date_strat */}

                                    <div className={fixed ? !trip ? 'row g-2' : 'row g-2 d-none' : 'row g-2 d-none'}>
                                        <h6 className='vaction-title '>{t("thistrip")}</h6>
                                        <div className='col-lg-12'>
                                            <div className='date_slected'>
                                                <form>
                                                    <label className="form-check-label" htmlFor="exampleCheck-8">
                                                        <div className="form-check ">
                                                            <span className='form-check-label'>
                                                                {t("WithinHours")}
                                                            </span>
                                                            <input type="checkbox" className="form-check-input" id="exampleCheck-8" name='Within' onClick={() => handletrip(1)} />

                                                        </div>

                                                    </label>

                                                </form>
                                            </div>
                                            <div className='date_slected'>
                                                <form>
                                                    <label className="form-check-label" htmlFor="exampleCheck-8">
                                                        <div className="form-check ">
                                                            <span className='form-check-label'>
                                                                {t("WithinHour")}
                                                            </span>
                                                            <input type="checkbox" className="form-check-input" id="exampleCheck-8" name='days' onClick={() => handletrip(2)} />

                                                        </div>

                                                    </label>

                                                </form>
                                            </div>
                                            <div className='date_slected'>
                                                <form>
                                                    <label className="form-check-label" htmlFor="exampleCheck-8">
                                                        <div className="form-check ">
                                                            <span className='form-check-label'>
                                                                {t("WithinHou")}
                                                            </span>
                                                            <input type="checkbox" className="form-check-input" id="exampleCheck-8" name='week' onClick={() => handletrip(3)} />

                                                        </div>

                                                    </label>

                                                </form>
                                            </div>
                                            <div className='date_slected'>
                                                <form>
                                                    <label className="form-check-label" htmlFor="exampleCheck-8">
                                                        <div className="form-check ">
                                                            <span className='form-check-label'>
                                                                {t("WithinHo")}
                                                            </span>
                                                            <input type="checkbox" className="form-check-input" id="exampleCheck-8" name='Reaseaching' onClick={() => handletrip(4)} />

                                                        </div>

                                                    </label>

                                                </form>
                                            </div>
                                        </div>

                                    </div>
                                    {/* book_date_end */}
                                    {/* flight _strat */}

                                    <div className={trip && !tickets ? 'row g-2' : 'row g-2 d-none'}>
                                        <h6 className="vaction-title">{t("tickets")}</h6>

                                        {/* Flight tickets booked option */}
                                        <div className="col-lg-12">
                                            <div className="date_slected">
                                                <form>
                                                    <label className="form-check-label" htmlFor="exampleCheck-8">
                                                        <div className="form-check">
                                                            <span className="form-check-label">
                                                                {t("booked")}
                                                            </span>
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                id="exampleCheck-8"
                                                                name="booked"
                                                                onClick={() => handletickets(1)}
                                                            />
                                                        </div>
                                                    </label>
                                                </form>
                                            </div>

                                            {/* Need help in booking flight tickets option */}
                                            <div className="date_slected">
                                                <form>
                                                    <label className="form-check-label" htmlFor="exampleCheck-8">
                                                        <div className="form-check">
                                                            <span className="form-check-label">
                                                                {t("helpbooking")}
                                                            </span>
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                id="exampleCheck-8"
                                                                name="flight"
                                                                onClick={() => handletickets(2)}
                                                            />
                                                        </div>
                                                    </label>
                                                </form>
                                            </div>

                                            {/* Will book flight tickets on my own option */}
                                            <div className="date_slected">
                                                <form>
                                                    <label className="form-check-label" htmlFor="exampleCheck-8">
                                                        <div className="form-check">
                                                            <span className="form-check-label">
                                                                {t("bookflight")}
                                                            </span>
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                id="exampleCheck-8"
                                                                name="book"
                                                                onClick={() => handletickets(3)}
                                                            />
                                                        </div>
                                                    </label>
                                                </form>
                                            </div>
                                        </div>
                                    </div>


                                    <div className={tickets ? "thank_box" : 'd-none'}>
                                        <div className="vaction_icon style-1">
                                            <FaCheck />
                                        </div>
                                        <div>
                                            <h6 className="mt-3" style={{ fontWeight: '600' }}>
                                                {t("thank")} <span className="text-orange">{Name}</span>
                                            </h6>
                                            <p className="mb-0 fs-14">
                                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ea libero reprehenderit necessitatibus blanditiis.
                                            </p>
                                        </div>
                                    </div>

                                    {/* book_date_end */}
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-12'>
                            
                            <div className='pakage_box'>
                                <h4>{t("Whatsinsidethepackage")}</h4>
                                <div className='row'>
                                    <div className='col-lg-6 col-6'>
                                        <div className='package-list'>
                                            <h6>{t("Inclusions")}</h6>
                                            {Inclusion.map((item) => {
                                                return (
                                                    <>
                                                        <ul>
                                                            <li>{item.title}</li>


                                                        </ul>
                                                    </>
                                                )
                                            })}

                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-6'>
                                        <div className='package-list style-1'>
                                            <h6>{t("Exclusions")}</h6>
                                            {Exclusions.map((item) => {
                                                return (
                                                    <>
                                                        <ul>
                                                            <li>{item.title}</li>

                                                        </ul>
                                                    </>
                                                )
                                            })}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                        <div class="seprator-gradend"></div>

                        <div className='end_trip'>
                                <img src={endImage} className='w-100' alt="" />
                            </div>
                        {/* inquires_strat */}
                        {/* <div className='inquire_box'>
                            <div className='sub_box'>
                                <h6>{t("Bigger")}</h6>
                                <p>
                                    {t("unforgettable")}
                                </p>
                                <button className='btn btn-white' onClick={handleShow1}>{t("RequestCallback")}</button>
                            </div>
                        </div> */}
                        {/* inquires_end */}
                        {/* <div class="seprator-gradend"></div> */}

                       
                        {/* related_product_end */}
                        <div className='col-lg-12'>
                            {/* progress_review */}
                           
                            <div className='progress_review mb-5'>
                                {/* <h2>{t("Reviews")} (54,754)</h2> */}
                                <div className='row'>
                                    {/* <div className='col-lg-2 col-md-3'>
                                        <div className='all_progress'>
                                            <img src={stargrop} alt="" />
                                            <h3>{data.review_score}</h3>
                                            <a href="#">From 65+ countries</a>
                                        </div>
                                    </div> */}
                                    {/* <div className='col-lg-10 col-md-9'>
                                        <div className='d-flex align-items-center mb-2'>
                                            <div className='d-flex align-items-center'>
                                                <span>5</span>
                                                <span className='ms-2'><svg class="RatingBars_starIcon__URkxs" width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.1555 5.63406L11.8408 4.90408L9.46504 0.352135C9.40015 0.227506 9.2934 0.126615 9.16152 0.0652895C8.8308 -0.0890138 8.4289 0.0395723 8.26354 0.352135L5.88774 4.90408L0.573088 5.63406C0.426563 5.65384 0.292598 5.71912 0.190031 5.81803C0.0660325 5.93848 -0.00229606 6.10053 5.89153e-05 6.26856C0.00241389 6.4366 0.0752597 6.59688 0.20259 6.71418L4.04781 10.2572L3.13936 15.2602C3.11806 15.3766 3.13169 15.4963 3.1787 15.6057C3.22571 15.7151 3.30423 15.8099 3.40534 15.8793C3.50646 15.9488 3.62613 15.99 3.75079 15.9984C3.87544 16.0068 4.0001 15.982 4.11061 15.9269L8.86429 13.5648L13.618 15.9269C13.7477 15.9922 13.8985 16.0139 14.0429 15.9902C14.4071 15.9308 14.652 15.6044 14.5892 15.2602L13.6808 10.2572L17.526 6.71418C17.6306 6.61724 17.6997 6.49064 17.7207 6.35216C17.7772 6.00597 17.5218 5.68549 17.1555 5.63406Z" fill="#FFBA0A"></path></svg></span>
                                            </div>
                                            <ProgressBar variant="primary" now={60} />
                                            <div>
                                                43.3K
                                            </div>
                                        </div>
                                        <div className='d-flex align-items-center mb-2'>
                                            <div className='d-flex align-items-center'>
                                                <span>4</span>
                                                <span className='ms-2'><svg class="RatingBars_starIcon__URkxs" width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.1555 5.63406L11.8408 4.90408L9.46504 0.352135C9.40015 0.227506 9.2934 0.126615 9.16152 0.0652895C8.8308 -0.0890138 8.4289 0.0395723 8.26354 0.352135L5.88774 4.90408L0.573088 5.63406C0.426563 5.65384 0.292598 5.71912 0.190031 5.81803C0.0660325 5.93848 -0.00229606 6.10053 5.89153e-05 6.26856C0.00241389 6.4366 0.0752597 6.59688 0.20259 6.71418L4.04781 10.2572L3.13936 15.2602C3.11806 15.3766 3.13169 15.4963 3.1787 15.6057C3.22571 15.7151 3.30423 15.8099 3.40534 15.8793C3.50646 15.9488 3.62613 15.99 3.75079 15.9984C3.87544 16.0068 4.0001 15.982 4.11061 15.9269L8.86429 13.5648L13.618 15.9269C13.7477 15.9922 13.8985 16.0139 14.0429 15.9902C14.4071 15.9308 14.652 15.6044 14.5892 15.2602L13.6808 10.2572L17.526 6.71418C17.6306 6.61724 17.6997 6.49064 17.7207 6.35216C17.7772 6.00597 17.5218 5.68549 17.1555 5.63406Z" fill="#FFBA0A"></path></svg></span>
                                            </div>
                                            <ProgressBar variant="primary" now={50} />
                                            <div>
                                                43.3K
                                            </div>
                                        </div>
                                        <div className='d-flex align-items-center mb-2'>
                                            <div className='d-flex align-items-center'>
                                                <span>3</span>
                                                <span className='ms-2'><svg class="RatingBars_starIcon__URkxs" width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.1555 5.63406L11.8408 4.90408L9.46504 0.352135C9.40015 0.227506 9.2934 0.126615 9.16152 0.0652895C8.8308 -0.0890138 8.4289 0.0395723 8.26354 0.352135L5.88774 4.90408L0.573088 5.63406C0.426563 5.65384 0.292598 5.71912 0.190031 5.81803C0.0660325 5.93848 -0.00229606 6.10053 5.89153e-05 6.26856C0.00241389 6.4366 0.0752597 6.59688 0.20259 6.71418L4.04781 10.2572L3.13936 15.2602C3.11806 15.3766 3.13169 15.4963 3.1787 15.6057C3.22571 15.7151 3.30423 15.8099 3.40534 15.8793C3.50646 15.9488 3.62613 15.99 3.75079 15.9984C3.87544 16.0068 4.0001 15.982 4.11061 15.9269L8.86429 13.5648L13.618 15.9269C13.7477 15.9922 13.8985 16.0139 14.0429 15.9902C14.4071 15.9308 14.652 15.6044 14.5892 15.2602L13.6808 10.2572L17.526 6.71418C17.6306 6.61724 17.6997 6.49064 17.7207 6.35216C17.7772 6.00597 17.5218 5.68549 17.1555 5.63406Z" fill="#FFBA0A"></path></svg></span>
                                            </div>
                                            <ProgressBar variant="primary" now={40} />
                                            <div>
                                                43.3K
                                            </div>
                                        </div>
                                        <div className='d-flex align-items-center mb-2'>
                                            <div className='d-flex align-items-center'>
                                                <span>2</span>
                                                <span className='ms-2'><svg class="RatingBars_starIcon__URkxs" width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.1555 5.63406L11.8408 4.90408L9.46504 0.352135C9.40015 0.227506 9.2934 0.126615 9.16152 0.0652895C8.8308 -0.0890138 8.4289 0.0395723 8.26354 0.352135L5.88774 4.90408L0.573088 5.63406C0.426563 5.65384 0.292598 5.71912 0.190031 5.81803C0.0660325 5.93848 -0.00229606 6.10053 5.89153e-05 6.26856C0.00241389 6.4366 0.0752597 6.59688 0.20259 6.71418L4.04781 10.2572L3.13936 15.2602C3.11806 15.3766 3.13169 15.4963 3.1787 15.6057C3.22571 15.7151 3.30423 15.8099 3.40534 15.8793C3.50646 15.9488 3.62613 15.99 3.75079 15.9984C3.87544 16.0068 4.0001 15.982 4.11061 15.9269L8.86429 13.5648L13.618 15.9269C13.7477 15.9922 13.8985 16.0139 14.0429 15.9902C14.4071 15.9308 14.652 15.6044 14.5892 15.2602L13.6808 10.2572L17.526 6.71418C17.6306 6.61724 17.6997 6.49064 17.7207 6.35216C17.7772 6.00597 17.5218 5.68549 17.1555 5.63406Z" fill="#FFBA0A"></path></svg></span>
                                            </div>
                                            <ProgressBar variant="primary" now={20} />
                                            <div>
                                                43.3K
                                            </div>
                                        </div>
                                        <div className='d-flex align-items-center'>
                                            <div className='d-flex align-items-center'>
                                                <span>1</span>
                                                <span className='ms-2'><svg class="RatingBars_starIcon__URkxs" width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.1555 5.63406L11.8408 4.90408L9.46504 0.352135C9.40015 0.227506 9.2934 0.126615 9.16152 0.0652895C8.8308 -0.0890138 8.4289 0.0395723 8.26354 0.352135L5.88774 4.90408L0.573088 5.63406C0.426563 5.65384 0.292598 5.71912 0.190031 5.81803C0.0660325 5.93848 -0.00229606 6.10053 5.89153e-05 6.26856C0.00241389 6.4366 0.0752597 6.59688 0.20259 6.71418L4.04781 10.2572L3.13936 15.2602C3.11806 15.3766 3.13169 15.4963 3.1787 15.6057C3.22571 15.7151 3.30423 15.8099 3.40534 15.8793C3.50646 15.9488 3.62613 15.99 3.75079 15.9984C3.87544 16.0068 4.0001 15.982 4.11061 15.9269L8.86429 13.5648L13.618 15.9269C13.7477 15.9922 13.8985 16.0139 14.0429 15.9902C14.4071 15.9308 14.652 15.6044 14.5892 15.2602L13.6808 10.2572L17.526 6.71418C17.6306 6.61724 17.6997 6.49064 17.7207 6.35216C17.7772 6.00597 17.5218 5.68549 17.1555 5.63406Z" fill="#FFBA0A"></path></svg></span>
                                            </div>
                                            <ProgressBar variant="primary" now={10} />
                                            <div>
                                                43.3K
                                            </div>
                                        </div>

                                    </div> */}
                                </div>
                            </div>

                            {/* <h6 className='travel_gallary_title'>{t("TravellerGallery")}</h6>
                            <div className='row g-2'>
                                <div className='col-lg-6 col-sm-6'>
                                    <div className='tour_media-1'>
                                        <img className='w-100 rounded' src={dubaiImage} alt="" />
                                    </div>
                                </div>
                                <div className='col-lg-6 col-sm-6'>
                                    <div className='row g-2'>
                                        <div className="col-lg-6 col-6 ">
                                            <div className='tour_media-1'>
                                                <img className='w-100 rounded' src={dubaiImage} alt="" />
                                            </div>

                                        </div>
                                        <div className="col-lg-6 col-6">
                                            <div className='tour_media-1'>
                                                <img className='w-100 rounded' src={dubaiImage} alt="" />
                                            </div>

                                        </div>
                                        <div className="col-lg-6 col-6">
                                            <div className='tour_media-1'>
                                                <img className='w-100 rounded' src={dubaiImage} alt="" />
                                            </div>

                                        </div>
                                        <div className="col-lg-6 col-6 ">
                                            <div className='tour_media-1'>
                                                <img className='w-100 rounded' src={dubaiImage} alt="" />
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div> */}


                            {/* review */}
                            {/* <div className='people_review_box mt-3'>
                                <div className='review_header'>
                                    <div className='d-flex align-items-center'>
                                        <div className='review_media_1'>
                                            <img src={dubaiImage} alt="" />
                                        </div>
                                        <div className='review_content_1'>
                                            <h6>Vinaya Arasu</h6>
                                            <small>Reviewed: 04 Oct 2023</small>
                                        </div>
                                    </div>
                                    <div class="ReviewCard_rightSection___2gjc ms-auto"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.4829 5.63406L10.6864 4.90408L8.54222 0.352135C8.48365 0.227506 8.38731 0.126615 8.26829 0.0652895C7.96981 -0.0890138 7.6071 0.0395723 7.45786 0.352135L5.3137 4.90408L0.517213 5.63406C0.384974 5.65384 0.26407 5.71912 0.171503 5.81803C0.0595945 5.93848 -0.0020722 6.10053 5.31712e-05 6.26856C0.00217854 6.4366 0.0679221 6.59688 0.182838 6.71418L3.65316 10.2572L2.83328 15.2602C2.81405 15.3766 2.82635 15.4963 2.86878 15.6057C2.91121 15.7151 2.98207 15.8099 3.07333 15.8793C3.16459 15.9488 3.27259 15.99 3.38509 15.9984C3.4976 16.0068 3.6101 15.982 3.70983 15.9269L8.00004 13.5648L12.2902 15.9269C12.4074 15.9922 12.5434 16.0139 12.6737 15.9902C13.0024 15.9308 13.2235 15.6044 13.1668 15.2602L12.3469 10.2572L15.8172 6.71418C15.9117 6.61724 15.974 6.49064 15.9929 6.35216C16.0439 6.00597 15.8135 5.68549 15.4829 5.63406Z" fill="#19AD6F"></path></svg><span>5.0/5</span></div>
                                </div>
                                <div className='review_body'>
                                    <span>Booked: <a href="#"> Wonders of Dubai | Journey through Jewels of the Emirates<svg width="16" height="16" className='ms-2' viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 7L14 2M14 2H10.6667M14 2V5.33333M14 9.33333V12.6667C14 13.0203 13.8595 13.3594 13.6095 13.6095C13.3594 13.8595 13.0203 14 12.6667 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V3.33333C2 2.97971 2.14048 2.64057 2.39052 2.39052C2.64057 2.14048 2.97971 2 3.33333 2H6.66667" stroke="#202020" stroke-linecap="round" stroke-linejoin="round"></path></svg> </a> </span>
                                    <div className='mt-3'>
                                        {isExpanded ? (
                                            <p>
                                                A mesmerizing trip to Dubai was very well organized by the Om Shankar team.
                                                Our heartfelt thanks to Destination Expert Chirayu Sharma and Yash for their continuous support for our memorable trip.
                                                The itinerary was well planned and executed by the team. I would surely recommend Om Shankar to all my friends and family for future wonderful trip experiences.
                                                Thank you Team Om Shankar!
                                                <button onClick={toggleReadMore} style={{ marginLeft: '8px', color: '#515151', fontWeight: "500", border: 'none', background: 'transparent', cursor: 'pointer' }}>
                                                    Read Less
                                                </button>
                                            </p>
                                        ) : (
                                            <p>
                                                A mesmerizing trip to Dubai was very well organized by the Om Shankar team.
                                                Our heartfelt thanks to Destination Expert Chirayu Sharma and Yash for their continuous support for our memorable trip.
                                                <span style={{ marginLeft: '8px' }}>...</span>
                                                <button onClick={toggleReadMore} style={{ color: '#515151', fontWeight: "500", border: 'none', background: 'transparent', cursor: 'pointer' }}>
                                                    Read More
                                                </button>
                                            </p>
                                        )}
                                    </div>
                                    <LightGallery
                                        plugins={[lgThumbnail, lgZoom]}
                                        speed={500}
                                        elementClassNames="custom-gallery"
                                    >
                                        {images.map((image, index) => (
                                            <a key={index} href={image.src} data-sub-html={`<h4>${image.alt}</h4>`}>
                                                <img
                                                    src={image.thumb}
                                                    alt={image.alt}
                                                    style={{
                                                        margin: '10px',
                                                        borderRadius: '8px',
                                                        cursor: 'pointer',
                                                        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.2)',
                                                    }}
                                                />
                                            </a>
                                        ))}
                                    </LightGallery>
                                </div>

                            </div> */}
                            {/* <div className='load_more'>
                                <button className='btn btn-outline-primary'> {t("Reviews")} (54774+)</button>
                            </div>
                            <div class="seprator-gradend"></div> */}
                            {/* curted More Love */}

                            {/* <div className='more_box'>
                                <div className='row'>
                                    <div className='col-lg-3 col-md-3 col-6'>
                                        <div className='more_media'>
                                            <img src={HeartImage} style={{ width: '100px' }} alt="" />
                                        </div>
                                        <div className='more_content'>
                                            <h6>{t("Million")}</h6>
                                            <p>{t("Happycustomers")}</p>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 col-md-3 col-6'>
                                        <div className='more_media'>
                                            <img src={messageImage} style={{ width: '100px' }} alt="" />
                                        </div>
                                        <div className='more_content'>
                                            <h6>{data.review_score} {t("review_score")}</h6>
                                            <p>{t("Cumulativeratings")}</p>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 col-md-3 col-6'>
                                        <div className='more_media'>
                                            <img src={HeartImages} style={{ width: '100px' }} alt="" />
                                        </div>
                                        <div className='more_content'>
                                            <h6>{t("Curatedlove")}</h6>
                                            <p>{t("Expertguided")}</p>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 col-md-3 col-6'>
                                        <div className='more_media'>
                                            <img src={HeartPhone} style={{ width: '100px' }} alt="" />
                                        </div>
                                        <div className='more_content'>
                                            <h6>24*7 {t("Support")}</h6>
                                            <p>{t("Wearealways")}</p>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            {/* <div class="seprator-gradend"></div> */}
                            {/* <div className='policy'>
                                <Accordion defaultActiveKey="3">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>{t("Moreon")} {data?.address}</Accordion.Header>
                                        <Accordion.Body>
                                            <ul>
                                                <li>{t("totaltour")}</li>
                                            </ul>
                                        </Accordion.Body>
                                    </Accordion.Item>

                                </Accordion>

                            </div> */}
                            {/* <div class="seprator-gradend style-1"></div>
                            <div className='policy'>
                                <Accordion defaultActiveKey="3">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>{t("ConfirmationPolicy")}</Accordion.Header>
                                        <Accordion.Body>
                                            <ul>
                                                <li>{t("totaltour")}</li>
                                            </ul>
                                        </Accordion.Body>
                                    </Accordion.Item>

                                </Accordion>

                            </div> */}


                            {/* <div class="seprator-gradend style-1"></div> */}
                            {/* <div className='policy'>
                                <Accordion defaultActiveKey="3">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>{t("RefundPolicy")} </Accordion.Header>
                                        <Accordion.Body>
                                            <ul>
                                                <li>{t("totaltour")}</li>
                                            </ul>
                                        </Accordion.Body>
                                    </Accordion.Item>

                                </Accordion>

                            </div>
                            <div class="seprator-gradend style-1"></div> */}
                        </div>

                        {/* <div className='policy'>
                            <Accordion defaultActiveKey="3">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>{t("CancellationPolicy")}</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>{t("totaltour")}</li>
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>

                            </Accordion>

                        </div> */}


                        {/* <div class="seprator-gradend style-1"></div>
                        <div className='policy'>
                            <Accordion defaultActiveKey="3">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>{t("PaymentTermPolicy")}</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>{t("totaltour")}</li>
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>

                            </Accordion>

                        </div> */}



                    </div>
                </div>
            </div>








            <Offcanvas className="custom-offanvas" show={show} onHide={handleClose}>
                <div className=''>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title></Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Tabs
                            defaultActiveKey="home"
                            id="uncontrolled-tab-example"
                            className="mb-3 details_tabs"
                        >
                            <Tab eventKey="home" title={t("AllImages") + " (" + Media.length + ")"}>
                                <div className='images_details'>
                                    {Media.length > 0 ?
                                        Media.map((item) => {
                                            return (
                                                <>
                                                    <div className='images_details_media'>
                                                        <img src={item.original_url} alt="" />
                                                    </div>
                                                </>
                                            )
                                        }) : ""}


                                </div>
                            </Tab>
                            <Tab eventKey="profile" title={t("Destination") + " (" + Media.length + ")"}>
                                <div className='images_details'>
                                    {Media.length > 0 ?
                                        Media.map((item) => {
                                            return (
                                                <>
                                                    <div className='images_details_media'>
                                                        <img src={item.original_url} alt="" />
                                                    </div>
                                                </>
                                            )
                                        }) : ""}
                                </div>
                            </Tab>
                            <Tab eventKey="profile-1" id="uncontrolled-tab-example"
                                className="mb-3 details_tabs" title={t("Activities") + " (" + Media.length + ")"}>
                                <div className='images_details'>
                                    {Media.length > 0 ?
                                        Media.map((item) => {
                                            return (
                                                <>
                                                    <div className='images_details_media'>
                                                        <img src={item.original_url} alt="" />
                                                    </div>
                                                </>
                                            )
                                        }) : ""}
                                </div>
                            </Tab>
                            <Tab eventKey="profile-2" id="uncontrolled-tab-example"
                                className="mb-3 details_tabs" title={t("stay") + " (" + Media.length + ")"}>
                                <div className='images_details'>
                                    {Media.length > 0 ?
                                        Media.map((item) => {
                                            return (
                                                <>
                                                    <div className='images_details_media'>
                                                        <img src={item.original_url} alt="" />
                                                    </div>
                                                </>
                                            )
                                        }) : ""}
                                </div>
                            </Tab>

                        </Tabs>
                    </Offcanvas.Body>
                </div>
            </Offcanvas>

            <Modal className='normal_model' show={show1} onHide={handleClose1}>
                <Modal.Header className='border-0' closeButton>
                    <Modal.Title>
                        <h6 className='mb-0'>Save up to<span>50% OFF</span></h6>
                        <small className='LeadForm_discountHelpText__AbNHL'>We’ll be needing some of your basic details to help you better with your trip</small>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='pt-0'>
                    <div className='row'>
                        <div className='col-lg-12 custom_tabs_content style-1'>

                            <Form className={Name ? "d-none" : 'mt-3'} onSubmit={handleSumbit}>
                                <div className='row gx-2'>

                                    <Form.Group controlId="exampleForm.ControlInput1">
                                        <Form.Control type="text" className='form-control-lg' placeholder={t("Names")} name='name' value={SinForm.name} onChange={handleSelectChange} />
                                    </Form.Group>
                                    <Form.Group controlId="exampleForm.ControlInput2">
                                        <Form.Control type="email" className='form-control-lg' name='email' placeholder={t("Emails")} value={SinForm.email} onChange={handleSelectChange} />
                                    </Form.Group>
                                    <div className='col-lg-4'>
                                        <Select
                                            options={options}
                                            // value={selectedOption}

                                            placeholder="+91"
                                            name='Mobail9'

                                        />
                                    </div>
                                    <div className='col-lg-8'>
                                        <Form.Group controlId="exampleForm.ControlInput2">
                                            <Form.Control type="number" className='form-control-lg' placeholder={t("number")} value={SinForm.Phone} name='Phone' onChange={handleSelectChange} />
                                        </Form.Group>
                                    </div>
                                    <div className='col-lg-6'>
                                        <Form.Group controlId="exampleForm.ControlInput1">
                                            <Form.Control type="date" className='form-control-lg' placeholder="" value={SinForm.Startdate} name='Startdate' onChange={handleSelectChange} />
                                        </Form.Group>
                                    </div>
                                    <div className='col-lg-6'>
                                        <Form.Group controlId="exampleForm.ControlInput2">
                                            <Form.Control type="number" className='form-control-lg' placeholder={t("persons")} value={SinForm.person} name="person" onChange={handleSelectChange} />
                                        </Form.Group>
                                    </div>
                                </div>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1" >

                                    <Form.Control as="textarea" rows={4} value={SinForm.texteraa} onChange={handleSelectChange} name='texteraa' placeholder={t("comment")} />
                                </Form.Group>
                                <button className='btn btn-primary'> {t("SendEnquiry")}</button>


                            </Form>

                        </div>
                    </div>
                    <div className={Name === '' ? "d-none vaction_tour" : "vaction_tour"}>
                        <div className='d-flex'>
                            <div className='vaction_icon'>
                                <FaCheck />
                            </div>
                            <div style={{ lineHeight: "1" }} >
                                <span className='fs-13 d-block font-w400'>{t("Awesome")}<strong className='text-orange'>{Name}</strong> </span>
                                <small className='fs-10 text-small'>{t("personalisedquotation")}</small>
                            </div>
                        </div>
                        <div className='th-lf-cps-divider'></div>
                        {/* trip_image_strat */}
                        <div className={withs === 0 ? 'row g-2' : 'row g-2 d-none'}>
                            <h6 className='vaction-title '>{t("vacationwith")}</h6>
                            <div className='col-lg-6'>
                                <div className='tour_slected'>
                                    <form>
                                        <label className="form-check-label" htmlFor="exampleCheck-5">
                                            <div className="form-check ps-0">
                                                <span className='form-check-label'>
                                                    <img src={vactionImage} className='w-100' alt="" />
                                                </span>
                                                <input type="checkbox" className="form-check-input" id="exampleCheck-5" name='Solo' onClick={() => handleWith(1)} />

                                            </div>
                                            <div className='text-center mt-2'>
                                                <h6 className='mb-0 fs-14 text-small'>{t("Solo")}</h6>
                                            </div>
                                        </label>

                                    </form>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='tour_slected'>
                                    <form>
                                        <label className="form-check-label" htmlFor="exampleCheck-5">
                                            <div className="form-check ps-0">
                                                <span className='form-check-label'>
                                                    <img src={vactionImage} className='w-100' alt="" />
                                                </span>
                                                <input type="checkbox" className="form-check-input" id="exampleCheck-5" name='Spouse' onClick={() => handleWith(2)} />
                                                <div className='text-center mt-2'>
                                                    <h6 className='mb-0 fs-14 text-small'>{t("Spouse")}</h6>
                                                </div>
                                            </div>
                                        </label>

                                    </form>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='tour_slected'>
                                    <form>
                                        <label className="form-check-label" htmlFor="exampleCheck-5">
                                            <div className="form-check ps-0">
                                                <span className='form-check-label'>
                                                    <img src={vactionImage} className='w-100' alt="" />
                                                </span>
                                                <input type="checkbox" className="form-check-input" id="exampleCheck-5" name='Family' onClick={() => handleWith(3)} />

                                            </div>
                                            <div className='text-center mt-2'>
                                                <h6 className='mb-0 fs-14 text-small'>{t("Family")}</h6>
                                            </div>
                                        </label>

                                    </form>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='tour_slected'>
                                    <form>
                                        <label className="form-check-label" htmlFor="exampleCheck-5">
                                            <div className="form-check ps-0">
                                                <span className='form-check-label'>
                                                    <img src={vactionImage} className='w-100' alt="" />
                                                </span>
                                                <input type="checkbox" className="form-check-input" id="exampleCheck-5" name='Friends' onClick={() => handleWith(4)} />
                                                <div className='text-center mt-2'>
                                                    <h6 className='mb-0 fs-14 text-small'>{t("Friends")}</h6>
                                                </div>
                                            </div>
                                        </label>

                                    </form>
                                </div>
                            </div>
                        </div>
                        {/* trip_image_end */}
                        {/* fixed_date_strat */}
                        <div className={withs ? !fixed ? 'row g-2' : 'row g-2 d-none' : 'row g-2 d-none'}>
                            <h6 className='vaction-title '>{t("traveldate")}</h6>
                            <div className='col-lg-12'>
                                <div className='date_slected'>
                                    <form>
                                        <label className="form-check-label" htmlFor="exampleCheck-8">
                                            <div className="form-check ">
                                                <span className='form-check-label'>
                                                    {t("DateFixed")}
                                                </span>
                                                <input type="checkbox" className="form-check-input" id="exampleCheck-8" name='Date' onClick={() => handlefixd(1)} />

                                            </div>

                                        </label>

                                    </form>
                                </div>
                                <div className='date_slected'>
                                    <form>
                                        <label className="form-check-label" htmlFor="exampleCheck-8">
                                            <div className="form-check ">
                                                <span className='form-check-label'>
                                                    {t("flexible")}
                                                </span>
                                                <input type="checkbox" className="form-check-input" id="exampleCheck-8" name='travel' onClick={() => handlefixd(2)} />

                                            </div>

                                        </label>

                                    </form>
                                </div>
                                <div className='date_slected'>
                                    <form>
                                        <label className="form-check-label" htmlFor="exampleCheck-8">
                                            <div className="form-check ">
                                                <span className='form-check-label'>
                                                    {t("exploring")}
                                                </span>
                                                <input type="checkbox" className="form-check-input" id="exampleCheck-8" name='exploring' onClick={() => handlefixd(3)} />

                                            </div>

                                        </label>

                                    </form>
                                </div>
                            </div>

                        </div>
                        {/* fixed_date_end */}
                        {/* book_date_strat */}

                        <div className={fixed ? !trip ? 'row g-2' : 'row g-2 d-none' : 'row g-2 d-none'}>
                            <h6 className='vaction-title '>{t("thistrip")}</h6>
                            <div className='col-lg-12'>
                                <div className='date_slected'>
                                    <form>
                                        <label className="form-check-label" htmlFor="exampleCheck-8">
                                            <div className="form-check ">
                                                <span className='form-check-label'>
                                                    {t("WithinHours")}
                                                </span>
                                                <input type="checkbox" className="form-check-input" id="exampleCheck-8" name='Within' onClick={() => handletrip(1)} />

                                            </div>

                                        </label>

                                    </form>
                                </div>
                                <div className='date_slected'>
                                    <form>
                                        <label className="form-check-label" htmlFor="exampleCheck-8">
                                            <div className="form-check ">
                                                <span className='form-check-label'>
                                                    {t("WithinHour")}
                                                </span>
                                                <input type="checkbox" className="form-check-input" id="exampleCheck-8" name='days' onClick={() => handletrip(2)} />

                                            </div>

                                        </label>

                                    </form>
                                </div>
                                <div className='date_slected'>
                                    <form>
                                        <label className="form-check-label" htmlFor="exampleCheck-8">
                                            <div className="form-check ">
                                                <span className='form-check-label'>
                                                    {t("WithinHou")}
                                                </span>
                                                <input type="checkbox" className="form-check-input" id="exampleCheck-8" name='week' onClick={() => handletrip(3)} />

                                            </div>

                                        </label>

                                    </form>
                                </div>
                                <div className='date_slected'>
                                    <form>
                                        <label className="form-check-label" htmlFor="exampleCheck-8">
                                            <div className="form-check ">
                                                <span className='form-check-label'>
                                                    {t("WithinHo")}
                                                </span>
                                                <input type="checkbox" className="form-check-input" id="exampleCheck-8" name='Reaseaching' onClick={() => handletrip(4)} />

                                            </div>

                                        </label>

                                    </form>
                                </div>
                            </div>

                        </div>
                        {/* book_date_end */}
                        {/* flight _strat */}

                        <div className={trip && !tickets ? 'row g-2' : 'row g-2 d-none'}>
                            <h6 className="vaction-title">{t("tickets")}</h6>

                            {/* Flight tickets booked option */}
                            <div className="col-lg-12">
                                <div className="date_slected">
                                    <form>
                                        <label className="form-check-label" htmlFor="exampleCheck-8">
                                            <div className="form-check">
                                                <span className="form-check-label">
                                                    {t("booked")}
                                                </span>
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="exampleCheck-8"
                                                    name="booked"
                                                    onClick={() => handletickets(1)}
                                                />
                                            </div>
                                        </label>
                                    </form>
                                </div>

                                {/* Need help in booking flight tickets option */}
                                <div className="date_slected">
                                    <form>
                                        <label className="form-check-label" htmlFor="exampleCheck-8">
                                            <div className="form-check">
                                                <span className="form-check-label">
                                                    {t("helpbooking")}
                                                </span>
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="exampleCheck-8"
                                                    name="flight"
                                                    onClick={() => handletickets(2)}
                                                />
                                            </div>
                                        </label>
                                    </form>
                                </div>

                                {/* Will book flight tickets on my own option */}
                                <div className="date_slected">
                                    <form>
                                        <label className="form-check-label" htmlFor="exampleCheck-8">
                                            <div className="form-check">
                                                <span className="form-check-label">
                                                    {t("bookflight")}
                                                </span>
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="exampleCheck-8"
                                                    name="book"
                                                    onClick={() => handletickets(3)}
                                                />
                                            </div>
                                        </label>
                                    </form>
                                </div>
                            </div>
                        </div>


                        <div className={tickets ? "thank_box" : 'd-none'}>
                            <div className="vaction_icon style-1">
                                <FaCheck />
                            </div>
                            <div>
                                <h6 className="mt-3" style={{ fontWeight: '600' }}>
                                    {t("thank")} <span className="text-orange">{Name}</span>
                                </h6>
                                <p className="mb-0 fs-14">
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ea libero reprehenderit necessitatibus blanditiis.
                                </p>
                            </div>
                        </div>

                        {/* book_date_end */}
                    </div>
                </Modal.Body>
                {/* <Modal.Footer>
                    
                    
                </Modal.Footer> */}
            </Modal>


        </section >

    );
}

export default Tour;
