import React from 'react';
import "../assets/style.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../src/assets/chardham.png';

const AccountDetails = () => {
    return (
        <>
            <div class="card">
                <h1>Om Shiv Shankar Tirth Yatra</h1>
                <p><strong>For payments and transactions, please find our official bank details below:</strong></p>

                <h2>🏦 Bank Account Details</h2>
                <p><span class="highlight">Account Name:</span> Om Shiv Shankar Tirth Yatra</p>
                <p><span class="highlight">Bank Name:</span> State Bank of India</p>
                <p><span class="highlight">Branch:</span> Malviya Nagar</p>
                <p><span class="highlight">Account Number:</span> 43312198408</p>
                <p><span class="highlight">IFSC Code:</span> SBIN0017670</p>

                <h2>💳 Payment Methods</h2>
                <ul>
                    <li>Online Bank Transfer</li>
                    <li>UPI (9460938009)</li>
                    <li>Cheque Deposit (Payable to Om Shiv Shankar Tirth Yatra)</li>
                    <li>Cash Deposit (As per company policy)</li>
                </ul>

                <div class="contact">
                    <h2>📞 Contact</h2>
                    <p><span class="highlight">Phone/WhatsApp:</span> 9460938009</p>
                    <p><span class="highlight">Email:</span> omshankartirthyatra@gmail.com</p>
                </div>

                <div class="note">
                    🔹 <strong>Note:</strong> Kindly share the transaction receipt after making the payment for verification.
                </div>
            </div>
        </>
    )
}
export default AccountDetails;