import React, { useState } from "react";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';

import dwarkaPuriImage from "../assets/images/dwarka-puri-dham-tirth-yatra.jpg";
import { useTranslation } from "react-i18next";
function Carrier() {
    const { t, i18n } = useTranslation();
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        message: "",
    });

    // Handle input changes
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Form Submitted:", formData);
        alert("Form Submitted Successfully!");
    };
    return (
        <section className='carrior_main' style={{
            backgroundImage: `url(${dwarkaPuriImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            minHeight: "500px",

        }}
        >
            <div className='container'>
                <div className='main_yatra_banner'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className='left_carrior_content'>
                                <h1>{t("omshiv")}</h1>
                                <p>{t("BestServices")}</p>

                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <form
                                className="section__advertise-intro-form"
                                id="advertise-enquiry-form"
                                onSubmit={handleSubmit}
                            >
                                <div className="section__advertise-intro-form__header">
                                    Let's Talk
                                    <span className="close-form-button icon-cancel-music advertisement-form-button desktop-hidden"></span>
                                </div>

                                {/* Hidden Fields */}
                                <input type="hidden" name="platform_lead[lead_type]" value="advertisement_lead" />
                                <input type="hidden" name="platform_lead[new_ui]" value="true" />

                                <div className="section__advertise-intro-form__inputs">
                                    {/* Name */}
                                    <div className="section__advertise-intro-form__inputs-input-wrapper">
                                        <input
                                            className="section__advertise-intro-form__inputs-input advertisement-form-input"
                                            required
                                            type="text"
                                            name="name"
                                            placeholder={t("Names")}
                                            value={formData.name}
                                            onChange={handleChange}
                                        />

                                    </div>

                                    {/* Email */}
                                    <div className="section__advertise-intro-form__inputs-input-wrapper">

                                        <input
                                            className="section__advertise-intro-form__inputs-input advertisement-form-input"
                                            required
                                            type="email"
                                            name="email"
                                            placeholder={t("Emails")}
                                            value={formData.email}
                                            onChange={handleChange}
                                        />

                                    </div>

                                    {/* Contact Number */}
                                    <div className="section__advertise-intro-form__inputs-input-wrapper">
                                        <input
                                            className="section__advertise-intro-form__inputs-input advertisement-form-input"
                                            required
                                            type="text"
                                            name="phone"
                                            pattern="[0-9+]+"
                                            value={formData.phone}
                                            placeholder={t("PhoneNumber")}
                                            onChange={handleChange}
                                        />

                                    </div>

                                    {/* Company Name */}





                                    {/* Message */}
                                    <div className="section__advertise-intro-form__inputs-input-wrapper">
                                        <input
                                            className="section__advertise-intro-form__inputs-input advertisement-form-input"
                                            type="text"
                                            name="message"
                                            placeholder={t("sandesh")}
                                            value={formData.message}
                                            onChange={handleChange}
                                        />

                                    </div>

                                    {/* Submit Button */}
                                    <div className="section__advertise-intro-form__inputs-input-wrapper">
                                        <input
                                            type="submit"
                                            value={t("SendEnquiry")}
                                            className="section__advertise-intro-form__inputs-input advertisement-form-input advertisement-enquiry-submit-button"
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default Carrier