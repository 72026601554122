const en = {
    translation: {
        hello: "Language",
        inr:"INR",
        days:'Days in',
        StayCategory:"Stay Category",
        TripHighlights:"Trip Highlights",
        DestinationRoutes:"Destination Routes",
        SendEnquiry:"Send Enquiry",
        ChooseTrip :"Choose Trip Duration",
        StartingFrom:"Starting From",
        AllImages:"View All Images",
        Itinerary:"Itinerary",
        SummarisedView:" Summarised View",
        Activities:"Activities",
        stay:"stay",
        Transfers:"Transfers",
        Whatsinsidethepackage:"What’s inside the package?",
        Inclusions:"Inclusions",
        Exclusions:"Exclusions",
        RelatedProducts:"Related Products",
        Reviews:" Reviews",
        TravellerGallery:"Traveller Image Gallery",
        Million:"10 Million",
        Happycustomers:"Happy customers from 65+ countries all around.",
        Cumulativeratings:"Cumulative ratings of our trips across platforms.",
        Curatedlove:"Curated with love",
        Expertguided:"Expert-guided trips with meticulous planning.",
        Wearealways:"We are always there to help you pre, post and on the trip.",
        Moreon:"More on",
        totaltour:"100.0% of total tour cost will have to be paid 0 days before the date of booking",
        ConfirmationPolicy:"Confirmation Policy",
        RefundPolicy:"Refund Policy",
        CancellationPolicy:"Cancellation Policy",
        PaymentTermPolicy:"Payment Term Policy",
        save:"SAVE",
        PerAdult:"Per Adult",
        Destination:"Destination",
        Sightseeing:"Activity & Sightseeing",
        RequestCallback:"Request Callback",
        OFF:"50% OFF",
        needing:"We’ll be needing some of your basic details to help you better with your trip",
        anExperts:"Connect With an Experts",
        Awesome:"Awesome",
        personalisedquotation:"Couple of more details to a personalised quotation",
        vacationwith:"Who are you planning this vacation with?",
        Solo:"Solo",
        Spouse:"Spouse",
        Family:"Family",
        Friends:"Friends",
        traveldate:'Is your travel date fixed?',
        DateFixed:"Date Fixed",
        flexible:"My travel dates are flexible upto 15",
        exploring:"I’m just exploring as of now",
        thistrip:"How soon would you like to book this trip?",
        WithinHours:"Within 48 Hours",
        WithinHour:"3 से 4 दिन के अंदर",
        WithinHou:"Within a week",
        WithinHo:'Just Reaseaching',
        tickets:"Have you booked your flight tickets?",
        booked:"My flight tickets are booked.",
        helpbooking:"I also need help in booking flight tickets.",
        bookflight:"No, I will book flight tickets on my own.",
        thank:"Thank you",
        MyProfile:"My Profile",
        edit:"Edit Basic Profile",
        change:"Change Phone Number",
        password:"Change Password",
        Name:"Name",
        Email:"Email",
        Phone:"Phone",
        Address:"Address",
        Edit:"Edit",
        City:"City",
        Country:"Country",
        State:"State",
        ZipCode:"Zip Code",
        Saves:"Save",
        CountryCode:"Country Code",
        PhoneNumber:'Phone Number',
        CurrentPassword:"Current Password",
        NewPassword:"New Password",
        ConfirmPassword:"Confirm Password",
        Cancel:"Cancel",
        Update:"Update",
        FirstName:"First Name",
        LastName:"Last Name",
        MyBookings:"My Bookings",
        MyQuotation:"My Quotation",
        MyWallet:"My Wallet",
        MyPassengers:'My Passengers',
        SupportFAQS:"Support & FAQS",
        Passengersadded:"No Passengers added",
        AddNew:"Add New",
        AdPassenger:"Add New Passenger",
        Gender:"Gender",
        Birth:"Date Of Birth",
        SAVEDETAILS:"SAVE DETAILS",
        Delete:"Delete",
        WalletHistory:"Wallet History",
        transactionswallet:'Seems you have no transactions in wallet',
        FAQs:"FAQs",
        bookingtours:"I am interested in booking tours and activities. How can I talk to someone?",
        bookingtour:"We are glad to know that you are interested to book with us. In order to get in touch with our representative go to the home page of Thrillophilia and search for the destination you like and tap on request callback. Our team will get back to you.",
        visa:"Do you provide Visa Assistance?",
        visas:"Yes, for the multi-day tours and holiday packages booked by us we do assist our customers in visa procurement.",
        Thrillophilia:"What are the variety of products and services provided by Thrillophilia?",
        Thrillophili:"Thrillophilia is the country’s most thrilling name when it comes to activities and experiences. We offer short activities, multi-day tours, comprehensive holiday pakcages, across the globe.",
        paasss:"How to change my password?",
        paass:"Yes, for the multi-day tours and holiday packages booked by us we do assist our customers in visa procurement.",
        number:"Type Mobile Numner",
        Emails:"Type Vaild Email",
        Names:"Type Your Name",
        persons:"Person",
        comment:"Comment type",
        LOgins:'Login',
        Logout:"Logout",
        Sign:"Sign up",
        YourAccount:"Log into Your Account",
        ForgetPassword:"Forget Password?",
        LoginContinue:"Login & Continue",
        ForgottenPassword:"Forgotten Your Password?",
        resetyour:"Don’t worry, we’ll send you a message to help you reset your password.",
        Continue:"Continue",
        passwords:"Password Type",
        Returnto:"Return to",
        CreateYourAccount:'Create Your Account',
        PrivacyPolicy:"By joining, you agree to the Terms and Privacy Policy.",
        Already :"Already Have An Account?",
        Tour:"Tour",
        ProductType:"Product Type",
        UptoDay:"Upto 1 Day",
        UptoDays:"2 to 3 days",
        UptoDayss:"3 to 5 days",
        UptoDaysss:"5 to 7 days",
        UptoDayssss:"7+ Days",
        PriceRange:"Price Range ",
        ClearAll:"Clear All",
        SearchProducts:"Search For Products",
        ViewAll:"View All",
        SearchHere:"Search Here",
        helpyou:"Hi, how can we help you?",
        searchterm:"Enter the search term here....",
        Browsearticles:"Browse articles",
        Browsearticlese:"Explore How-To's and learn best practices from our knowledge base",
        Submiticket:"Submit a ticket",
        Submitickets:"Describe your issue by filling out the support ticket form",
        Knowledgebase:"Knowledge base",
        Knowledgebases:"View all articles",
        sankar:"Elevate Your Support Experience with OM shankar!",
        sankars:"Here’s your ultimate video guide to mastering your bookings through",
        Related:"Customer Related Queries",
        Relateds:"Frequently asked queries and relevant solution for your quick assistance",
        Affiliation:"Affiliation &amp; Coverage Related Queries",
        popular:"Most popular articles",
        allarticles:"View all articles",
        query:"How to connect over the phone/Email for query/support?",
        Modified :" Modified on Wed, 19 Feb, 2020 at  6:32 PM",
        site:"How to book a tour on the site.",
        Modifieds:"Modified on Wed, 22 Sep, 2021 at  2:11 PM",
        received:"I booked online and have not received confirmation email voucher or any other details.",
        Modifiedse:" Modified on Thu, 18 Apr, 2019 at  7:11 PM",
        cancellation:"What are the cancellation policies at OM shankar?",
        Modifiedt:"Modified on Tue, 25 May, 2021 at  5:31 PM",
        Tirthyatra:"Om shankar Tirthyatra",
        Shapingthe:"Shaping the",
        Shapingth:"Future Of Travel!",
        Shapingths:"Changing the Way People Travel the World",
        Our:"Our",
        Mission:"Mission",
        Missions:"At Om shivShankar tirthyatra, we're on a grand quest to transform the landscape of travel experiences. Our aspiration goes beyond destinations—we endeavor to spark passion, carve everlasting memories, and lead the way in crafting adventures that echo in the hearts of every explorer. Join us on this epic journey, where the essence of adventure is infused into every expedition!",
        Namese:"Chitra Gurnani Daga",
        ceo:'CEO & Co-Founder',
        Namess:'Abhishek Daga',
        co:"Co-Founder",
        Meetthe:"Meet the",
        Architects:"Architects of Experiences",
        powers:"We are a power-packed tribe of",
        powerss:"extraordinarily talented & passionate professionals",
        ChitraGurnaniDag:"The Show Runner",
        IdeaBox:"The Idea Box",
        TarunDadlani:"Tarun Dadlani",
        TarunDadlan:"Life of The Party",
        TarunDadlans:"Vice President - Growth",
        AbhishekPuri:"Abhishek Puri",
        AbhishekPur:"Man City Fan",
        RajdeepMandrekar:"Rajdeep Mandrekar",
        RajdeepMandreka:"The Poker Guy",
        RajdeepMandrekas:"Head of Engineering",
        GirdharSinghSodha:"Girdhar Singh Sodha",
        GirdharSinghSodha:"Heavy Driverr",
        GirdharSinghSodhasa:"SEO Manager",
        RamRatanMishra:"Ram Ratan Mishra",
        RamRatanMishraa:"Cognitive Maven",
        RamRatanMishr:"Digital Marketing Manager",
        ChitranshSingh:"Chitransh Singh",
        ChitranshSing:"The Off-Roader",
        ChitranshSin:"Associate Vice President",
        Seeyourselfhere:"See yourself here?",
        JoinUs:"Join Us!",
        Innovation:"Masters of Ideas, Innovation & Growth",
        guide :"Meet the leaders of OM Shankar who guide the path of innovation. They keep infusing each task with a touch of brilliance, a dash of humour, and a spark of unconventional genius. From tech wizards to sales dynamos, they're the architects of a workplace where every day is a thrilling journey. Come, join us and celebrate the spontaneous, the surprising, and the unmatched expertise that make this team truly - extraordinary.",
        omshiv:"om shiv sankar",
        Thrillophilia:"Journey ?",
        Thrillophili:"Traversing the Path of Adventure, One Remarkable Memory at a Time.",
        Igniting:"Igniting a",
        Vision:"Vision.",
        Infusing:'Infusing',
        launched:"A shared dream of Chitra Gurnani Daga and Abhishek Daga, Thrillophilia was launched in 2011. Within just a year, what began as an online platform went on to become one of India’s most-loved adventure travel service providers. From 2011-13, Thrillophilia continued to expand its horizons and even ventured into the B2B space. The company served 1000+ corporate clients like Google, Cisco, SAP, Microsoft, Mercedes, and many prominent brands. We also catered to 300K+ travellers and positioned ourselves as a leader in corporate travel experiences.",
        Investments:"Investments",
        Breakthroughs:"Breakthroughs",
        significant:"This pivotal year witnessed strategic expansions as well as significant investments that provided Thrillophilia the boost it needed to become the market leader. In the next two years, we ventured into scaling activities and experiences, invested in technology, and launched a B2C app for bookings activities on the go. Over the next three years (2014-17) the growth rate spiked to 200% Year-on-Year. By late 2016, we shifted our base of operations from Bengaluru to Jaipur and expanded the team size to fulfil our growth objectives.",
        Recognition:"A Year of Innovation and Industry Recognition",
        destinations:"With a user base exceeding 3 million, Thrillophilia curated 12,500+ activities across 125+ destinations and emerged as a leader in India's activities &amp; experiences travel market. We received widespread recognition and admiration within the travel industry. Responding to the growing demand for experiential travel, we selected a few Indian destinations to venture into the multi-day tour segment in next two years. The pilot tests fuelled our confidence for this promising category.",
        Thriving:"Thriving Together through an Apocalypse Called COVID",
        embarked:"During the Covid-19 outbreak, while the world stood still, we, at Thrillophilia, embarked on a journey of transformation overhauling our content and technology infrastructure. Our sustained efforts and never-say-die attitude eventually resulted in a 10x increase in the company’s revenue as compared to the pre-Covid years. Recognizing the evolving travel landscape post-pandemic, our team pioneered innovative concepts, leading to the creation of 53 TakeOffs—a house of digital-first travel brands and the current parent company of Thrillophilia. In FY 2022-23, Thrillophilia bought back its 30% stakes from the investors, which gave us complete autonomy and set the stage for a new chapter of innovation and growth.",
        Autonomy:'Scaling New Peaks of Excellence With Full Autonomy',
        unprecedented:"In 2023, Thrillophilia reached unprecedented heights, achieving remarkable milestones in user engagement and global outreach. This year, Thrillophilia entered the international multi-day tour market, opening a “world” of experiences and activities for our customers. We also established strategic partnerships with prestigious tourism boards of destinations like Dubai, Abu Dhabi, Thailand and many more. The introduction of international multi-day tours and the strategic partnerships resulted in a turnover of 500+ crore in 2023. Our next mission is to create a multi-billion dollar tourism business with 53 Takeoffs, establish more than 100 international brands, and generate over 25,000 crores in revenue by 2027.",
        Tirthyatra:"ife At Om ShivShankar Tirthyatra",
        PictureGallery:"Picture Gallery",
        Spotlight:" In The Spotlight",
        Adventures:"Explore What the World is Saying About Our Adventures and Experiences",
        Funding:"A Tale of Success: 53 Takeoffs Organic Growth to Om shivShankar tirthyatra 's Funding Saga",
        Fundings:"53Takeoffs soared to become India's leading multi-day tours company within three years, serving 800,000+ travelers in 2023. Reporting a turnover of 500+ crores & a 2.5x surge from the previous year, they also reclaimed control of their vision by buying back investor shares in Om shivShankar tirthyatra .",
        Insights:"Insights, Inspirations &",
        Impact:"Impact",
        Stories:"Dive into a World of Ideas, Stories, News and Inspiration",
        Specially:"An Inspiring Story of a Specially-Abled Couple’s Travel to Ladakh",
        outperform:"Sometimes, all we need in our work life is one such person who will push us to outperform ourselves in any situation. Almost as a wish come true, we have been blessed with Chitra Gurnani Daga, the raison d’etre of Om shivShankar tirthyatra , to help & guide us along the stepping stones to success.",
        Takeoffs:"Takeoffs",
        focusing:'is a house of digital-first travel brands, focusing on becoming a powerful travel conglomerate by growing its tours vertical and incubating new travel brands',
        consistent:"Currently catering to customers across 75+ countries, the firm is leveraging its proficiency in online travel, technology, consumer understanding and distribution. One thing that remains consistent across its brands is delivering the best travel experiences for the customers. The brands which are run as of now include  Om shivShankar tirthyatra, Couple Escapes, Rare Safaris and MyTravelPass",
        conglomerate:"is set to become a multi-billion dollar travel conglomerate in the next 5 years, with a mission to create 100+ sizable travel brands across the globe by",
        OurPresence:"Our Presence",
        JaipurIndia:"Jaipur,India",
        JaipurIndias:"Sitapura Industrial Area, Sitapura, Jaipur, Rajasthan",
        UAE:"Dubai, UAE",
        UAe:"Dubai Silicon Oasis, DDP, Building A, Dubai, United Arab Emirates",
        Delaware:"Delaware, USA",
        Delawar:"Suite 403-B, 1013 Centre Road, Wilmington, New Castle, Delaware",
        Future:"Let’s Build the Future of Travel",
        TOGETHER:"TOGETHER",
        ambitious:"Om shivShankar tirthyatra is not just a career opportunity; it is the breeding ground for fresh ideas, ambitious individuals, fast-paced culture & unrestricted growth. If you're ready to discover your potential, make a difference, and join a thrilling professional journey - choose us! Click the button crafted just for you, whether you're drawn to marketing, sales, tech or beyond—your adventure awaits",
        Heightsm:"Join Our teaTaking Off Travel Experiences & Technology to New Heightsm",
        Building:"Building",
        explore:"explore",
        Trending:"Trending",
        Male:"Male",
        Female:"Female",
        India:"India",
        USA:"USA",
        Nodatafound:"Not data found",
        Support:'Support',
        review_score:"review",
        Bigger:"Bigger Group? Get special offers up to 50% Off!",
        Download:"dwonload tour",
        cities:"cities",
        ANew:"Choose A New Password",
        PackageCode:"Package Code",
        Traveler:"Traveler",
        TotalAmount:"Total Amount",
        DateofTraveler:'Date of Traveler',
        passwordsss:"Go to my profile page then your password change page will appear you can change the password",
        day:"Day",
        Popular:"Popular Pages",
        booklet:"booklet",
        ClientSay:"Opinion of our customers - About Shiv Shankar Tirtha Yatra",
        GetInTouch:"Get In Touch",
        TirthYatraIndia:"Tirth Yatra India",
        helps:"We conduct tirth yatrain an organized manner in which the pilgrims are provided good quality food, Satsang programmes, medical facilities.",
        sandesh:"Message",
        BestServices:"Chardham Yatra Experience."
        
        


        




        




      }
};

export default en;