import React from 'react';
import "../assets/style.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../src/assets/chardham.png';
import nepal from '../../src/assets/nepal.jpg'

const Nepal = () => {
    return (
        <>
            <div>

                {/* <img className='w-100' src={purinepal1} alt="purinepal.jng" /> */}
            </div>
            <section className='section-padding'>
                <div className='container'>
                    <div className>
                        <div className='row'>
                            <div className='col-lg-12 tabs'>
                                <h1>Nepal Yatra: A Sacred Call
                                </h1>
                                <span>
                                </span> <br />

                                <p classname="about_para">Where mountains touch the endless sky,
                                    And temple bells sing prayers high.
                                    A land so pure, with grace untold,
                                    Where faith and nature strong behold.
                                    The rivers chant, the winds embrace,
                                    A journey blessed with divine grace.
                                    In Nepal’s heart, so calm, so wide,
                                    Find your soul, let peace reside.
                                    Come, walk this path, so bright, so true,
                                    With Om Shiv Shankar Tirth Yatra guiding you. 🚩

                                </p>
                                <br />
                                <h1>Explore the Heart of Nepal with Om Shiv Shankar Tirth Yatra

                                </h1> <br />
                                <p>Nepal, the land of the Himalayas, is not just a place; it's an experience that will touch your soul.
                                     At Om Shiv Shankar Tirth Yatra, we offer an unparalleled journey through Kathmandu, the cultural
                                      and spiritual capital of Nepal. Whether you're seeking divine blessings or a deeper connection with
                                       the rich heritage of this beautiful country, our exclusive Kathmandu tour is the perfect escape.
                                </p>


                                <h3>Why Choose Om Shiv Shankar Tirth Yatra?
                                </h3>

                                <p> 1. Authentic Spiritual Experience: Kathmandu is home to some of the holiest temples and shrines. With us, you'll visit iconic spiritual destinations like the sacred Pashupatinath Temple, the serene Swayambhunath (Monkey Temple), and the peaceful Boudhanath Stupa. Each visit is a chance to experience the vibrant spirituality of Nepal firsthand.
                                </p>
                                <p> 2. Guided Tours with Experts: Our knowledgeable and friendly guides will ensure that you have a deeper understanding of each site, sharing fascinating insights into the history, culture, and significance of each landmark.

                                </p>
                                <p>3. Tailored Itinerary: Whether you're a first-time traveler or a frequent visitor to Nepal, our flexible tour packages are designed to cater to your individual preferences. We ensure a balance between exploration and relaxation, allowing you to fully immerse yourself in the Kathmandu Valley's charm.                    </p>

                                <p> 4. Safety and Comfort: Your comfort is our priority. We provide well-organized transportation, comfortable accommodations, and a team that looks after every detail to ensure you have a worry-free experience.

                                </p>

                                <h5>A Journey You’ll Never Forget
                                </h5>
                                <p>Kathmandu is a city where ancient traditions blend seamlessly with modern life. As you walk through its lively streets, explore temples, and experience the warmth of its people, you’ll realize why it’s often called the spiritual gateway to the Himalayas. Whether you seek divine blessings or the serenity of a place untouched by time, Kathmandu promises a transformative journey.
                                </p>
                                <p>Join Om Shiv Shankar Tirth Yatra and embark on a soul-enriching adventure. We take pride in offering a unique, authentic, and safe experience that will leave you with lasting memories of Nepal’s spiritual heart.
                                </p>
                                <p>Book your tour now and be part of a journey that will not only explore Nepal’s rich heritage but also refresh your spirit.
                                </p>

                            </div>


                        </div>
                        <div className='mt-4'>
                            <img className="w-100" src={nepal} alt="nepal.png" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
export default Nepal;