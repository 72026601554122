import React, { useState } from 'react';
import "../../assets/style.css";
import { IoArrowBackSharp } from "react-icons/io5";
import Form from 'react-bootstrap/Form';
import { apiRequest } from '../../ApiService/Allapi';
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

function ChangePhone({ onBack }) {
    const langs = localStorage.getItem("i18nextLng") ?? "en";
    const [lang, setLang] = useState(langs);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({
        mobile: "",
        countryCode: "+91",
    });

    const [type, setType] = useState(1);

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const body = {
            mobile: data.mobile,
            countryCode: data.countryCode,
            type: type, // Include the "type" field
        };

        try {
            const result = await apiRequest(`update-mobile?lang=${lang}`, "POST", body);
            console.log(result);
            if (result.status === true) {
                Swal.fire({
                    title: result.message,
                    icon: "success",
                });

                setType(2)
                onBack(); // Go back to the previous screen after success
                window.location.reload(); // Reload the page
            }
            else {
                Swal.fire({
                    title: result.message,
                    icon: "error"
                });
            }

        } catch (error) {
            Swal.fire({
                title: error.message,
                icon: "error",
            });
        } finally {
            setLoading(false);
        }
    };

    const { t, i18n } = useTranslation();
    return (
        <>
            <div className='edit_box'>
                <div className='back_btn'>
                    <a href="#" onClick={(e) => { e.preventDefault(); onBack(); }}>
                        <IoArrowBackSharp />
                    </a>
                </div>

                <div className='card'>
                    <div className='card-header'>
                        <h4>{t("change")}</h4>
                    </div>
                    <div className='card-body'>
                        <Form onSubmit={handleSubmit}>
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <Form.Group className="mb-3">
                                        <Form.Label>{t("CountryCode")}</Form.Label>
                                        <Form.Select
                                            aria-label="Default select example"
                                            name="countryCode"
                                            value={data.countryCode}
                                            onChange={handleChange}
                                        >
                                            <option value="+91">+91</option>
                                            <option value="+92">+92</option>
                                            <option value="+93">+93</option>
                                            <option value="+94">+94</option>
                                        </Form.Select>
                                    </Form.Group>
                                </div>
                                <div className='col-lg-6'>
                                    <Form.Group className="mb-3" controlId="phoneInput">
                                        <Form.Label>{t("PhoneNumber")}</Form.Label>
                                        <Form.Control
                                            type="number"
                                            placeholder={t("PhoneNumber")}
                                            name="mobile"
                                            value={data.mobile}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                </div>
                            </div>
                            <div className='d-flex align-items-center justify-content-end'>
                                <button
                                    type="submit"
                                    className='btn btn-primary w-auto'
                                    disabled={loading}
                                >
                                    {loading ? "Updating..." : "Update"}
                                </button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ChangePhone;
