import React, { useEffect, useState } from 'react';
import "../assets/style.css";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import lightImage from '../assets/images/light.svg';
import userImage from '../assets/images/user.svg';
import SuppoerImage from "../assets/images/support_icon.png";
import { useTranslation } from "react-i18next";

function Support() {
  const { t, i18n } = useTranslation();
  return (
    <>
      <div className='support_body'>

        <div className="supoort_box">
          <div className='supoort'>
            <h2>{t("helpyou")}</h2>
            <InputGroup className="mb-3">

              <Form.Control
                placeholder={t("searchterm")}
                aria-label="Username"
                aria-describedby="basic-addon1"
              />
              <InputGroup.Text id="basic-addon1">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#b1bdc8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg></InputGroup.Text>
            </InputGroup>

          </div>
        </div>
        {/* section-start */}
        <section class="container-fluid px-sm-0 mt-16">
          <div class="support-container">
            <div class="row justify-content-center">


              <div class="col-md-6 col-xl-3 mb-8">
                <a class="card fw-horizontal" href="https://media1.OM shankar.com/filestore/OM shankar_customer_support_guide_video.mp4" aria-label="how" to="" rasie="" a="" ticket="">
                  <div class="col-3 px-0 my-auto text-center">
                    <div class="fw-category-icon">
                      <img src={lightImage} alt="" />
                    </div>
                  </div>
                  <div class="card-body col-9">
                    <h2 class="mt-0 card-title fs-18 line-clamp-2">{t("Browsearticles")}</h2>
                    <p class="line-clamp-3">{t("Browsearticlese")}</p>
                  </div>
                </a>
              </div>







              <div class="col-md-6 col-xl-3 mb-8">
                <a class="card fw-horizontal" href="https://media1.OM shankar.com/filestore/OM shankar_customer_support_guide_video.mp4" aria-label="how" to="" rasie="" a="" ticket="">
                  <div class="col-3 px-0 my-auto text-center">
                    <div class="fw-category-icon">
                      <img src={userImage} alt="" />
                    </div>
                  </div>
                  <div class="card-body col-9">
                    <h2 class="mt-0 card-title fs-18 line-clamp-2">{t("Submiticket")}</h2>
                    <p class="line-clamp-3">{t("Submitickets")}</p>
                  </div>
                </a>
              </div>


            </div>
          </div>
        </section>
        {/* section_end */}
        <section class="container-fluid px-sm-0">
          <div class="support-container mt-0">
            <div class="fw-section-heading text-center mt-28 mt-md-44 mb-24 mb-md-16">
              <h2>{t("Knowledgebase")}</h2>
              <a href="#">{t("Knowledgebases")}</a>
            </div>
            <div class="row justify-content-center">





              <div class="col-md-4 col-xl-3 mb-8">
                <a class="card  fw-kbase-card" aria-label="Elevate Your Support Experience with OM shankar!" href="/support/solutions/5000174653">
                  <div class="px-0 my-auto fw-icon-holder">

                    <span class="icon-category text-center">
                      <img src={SuppoerImage} alt="" />
                    </span>

                  </div>
                  <div class="card-body m-0">
                    <p class="mt-0 card-title fs-18 line line-clamp-2">{t("sankar")}</p>
                    <p class="line-clamp-2">{t("sankars")} </p>
                  </div>
                </a>
              </div>







              <div class="col-md-4 col-xl-3 mb-8">
                <a class="card fw-kbase-card" aria-label="Customer Related Queries" href="/support/solutions/5000098403">
                  <div class="px-0 my-auto fw-icon-holder">

                    <span class="icon-category">

                      <img src={SuppoerImage} alt="" />
                    </span>

                  </div>
                  <div class="card-body">
                    <p class="mt-0 card-title fs-18 line line-clamp-2">{t("Related")}</p>
                    <p class="line-clamp-2">{t("Relateds")}</p>
                  </div>
                </a>
              </div>







              <div class="col-md-4 col-xl-3 mb-8">
                <a class="card h-100 fw-kbase-card" aria-label="Affiliation &amp; Coverage Related Queries" href="/support/solutions/5000097872">
                  <div class="px-0 my-auto fw-icon-holder">

                    <span class="icon-category">
                      <img src={SuppoerImage} alt="" />
                    </span>

                  </div>
                  <div class="card-body">
                    <p class="mt-0 card-title fs-18 line   line-clamp-2">{t("Affiliation")}</p>
                    <p class="line-clamp-2"></p>
                  </div>
                </a>
              </div>



            </div>
          </div>
        </section>

        <section class="container-fluid px-0 pt-60 pb-44 bg-white mt-3">
          <div class="support-container mt-0">



            <div class="col-xl-9 mx-auto mb-32">
              <div class="fw-section-heading row mb-16">
                <div class="col-md-8 text-center text-lg-start">
                  <h4 className="support-title">{t("popular")}</h4>
                </div>
                <div class="col-md-4 text-center text-end">
                  <a href="#">{t("allarticles")}</a>
                </div>
              </div>
              <div class="fw-articles">
                <div class="row">

                  <a class="col-md-6 fw-article-info d-flex" href="#" aria-label="Advanced filters for Search">
                    <div class=" pe-8"><span class="icon-article pr-8">

                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file-text"><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path><polyline points="14 2 14 8 20 8"></polyline><line x1="16" y1="13" x2="8" y2="13"></line><line x1="16" y1="17" x2="8" y2="17"></line><polyline points="10 9 9 9 8 9"></polyline></svg>
                    </span></div>
                    <div>
                      <p class="fs-18 semi-bold line-clamp-2 mb-1">
                        {t("query")}
                      </p>
                      <p class="line-clamp-2 fw-meta-info">


                       {t("Modified")}


                      </p>
                    </div>
                    <p></p>
                  </a>

                  <a class="col-md-6 fw-article-info d-flex" href="#" aria-label="Advanced filters for Search">
                    <div class=" pe-8"><span class="icon-article pr-8">

                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file-text"><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path><polyline points="14 2 14 8 20 8"></polyline><line x1="16" y1="13" x2="8" y2="13"></line><line x1="16" y1="17" x2="8" y2="17"></line><polyline points="10 9 9 9 8 9"></polyline></svg>
                    </span></div>
                    <div>
                      <p class="fs-18 semi-bold line-clamp-2 mb-1">{t("site")}</p>
                      <p class="line-clamp-2 fw-meta-info">


                        {t("Modifieds")}


                      </p>
                    </div>
                    <p></p>
                  </a>

                  <a class="col-md-6 fw-article-info d-flex" href="#" aria-label="Advanced filters for Search">
                    <div class=" pe-8"><span class="icon-article pr-8">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file-text"><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path><polyline points="14 2 14 8 20 8"></polyline><line x1="16" y1="13" x2="8" y2="13"></line><line x1="16" y1="17" x2="8" y2="17"></line><polyline points="10 9 9 9 8 9"></polyline></svg></span></div>
                    <div>
                      <p class="fs-18 semi-bold line-clamp-2 mb-1">{t("received")}</p>
                      <p class="line-clamp-2 fw-meta-info">


                       {t("Modifiedse")}


                      </p>
                    </div>
                    <p></p>
                  </a>

                  <a class="col-md-6 fw-article-info d-flex" href="#" aria-label="Advanced filters for Search">
                    <div class=" pe-8"><span class="icon-article pr-8">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file-text"><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path><polyline points="14 2 14 8 20 8"></polyline><line x1="16" y1="13" x2="8" y2="13"></line><line x1="16" y1="17" x2="8" y2="17"></line><polyline points="10 9 9 9 8 9"></polyline></svg></span></div>
                    <div>
                      <p class="fs-18 semi-bold line-clamp-2 mb-1">{t("cancellation")}</p>
                      <p class="line-clamp-2 fw-meta-info">


                        {t("Modifiedt")}


                      </p>
                    </div>
                    <p></p>
                  </a>

                </div>
              </div>
            </div>




          </div>
        </section>




      </div>

    </>

  )
}

export default Support;
