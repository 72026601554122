import React from 'react'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import dwarkaPuriImage from "../assets/images/blog/banner.png";
function Blog() {
  return (
    <>
       <div className='blog_main'  style={{
              backgroundImage: `url("https://shivshankartirthyatra.com/blog/wp-content/uploads/2025/02/banner.png"), repeating-linear-gradient(160deg, #d1ad95 0%, #f4bb97 99%)`,
             backgroundSize: "cover",
             backgroundPosition: "right 0px bottom 0px;",
             backgroundRepeat:"no-repat",
             display: "flex",
             alignItems: "center",
             padding:"130px 0",
             justifyContent: "center",
             color: "white",  // Adjust text color for visibility

             

        }}>
            <div className='d-flex align-items-center'>
                <div className='blog_main_content'>
                    <h1>Shiv Shankar Tirth Yatra Blogs</h1>

                </div>
            </div>
            
       </div>
    </>
  )
}

export default Blog