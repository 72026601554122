
import React from 'react';
import "../assets/style.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../src/assets/chardham.png';
import chardhamImage  from '../../src/assets/chardham.png'
// import brajkos from '../../src/assets/brajkos.png.jpg'
import chardham1 from '../../src/assets/chardham1.png.jpg'

const CharDhamYatra = () => {
    return (
        <>
        <div>
           
            <img className='w-100' src={chardhamImage} alt="chardham.png" />
        </div>
            <section className='section-padding'>
                <div className='container'>
                    <div className>
                        <div className='row'>
                            <div className='col-lg-12 tabs'>
                                <h1>Char Dham Yatra with Om Shiv Shankar Tirth Yatra</h1>
                                <span>
                                </span> <br />

                                <p classname="about_para">Where rivers pure and mountains high,
                                    Call the soul to touch the sky.
                                    A path of faith, so bright, so grand,
                                    Through sacred snow and holy land. 🌿 Badrinath – where Vishnu stays,
                                    In golden light of ancient days.
                                    🔱 Kedarnath – where Shiva calls,
                                    Amidst the clouds, the echo falls.
                                    🌊 Gangotri – where Ganga starts,
                                    A river that cleanses the deepest hearts.
                                    💧 Yamunotri – where waters bless,
                                    And fill the soul with happiness.
                                    Come, walk this path, so pure, so true,
                                    With Om Shiv Shankar Tirth Yatra guiding you. 🚩
                                </p>
                                <br />
                                <h1>Char Dham Yatra with Om Shiv Shankar Tirth Yatra – A Journey to  Moksha  </h1> <br />
                                <p>Embark on the sacred Char Dham Yatra with Om Shiv Shankar Tirth Yatra and experience the divine energy of the Himalayas.
                                    This yatra to Badrinath, Kedarnath, Gangotri, and Yamunotri is not just a pilgrimage—it’s a journey of faith,
                                    purification, and spiritual awakening.  </p>

                                <h3>Why Choose This Yatra?   </h3>
                                <p>🙏 Badrinath Dham – Bow before Lord Vishnu in the land of eternal salvation.
                                </p>
                                <p> 🕉️ Kedarnath Dham – Seek the blessings of Lord Shiva at His sacred Jyotirlinga.</p>
                                <p> 🌊 Gangotri Dham – Witness the divine origin of the holy Ganga, the river of liberation.</p>
                                <p> 💧 Yamunotri Dham – Bathe in the purest waters of Maa Yamuna, where devotion flows endlessly. </p>
                                <h3>Why Travel with Us?   </h3>
                                <p>🚩 Seamless & Hassle-Free Experience – We take care of everything—travel, stay, and darshan.</p>
                                <p> 🚩 Comfort & Safety – Experience a well-organized journey with expert guidance. </p>
                                <p> 🚩 A Pilgrimage with Purpose – Let us guide you to peace, devotion, and divine blessings.  </p>
                                <div>At Om Shiv Shankar Tirth Yatra, we don’t just take you on a journey; we take you closer to Moksha.
                                </div>
                                <div>Join us for the Char Dham Yatra and let your soul connect with the divine! 🚩
                                </div>
                            </div>

                        </div > 
                        <div className='mt-4'>
                            <img  className="w-100" src={chardham1} alt="chardham1.png" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default CharDhamYatra; 
